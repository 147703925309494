import { useEffect } from "react";
import { useFormikContext, useField } from "formik";

interface UseGesamtBerechnungProps {
  index: number;
  materialTransportIndex: number;
  type: "materialanfuhr" | "materialtransport" | "materialabtransport";
}

export const useGesamtBerechnung = ({
  index,
  materialTransportIndex,
  type,
}: UseGesamtBerechnungProps) => {
  const { setFieldValue } = useFormikContext();

  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.anzahlFuhren`
  );

  useEffect(() => {
    const mengeGesamt = mengeField.value * fuhrenField.value;
    const preisGesamt = (mengeGesamt * preisField.value).toFixed(2);

    setFieldValue(
      `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.mengeGesamt`,
      mengeGesamt
    ).then(() => {});

    setFieldValue(
      `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.preisGesamt`,
      preisGesamt
    ).then(() => {});
  }, [
    mengeField.value,
    fuhrenField.value,
    preisField.value,
    index,
    materialTransportIndex,
    type,
    setFieldValue,
  ]);

  return { mengeField, preisField, fuhrenField };
};
