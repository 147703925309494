/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AbfallartenService } from './services/AbfallartenService';
import { AbladestellenService } from './services/AbladestellenService';
import { AbwesenheitenService } from './services/AbwesenheitenService';
import { AnbauteileService } from './services/AnbauteileService';
import { AnwesenheitenService } from './services/AnwesenheitenService';
import { AusgangsrechnungenService } from './services/AusgangsrechnungenService';
import { AuthService } from './services/AuthService';
import { BaustellenService } from './services/BaustellenService';
import { BenutzerService } from './services/BenutzerService';
import { BrzexportService } from './services/BrzexportService';
import { DatevService } from './services/DatevService';
import { EntsorgerService } from './services/EntsorgerService';
import { ErzeugerService } from './services/ErzeugerService';
import { ExternesystemeService } from './services/ExternesystemeService';
import { FahrzeugeService } from './services/FahrzeugeService';
import { FuhrparkeintraegeService } from './services/FuhrparkeintraegeService';
import { FuhrparkeintraganbauteileService } from './services/FuhrparkeintraganbauteileService';
import { KontenService } from './services/KontenService';
import { KundenService } from './services/KundenService';
import { LieferantenService } from './services/LieferantenService';
import { MaschinentransporteService } from './services/MaschinentransporteService';
import { MaterialService } from './services/MaterialService';
import { MaterialtransporteService } from './services/MaterialtransporteService';
import { PersonaleintraegeService } from './services/PersonaleintraegeService';
import { RechnungenService } from './services/RechnungenService';
import { SystemService } from './services/SystemService';
import { TaetigkeitsberichteService } from './services/TaetigkeitsberichteService';
import { TagesberichteService } from './services/TagesberichteService';
import { UmsatzService } from './services/UmsatzService';
import { ZahllaeufeService } from './services/ZahllaeufeService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class API {

  public readonly abfallarten: AbfallartenService;
  public readonly abladestellen: AbladestellenService;
  public readonly abwesenheiten: AbwesenheitenService;
  public readonly anbauteile: AnbauteileService;
  public readonly anwesenheiten: AnwesenheitenService;
  public readonly ausgangsrechnungen: AusgangsrechnungenService;
  public readonly auth: AuthService;
  public readonly baustellen: BaustellenService;
  public readonly benutzer: BenutzerService;
  public readonly brzexport: BrzexportService;
  public readonly datev: DatevService;
  public readonly entsorger: EntsorgerService;
  public readonly erzeuger: ErzeugerService;
  public readonly externesysteme: ExternesystemeService;
  public readonly fahrzeuge: FahrzeugeService;
  public readonly fuhrparkeintraege: FuhrparkeintraegeService;
  public readonly fuhrparkeintraganbauteile: FuhrparkeintraganbauteileService;
  public readonly konten: KontenService;
  public readonly kunden: KundenService;
  public readonly lieferanten: LieferantenService;
  public readonly maschinentransporte: MaschinentransporteService;
  public readonly material: MaterialService;
  public readonly materialtransporte: MaterialtransporteService;
  public readonly personaleintraege: PersonaleintraegeService;
  public readonly rechnungen: RechnungenService;
  public readonly system: SystemService;
  public readonly taetigkeitsberichte: TaetigkeitsberichteService;
  public readonly tagesberichte: TagesberichteService;
  public readonly umsatz: UmsatzService;
  public readonly zahllaeufe: ZahllaeufeService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://api.zeller-bip.de',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.abfallarten = new AbfallartenService(this.request);
    this.abladestellen = new AbladestellenService(this.request);
    this.abwesenheiten = new AbwesenheitenService(this.request);
    this.anbauteile = new AnbauteileService(this.request);
    this.anwesenheiten = new AnwesenheitenService(this.request);
    this.ausgangsrechnungen = new AusgangsrechnungenService(this.request);
    this.auth = new AuthService(this.request);
    this.baustellen = new BaustellenService(this.request);
    this.benutzer = new BenutzerService(this.request);
    this.brzexport = new BrzexportService(this.request);
    this.datev = new DatevService(this.request);
    this.entsorger = new EntsorgerService(this.request);
    this.erzeuger = new ErzeugerService(this.request);
    this.externesysteme = new ExternesystemeService(this.request);
    this.fahrzeuge = new FahrzeugeService(this.request);
    this.fuhrparkeintraege = new FuhrparkeintraegeService(this.request);
    this.fuhrparkeintraganbauteile = new FuhrparkeintraganbauteileService(this.request);
    this.konten = new KontenService(this.request);
    this.kunden = new KundenService(this.request);
    this.lieferanten = new LieferantenService(this.request);
    this.maschinentransporte = new MaschinentransporteService(this.request);
    this.material = new MaterialService(this.request);
    this.materialtransporte = new MaterialtransporteService(this.request);
    this.personaleintraege = new PersonaleintraegeService(this.request);
    this.rechnungen = new RechnungenService(this.request);
    this.system = new SystemService(this.request);
    this.taetigkeitsberichte = new TaetigkeitsberichteService(this.request);
    this.tagesberichte = new TagesberichteService(this.request);
    this.umsatz = new UmsatzService(this.request);
    this.zahllaeufe = new ZahllaeufeService(this.request);
  }
}

