import { useCallback } from "react";
import { useFuhrparkNutzungsdauer } from "./useFuhrparkNutzungsdauer";
import { useFuhrparkAnbauteile } from "./useFuhrparkAnbauteile";
import { useFuhrparkKosten } from "./useFuhrparkKosten";

interface UseFuhrparkeintragProps {
  index: number;
  isNew: boolean;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
}

export const useFuhrparkeintrag = ({
  index,
  isNew,
  taetigkeitsberichtDatum,
  dauerVorhanden,
}: UseFuhrparkeintragProps) => {
  const nutzungsdauer = useFuhrparkNutzungsdauer({
    index,
    isNew,
    dauerVorhanden,
  });
  const anbauteile = useFuhrparkAnbauteile({ index });
  const kosten = useFuhrparkKosten({ index, isNew, taetigkeitsberichtDatum });

  const handleFahrzeugChange = useCallback(async () => {
    const fahrzeugId =
      typeof kosten.fahrzeugField.value === "string"
        ? kosten.fahrzeugField.value
        : kosten.fahrzeugField.value?.id;

    await kosten.updateFahrzeugStundenpreis(fahrzeugId);
    anbauteile.handleFahrzeugChange();
  }, [kosten.fahrzeugField.value]);

  return {
    ...nutzungsdauer,
    ...anbauteile,
    ...kosten,
    handleFahrzeugChange,
  };
};
