import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateBenutzer } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { BenutzerDaten } from "../../api";
import BenutzerFieldsContent from "../../components/benutzer/BenutzerFieldsContent";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleBenutzer() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveBenutzer();
  let schema = yup.object().shape({
    personalNummer: yup.string(),
    name: yup.string().required(),
    plz: yup.string(),
    strasse: yup.string(),
    ort: yup.string(),
    telefon: yup.string(),
    email: yup.string().required(),
    vorname: yup.string(),
    geburtsDatum: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    eintrittsDatum: yup
      .date()
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    wochenArbeitszeit: yup
      .string()
      .test("is-decimal", "invalid wochenArbeitszeit", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    stundenLohn: yup
      .string()
      .test("is-decimal", "invalid stundenLohn", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    pauschalVerguetung: yup
      .string()
      .test("is-decimal", "invalid pauschalVerguetung", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    berechnungsSatz: yup
      .string()
      .test("is-decimal", "invalid berechnungsSatz", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    anrede: yup.string(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        personalNummer: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        vorname: string;
        geburtsDatum: string;
        eintrittsDatum: string;
        wochenArbeitszeit: string;
        stundenLohn: string;
        pauschalVerguetung: string;
        anrede: string;
        berechnungsSatz: string;
      },
      formikHelpers: FormikHelpers<{
        personalNummer: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        vorname: string;
        geburtsDatum: string;
        eintrittsDatum: string;
        wochenArbeitszeit: string;
        stundenLohn: string;
        pauschalVerguetung: string;
        anrede: string;
        berechnungsSatz: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const geburtsDatum =
        values.geburtsDatum === ""
          ? undefined
          : values.geburtsDatum + "T00:00:00Z";
      const eintrittsDatum =
        values.eintrittsDatum === ""
          ? undefined
          : values.eintrittsDatum + "T00:00:00Z";
      let wochenArbeitszeit = values.wochenArbeitszeit
        ? parseFloat(values.wochenArbeitszeit.replace(",", "."))
        : 0;
      let stundenlohn = values.stundenLohn
        ? parseFloat(values.stundenLohn.replace(",", "."))
        : 0;
      let pauschalVerguetung = values.pauschalVerguetung
        ? parseFloat(values.pauschalVerguetung.replace(",", "."))
        : 0;
      let berechnungssatz = values.berechnungsSatz
        ? parseFloat(values.berechnungsSatz.replace(",", "."))
        : 0;
      const benutzer = await mutateAsync({
        name: values.name,
        email: values.email,
        personalNummer: values.personalNummer
          ? values.personalNummer
          : undefined,
        vorname: values.vorname,
        strasse: values.strasse,
        plz: values.plz,
        ort: values.ort,
        telefon: values.telefon,
        geburtsDatum: geburtsDatum,
        eintrittsDatum: eintrittsDatum,
        wochenArbeitszeit: wochenArbeitszeit,
        stundenLohn: stundenlohn,
        pauschalVerguetung: pauschalVerguetung,
        anrede: values.anrede,
        berechnungsSatz: berechnungssatz,
      });
      navigate(`/benutzer`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Neuer Benutzer"} back="/benutzer">
      <Guard
        permission={"benutzer:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              anrede: "",
              personalNummer: "",
              name: "",
              plz: "",
              strasse: "",
              ort: "",
              telefon: "",
              email: "",
              vorname: "",
              geburtsDatum: "",
              eintrittsDatum: "",
              wochenArbeitszeit: "",
              stundenLohn: "",
              pauschalVerguetung: "",
              berechnungsSatz: "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">Neuer Benutzer</Typography>
                <DialogContent>
                  <Form id="new-erzeuger">
                    <BenutzerFieldsContent />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-erzeuger"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveBenutzer() {
  const createBenutzer = useCreateBenutzer();
  const queryClient = useQueryClient();

  return useMutation(
    (input: BenutzerDaten) => {
      return createBenutzer(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["benutzer"]);
      },
    }
  );
}
