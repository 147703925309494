import {
  Box,
  Button,
  ButtonGroup,
  capitalize,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { AnwesenheitDaten, AnwesenheitInfo } from "../../api";
import FilterBenutzerSelect from "../benutzer/FilterBenutzerSelect";
import { Field, Formik } from "formik";
import DataTableWithFilter from "../data/DataTableWithFilter";
import {
  useApproveMultipleAnwesenheiten,
  useGetBenutzer,
  useUpdateAnwesenheit,
} from "../../client/hooks";
import { useQueryClient } from "react-query";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";

export type AnwesenheitenFilter = {
  search?: string;
  mitarbeiterId?: string;
  startDatum?: string;
  endeDatum?: string;
  filtered?: string;
};

interface AnwesenheitProps {
  anwesenheitIds: string[];
  setAnwesenheitIds: (value: string[]) => void;
}

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function AnwesenheitenDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });
  const [anwesenheitIds, setAnwesenheitIds] = useState<string[]>([]);

  const [filteredState, setFilteredState] = useState("");
  const [updateAzSaldo, setUpdateAzSaldo] = useState(false);

  return (
    <Stack spacing={2}>
      <FilterComp
        anwesenheitIds={anwesenheitIds}
        setAnwesenheitIds={setAnwesenheitIds}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
        updateAzSaldo={updateAzSaldo}
        setUpdateAzSaldo={setUpdateAzSaldo}
        {...request}
      />
      <AnwesenheitenResults
        anwesenheitIds={anwesenheitIds}
        setAnwesenheitIds={setAnwesenheitIds}
        setFilteredState={setFilteredState}
        setUpdateAzSaldo={setUpdateAzSaldo}
        {...request}
      />
    </Stack>
  );
}

function FilterComp({
  anwesenheitIds,
  setAnwesenheitIds,
  filteredStateFilter,
  setFilteredState,
  updateAzSaldo,
  setUpdateAzSaldo,
  ...request
}: AnwesenheitProps & {
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
  setUpdateAzSaldo: (value: boolean) => void;
  updateAzSaldo: boolean;
} & DataRequestState<AnwesenheitenFilter>) {
  const [{ search, mitarbeiterId, startDatum, endeDatum, filtered }, setField] =
    useDebouncedFilter(request);
  const setMitarbeiterId = (value: string) => {
    setField("mitarbeiterId", value);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const approve = useApproveMultipleAnwesenheiten();
  const queryClient = useQueryClient();
  const { t } = useTranslation("entsorgungsdokumentation");
  const [startDatumFilter, setStartDatumFilter] = useState("");
  const [endDatumFilter, setEndDatumFilter] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [az_saldo, setAzSaldo] = useState("-");
  const ladeBenutzer = useGetBenutzer();

  async function approveAnwesenheiten(anwesenheitsIds: Array<string>) {
    await approve(anwesenheitsIds).then((r) => {
      queryClient.invalidateQueries(["anwesenheiten"]);
      setUpdateAzSaldo(true);
    });
  }

  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);

  useEffect(() => {
    setField("startDatum", startDatumFilter);
    setField("endeDatum", endDatumFilter);
  }, [startDatumFilter, endDatumFilter]);

  useEffect(() => {
    if (mitarbeiterId) {
      if (mitarbeiterId === "_all") {
        setAzSaldo("-");
      } else {
        (async () => {
          const benutzer = await ladeBenutzer(mitarbeiterId);
          setAzSaldo(benutzer.azSaldo?.toString().replace(".", ",") ?? "-");
        })();
      }
    }
    if (updateAzSaldo) {
      setUpdateAzSaldo(false);
    }
  }, [mitarbeiterId, updateAzSaldo]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{
              mitarbeiterId: mitarbeiterId,
              startDatum: startDatumFilter,
              endDatum: endDatumFilter,
            }}
            onSubmit={() => {}}
          >
            {({ handleChange, isSubmitting, isValidating, isValid, dirty }) => {
              const handleStartDatumChange = (e: React.ChangeEvent<any>) => {
                handleChange(e);
                setStartDatumFilter(e.target.value);
              };

              const handleEndDatumChange = (e: React.ChangeEvent<any>) => {
                handleChange(e);
                setEndDatumFilter(e.target.value);
              };
              return (
                <>
                  <Field
                    size={"small"}
                    sx={{ ml: 2 }}
                    component={TextField}
                    name="startDatum"
                    label={capitalize(t("start-date"))}
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleStartDatumChange}
                  />
                  <Field
                    sx={{ ml: 2 }}
                    size={"small"}
                    component={TextField}
                    name="endDatum"
                    label={capitalize(t("end-date"))}
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleEndDatumChange}
                  />
                  <Field
                    component={FilterBenutzerSelect}
                    name={"mitarbeiterId"}
                    label={"Mitarbeiter"}
                    setBenutzer={setMitarbeiterId}
                  />
                  <Grid size={{ xs: 1.5, md: 1.5 }}>
                    <Stack sx={{ ml: 2, textAlign: "center" }}>
                      <Typography>AZ-Saldo</Typography>
                      <Box>{az_saldo}</Box>
                    </Stack>
                  </Grid>
                </>
              );
            }}
          </Formik>
          <ButtonGroup sx={{ position: "absolute", top: 0, right: 0 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}
              onClick={() => approveAnwesenheiten(anwesenheitIds)}
            >
              Anwesenheiten freigeben
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/neu-anwesenheit");
              }}
            >
              Anwesenheit anlegen
            </Button>
          </ButtonGroup>
        </>
      )}
    </Box>
  );
}

function AnwesenheitenResults({
  anwesenheitIds,
  setAnwesenheitIds,
  setFilteredState,
  setUpdateAzSaldo,
  ...request
}: AnwesenheitProps & {
  setFilteredState: (value: string) => void;
  setUpdateAzSaldo: (value: boolean) => void;
} & DataRequestState<AnwesenheitenFilter>) {
  return (
    <Results
      anwesenheitIds={anwesenheitIds}
      setAnwesenheitIds={setAnwesenheitIds}
      request={request}
      setFilteredState={setFilteredState}
      setUpdateAzSaldo={setUpdateAzSaldo}
    />
  );
}

function Results({
  anwesenheitIds,
  setAnwesenheitIds,
  request,
  setFilteredState,
  setUpdateAzSaldo,
}: AnwesenheitProps & {
  request: DataRequestState<AnwesenheitenFilter>;
  setFilteredState: (value: string) => void;
  setUpdateAzSaldo: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAnwesenheiten: DataLoader<AnwesenheitenFilter, AnwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).anwesenheiten.sucheAnwesenheiten({ ...params }),
      [getApi]
    );
  const updateAnwesenheit = useUpdateAnwesenheit();
  const queryClient = useQueryClient();
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["anwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAnwesenheiten}
      name={"anwesenheiten"}
      // @ts-ignore
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      onRowSelectionModelChange={(newRowSelectionModel: any) => {
        setAnwesenheitIds(newRowSelectionModel);
      }}
      onRowClick={(row, event) => {
        //@ts-ignore
        if (event.target.attributes[2].value !== "arbeitszeitKonto") {
          if (event.ctrlKey || event.metaKey) {
            window.open("/anwesenheiten/" + row.id, "_blank");
          } else {
            navigate("/anwesenheiten/" + row.id);
          }
        }
      }}
      processRowUpdate={(updatedRow: any, originalRow: any) => {
        const newAnwesenheitData: AnwesenheitDaten = {
          datum: updatedRow.datum,
          mitarbeiterId: updatedRow.mitarbeiterId,
          start: updatedRow.start,
          ende: updatedRow.ende,
          arbeitszeitKonto: updatedRow.arbeitszeitKonto,
          arbeitszeitBrutto: updatedRow.arbeitszeitBrutto,
          arbeitszeitNetto: updatedRow.arbeitszeitNetto,
          bezahltePause: updatedRow.bezahltePause,
          unbezahltePause: updatedRow.unbezahltePause,
          fahrzeiten: updatedRow.fahrzeiten,
          status: updatedRow.status,
          brzUebertrag: updatedRow.brzUebertrag,
        };

        updateAnwesenheit(updatedRow.id, newAnwesenheitData).then((r) => {
          queryClient.invalidateQueries(["anwesenheiten"]);
          setUpdateAzSaldo(true);
        });

        return updatedRow;
      }}
      onProcessRowUpdateError={(error: any) => {
        console.log(error);
      }}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<AnwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: "Mitarbeiter",
        flex: 1,
      },
      {
        field: "datum",
        headerName: t("date"),
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "start",
        headerName: "Start",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "arbeitszeitBrutto",
        headerName: "Brutto Arbeitszeit",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "arbeitszeitKonto",
        headerName: "AZ Konto",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        type: "number",
        editable: true,
      },
      {
        field: "brzUebertrag",
        headerName: "BRZ Übertrag",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "fahrzeiten",
        headerName: "Fahrzeiten",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "bezahltePause",
        headerName: "Bezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "unbezahltePause",
        headerName: "Unbezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "arbeitszeitNetto",
        headerName: "Netto Arbeitszeit",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          switch (params.value) {
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"Offen"}
                ></Chip>
              );
            case "ANGENOMMEN":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"Freigegeben"}
                ></Chip>
              );
          }
        },
      },
    ],
    [t]
  );
}
