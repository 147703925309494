import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SetFieldFn } from "./data";

type FilterProps = {
  setField: SetFieldFn<any>;
};

export default function StartEndFilter({ setField }: FilterProps) {
  const { t } = useTranslation("entsorgungsdokumentation");
  const [startFilter, setStartFilter] = useState("");
  const [endFilter, setEndFilter] = useState("");
  useEffect(() => {
    setField("startDate", startFilter);
    setField("endDate", endFilter);
  }, [startFilter, endFilter]);
  return (
    <Formik
      initialValues={{
        startDatum: "",
        endDatum: "",
      }}
      onSubmit={() => {}}
    >
      {({ handleChange }) => {
        const handleStartDatumChange = (e: React.ChangeEvent<any>) => {
          handleChange(e);
          setStartFilter(e.target.value);
        };

        const handleEndDatumChange = (e: React.ChangeEvent<any>) => {
          handleChange(e);
          setEndFilter(e.target.value);
        };
        return (
          <>
            <Field
              size={"small"}
              sx={{ ml: 2 }}
              component={TextField}
              name="startDatum"
              label={capitalize(t("start-date"))}
              type={"date"}
              InputLabelProps={{ shrink: true }}
              onChange={handleStartDatumChange}
              InputProps={{
                inputProps: {
                  max: endFilter || undefined, // Prevents selecting dates after endDate
                },
              }}
            />
            <Field
              sx={{ ml: 2 }}
              size={"small"}
              component={TextField}
              name="endDatum"
              label={capitalize(t("end-date"))}
              type={"date"}
              InputLabelProps={{ shrink: true }}
              onChange={handleEndDatumChange}
              inputProps={{
                min: startFilter || undefined, // Prevents selecting dates after endDate
              }}
            />
          </>
        );
      }}
    </Formik>
  );
}
