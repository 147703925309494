import { useState, useEffect } from "react";
import { useGetFahrzeug } from "../client/hooks";

interface UseFahrzeugLademengeProps {
  fahrzeugId: string;
}

export const useFahrzeugLadeMenge = ({
  fahrzeugId,
}: UseFahrzeugLademengeProps) => {
  const [currentFahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  const getfahrzeug = useGetFahrzeug();

  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);

  return { currentFahrzeugLadeMenge };
};
