import {
  Fuhrparkeintrag,
  FuhrparkeintragAnbauteil,
  FuhrparkeintragAnbauteilDaten,
  FuhrparkeintragDaten,
  Maschinentransport,
  MaschinentransportDaten,
  Materialtransport,
  type MaterialtransportDaten,
  type MaterialtransportZwischenDaten,
  Personaleintrag,
  PersonaleintragDaten,
  Taetigkeitsbericht,
  TaetigkeitsberichtDaten,
} from "../../api";
import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
  ExtendedTaetigkeitsberichtExternDaten,
  MaterialTransportDaten,
} from "./models";
import { Buchungstyp } from "./buchungstyp";
import {
  getAbfallartId,
  getAbholortBaustelleId,
  getAbstelltOrtBaustelleId,
  getAnbauteilId,
  getEntsorgerId,
  getErzeugerId,
  getFahrzeugId,
  getMaterialId,
  getQuelleBaustelleId,
  getTransportiertesFahrzeugId,
  getZielAbladestelleId,
  getZielBaustelleId,
} from "./taetigkeitsberichtMutationHelper";

// TODO: Typ createTaetigkeitsbericht korrekt setzen
export const saveTaetigkeitsbericht = async (
  baustelleId: string,
  values: ExtendedTaetigkeitsberichtDaten,
  createTaetigkeitsbericht: any
): Promise<Taetigkeitsbericht> => {
  const taetigkeitsberichtData: TaetigkeitsberichtDaten = {
    baustelleId: baustelleId,
    datum: values.datum,
    taetigkeitsberichtTyp: "INTERN",
    status: "ANGENOMMEN",
  };

  return await createTaetigkeitsbericht(taetigkeitsberichtData);
};

export const saveTaetigkeitsberichtExtern = async (
  baustelleId: string,
  values: ExtendedTaetigkeitsberichtExternDaten,
  createTaetigkeitsbericht: any,
  lieferantId: string
): Promise<Taetigkeitsbericht> => {
  const taetigkeitsberichtData: TaetigkeitsberichtDaten = {
    baustelleId: baustelleId,
    datum: values.datum,
    taetigkeitsberichtTyp: "EXTERN",
    lieferantId: lieferantId,
    status: "ANGENOMMEN",
  };

  return await createTaetigkeitsbericht(taetigkeitsberichtData);
};

// TODO: Typ createPersonaleintrag korrekt setzen
export const savePersonaleintrag = async (
  mitarbeiterId: string,
  baustelleId: string,
  taetigkeitsbericht: Taetigkeitsbericht,
  values: ExtendedTaetigkeitsberichtDaten,
  createPersonaleintrag: any
): Promise<Personaleintrag> => {
  const personaleintragData: PersonaleintragDaten = {
    mitarbeiterId: mitarbeiterId,
    baustelleId: baustelleId,
    datum: values.datum,
    arbeitszeitNetto: values.arbeitszeitNetto ?? undefined,
    stundensatz: values.stundensatz,
    bemerkung: values.bemerkung,
  };

  return await createPersonaleintrag(
    taetigkeitsbericht.id,
    personaleintragData
  );
};

// TODO: Typ createFuhrparkeintrag korrekt setzen
export const saveFuhrparkeintrag = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten,
  createFuhrparkeintrag: any
): Promise<Fuhrparkeintrag> => {
  const fuhparkeintragsData: FuhrparkeintragDaten = {
    fahrzeugId: getFahrzeugId(fuhrparkeintrag),
    fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
    fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
    fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
    bemerkung: fuhrparkeintrag.bemerkung,
    datum: fuhrparkeintrag.datum,
  };

  return await createFuhrparkeintrag(
    taetigkeitsbericht.id,
    fuhparkeintragsData
  );
};

// TODO: Typ createFuhrparkeintragAnbauteil korrekt setzen
export const saveFuhrparkeintragAnbauteil = async (
  taetigkeitsberichtId: string,
  fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten,
  currentFuhrparkeintragId: string,
  createFuhrparkeintragAnbauteil: any
): Promise<FuhrparkeintragAnbauteil> => {
  const fuhrparkeintragAnbauteilData: FuhrparkeintragAnbauteilDaten = {
    anbauteilId: getAnbauteilId(fuhrparkeintragAnbauteil),
    anbauteilNutzungsdauer: fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
    anbauteilStundenPreis: fuhrparkeintragAnbauteil.anbauteilStundenPreis,
  };
  return createFuhrparkeintragAnbauteil(
    taetigkeitsberichtId,
    currentFuhrparkeintragId,
    fuhrparkeintragAnbauteilData
  );
};

// TODO: Typ createMaschinentransport korrekt setzen
export const saveMaschinentransporte = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  currentFuhrparkeintragId: string,
  maschinentransport: any,
  createMaschinentransport: any
): Promise<Maschinentransport> => {
  const abholortBaustellenId = getAbholortBaustelleId(maschinentransport);
  const abstellortBaustellenId = getAbstelltOrtBaustelleId(maschinentransport);
  const transportiesFahrzeugId =
    getTransportiertesFahrzeugId(maschinentransport);

  const maschinentransportData: MaschinentransportDaten = {
    baustelleId: taetigkeitsbericht.baustelleId,
    transportiertesFahrzeugId: transportiesFahrzeugId,
    abholortBaustellenId: abholortBaustellenId,
    abstellortBaustellenId: abstellortBaustellenId,
    fuhrTyp: Buchungstyp.TR,
    lieferscheinNummer: maschinentransport.lieferscheinNummer,
    bemerkung: maschinentransport.bemerkung,
  };

  return createMaschinentransport(
    taetigkeitsbericht.id,
    currentFuhrparkeintragId,
    maschinentransportData
  );
};

// TODO: Typ createMaterialtransport korrekt setzen
export const saveMaterialanfuhr = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  currentFuhrparkeintragId: string,
  materialtransport: MaterialTransportDaten,
  createMaterialtransport: any
): Promise<Materialtransport> => {
  //TODO: prüfen ob quelleAbladestelleId korrekt ist
  const quelleAbladestelleId: string = getQuelleBaustelleId(materialtransport);
  const materialId: string = getMaterialId(materialtransport);

  const materialtransportData = {
    baustelleId: taetigkeitsbericht.baustelleId,
    fuhrTyp: Buchungstyp.MA,
    buchungsTyp: Buchungstyp.MA,
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    materialId: materialId,
    quelleAbladestelleId,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    menge: materialtransport.menge,
    kostenProEinheit: materialtransport.kostenProEinheit,
    meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
    buchungsArt: materialtransport.buchungsArt,
  };

  return createMaterialtransport(
    taetigkeitsbericht.id,
    currentFuhrparkeintragId,
    materialtransportData
  );
};

// TODO: Typ createMaterialtransport korrekt setzen
export const saveMaterialabtransport = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  currentFuhrparkeintragId: string,
  materialtransport: any,
  createMaterialtransport: any
): Promise<Materialtransport> => {
  const zielAbladestelleId: string = getZielAbladestelleId(materialtransport);
  const materialId: string = getMaterialId(materialtransport);
  const entsorgerId: string = getEntsorgerId(materialtransport);
  const erzeugerId: string = getErzeugerId(materialtransport);

  const materialtransportData: MaterialtransportDaten = {
    baustelleId: taetigkeitsbericht.baustelleId,
    fuhrTyp: materialtransport.fuhrTyp,
    buchungsTyp: materialtransport.buchungsTyp,
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    materialId: materialId,
    zielAbladestelleId: zielAbladestelleId,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    menge: materialtransport.menge,
    kostenProEinheit: materialtransport.kostenProEinheit,
    meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
    buchungsArt: materialtransport.buchungsArt,
    bsNr: materialtransport.bsNr,
    entsorgerId: entsorgerId,
    erzeugerId: erzeugerId,
  };

  return createMaterialtransport(
    taetigkeitsbericht.id,
    currentFuhrparkeintragId,
    materialtransportData
  );
};

// TODO: Typ createMaterialtransportZwischenBaustellen korrekt setzen
export const saveMaterialtransportZwischenBaustellen = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  currentFuhrparkeintragId: string,
  materialtransport: any,
  createMaterialtransportZwischenBaustellen: any
): Promise<Materialtransport> => {
  const zielBaustelleId: string = getZielBaustelleId(materialtransport);
  const quelleBaustelleId: string = getQuelleBaustelleId(materialtransport);
  const abfallartId: string = getAbfallartId(materialtransport);

  const materialtransportData: MaterialtransportZwischenDaten = {
    baustelleId: taetigkeitsbericht.baustelleId,
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    zielBaustelleId: zielBaustelleId,
    quelleAbladestelleId: quelleBaustelleId,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    menge: materialtransport.menge,
    kostenProEinheit: materialtransport.kostenProEinheit,
    buchungsArt: materialtransport.buchungsArt,
    abfallartId: abfallartId,
  };

  return createMaterialtransportZwischenBaustellen(
    taetigkeitsbericht.id,
    currentFuhrparkeintragId,
    materialtransportData
  );
};
