import React, { memo } from "react";
import { FastField } from "formik";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import AbfallartenEAKSelect from "../../components/abfallarten/AbfallartenEAKSelect";
import { useTranslation } from "react-i18next";
import { Material } from "./AbladestellenDetails";

interface MaterialRowProps {
  material: Material;
  index: number;
  onDelete: () => void;
  onDialogOpen: () => void;
}

const MaterialNameField = memo(({ index }: { index: number }) => {
  const { t } = useTranslation("abladestelle");
  return (
    <FastField
      name={`materials.${index}.name`}
      as={TextField}
      label={t("Material Name")}
      fullWidth
      required
    />
  );
});

const MaterialAbfallartField = memo(({ index }: { index: number }) => {
  return (
    <FastField
      name={`materials.${index}.abfallart`}
      component={AbfallartenEAKSelect}
      label="Abfallart"
      fullWidth
      required
      keyField={`materials.${index}.abfallartKey`}
    />
  );
});

const MaterialKostenField = memo(
  ({
    index,
    fieldName,
    label,
  }: {
    index: number;
    fieldName: string;
    label: string;
  }) => {
    return (
      <FastField
        name={`materials.${index}.${fieldName}`}
        as={TextField}
        label={label}
        inputProps={{ shrink: true }}
      />
    );
  }
);

const MaterialArchiviertField = memo(({ index }: { index: number }) => {
  const { t } = useTranslation("abladestelle");
  return (
    <FormControlLabel
      control={
        <FastField
          name={`materials.${index}.archiviert`}
          as={Switch}
          color="primary"
          type="checkbox"
        />
      }
      label={t("Archiviert")}
    />
  );
});

const MaterialRow = memo(
  ({ material, index, onDelete, onDialogOpen }: MaterialRowProps) => {
    const { t } = useTranslation("abladestelle");

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MaterialNameField index={index} />
        </Grid>
        <Grid item xs={2}>
          <MaterialAbfallartField index={index} />
        </Grid>
        <Grid item xs={1}>
          <Stack>
            <Typography color={"textSecondary"}>EAK-Schlüssel</Typography>
            <Box>{material.abfallartKey}</Box>
          </Stack>
        </Grid>
        <Grid item xs={1.5}>
          <MaterialKostenField
            index={index}
            fieldName="kostenProTonne"
            label={t("price-per-tons")}
          />
        </Grid>
        <Grid item xs={1.5}>
          <MaterialKostenField
            index={index}
            fieldName="kostenProKubikmeter"
            label={t("price-per-cubic")}
          />
        </Grid>
        {material.id ? (
          <IconButton onClick={onDialogOpen}>
            <HelpIcon />
          </IconButton>
        ) : (
          <Box width={40}></Box>
        )}
        <Grid item xs={1}>
          <MaterialArchiviertField index={index} />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onDelete} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.material.id === nextProps.material.id &&
      prevProps.index === nextProps.index
    );
  }
);

export default MaterialRow;
