import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { lazy, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useGetMaterialtransporteZwischenListe,
  useGetTaetigkeitsbericht,
  useSucheFuhrparkeintraege,
  useSucheFuhrparkeintragAnbauteil,
  useSucheMaschinentransporte,
  useSucheMaterialtransporteAbtransport,
  useSucheMaterialtransporteAnfuhr,
} from "../../client/hooks";
import { Taetigkeitsbericht } from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTaetigkeitsberichtButton from "../../components/taetigkeitsberichte/DeleteTaetigkeitsberichtButton";
import { ExpandContextProvider } from "../../expand";
import TaetigkeitsberichtFieldsContentExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContentExtern";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtExternDaten,
  MaterialTransportDaten,
} from "../../models/taetigkeitsberichte/models";
import { timestampToDatetimeLocal } from "../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { validationSchemaTaetigkeitsberichtExternDetails } from "../../models/taetigkeitsberichte/taetigkeitsberichtExternValidationSchema";
import { useTaetigkeitsberichtExternDetails } from "../../hooks/useTaetigkeitsberichtDetailsExtern";
import { useQuery } from "react-query";
import TaetigkeitsberichtExternSummenzeile from "../../components/taetigkeitsberichte/TaetigkeitsberichtExternSummenzeile";
import { FuhrparkProvider } from "../../contexts/FuhrparkContext";
import NeuerMaterialanfuhrTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialanfuhrTtbExternButton";
import NeuerMaterialtransportZwischenBaustellenTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialtransportZwischenBaustellenTtbExternButton";
import NeuerMaterialabtransportTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialabtransportTtbExternButton";
import ConfirmNavigationDialog from "../../components/ConfirmNavigationDialog";
import { useConfirmationNavDialog } from "../../hooks/useConfirmationNavDialog";

const FuhrparkeintragFremdPersonalItem = lazy(
  () =>
    import(
      "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragFremdPersonalItem"
    )
);
export default function TaetigkeitsberichtDetailsExtern() {
  const { id: taetigkeitsberichtId = "" } = useParams<{
    id: string;
  }>();

  const { t } = useTranslation("taetigkeitsbericht");
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();
  const searchFuhrparkeintragAnbauteil = useSucheFuhrparkeintragAnbauteil();
  const searchMaschinentransport = useSucheMaschinentransporte();
  const searchMaterialtransportAnfuhr = useSucheMaterialtransporteAnfuhr();
  const searchMaterialtransporteAbtransport =
    useSucheMaterialtransporteAbtransport();
  const searchMaterialZwischenBaustellen =
    useGetMaterialtransporteZwischenListe();

  const [fuhrparkeintraege, setFuhrparkeintraege] = useState<
    ExtendedFuhrparkeintragDaten[]
  >([]);
  const [taetigkeitsbericht, setTaetigkeitsbericht] =
    useState<Taetigkeitsbericht | null>(null);

  const { data: fuhrparkeintraegeData } = useQuery(
    ["fuhrparkeintraege"],
    async () => {
      if (!taetigkeitsberichtId) return [];
      const fuhrparkeintraegeData = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );

      return await Promise.all(
        fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
          const fuhrparkeintragAnbauteileData =
            await searchFuhrparkeintragAnbauteil(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );

          const maschinentransporteData = await searchMaschinentransport(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialanfuhrData = await searchMaterialtransportAnfuhr(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          console.log(materialanfuhrData);
          const materialabtransportData =
            await searchMaterialtransporteAbtransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
          const materialzwischenData = await searchMaterialZwischenBaustellen(
            taetigkeitsberichtId,
            fuhrparkeintrag.id
          );

          return {
            ...fuhrparkeintrag,
            fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            maschinentransporte: maschinentransporteData.items,
            materialanfuhr: materialanfuhrData.items,
            materialabtransport: materialabtransportData.items,
            materialtransport: materialzwischenData.items,
          };
        })
      );
    },
    {
      enabled: !!taetigkeitsberichtId,
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (fuhrparkeintraegeData) {
      // @ts-ignore
      setFuhrparkeintraege(fuhrparkeintraegeData);
    }
  }, [fuhrparkeintraegeData]);

  const { data: taetigkeitsberichtData } = useQuery(
    ["fremdleistung", taetigkeitsberichtId],
    () => getTaetigkeitsbericht(taetigkeitsberichtId),
    {
      enabled: !!taetigkeitsberichtId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (taetigkeitsberichtData) {
      setTaetigkeitsbericht(taetigkeitsberichtData);
    }
  }, [taetigkeitsberichtData]);

  const submitMutation = useTaetigkeitsberichtExternDetails(
    taetigkeitsberichtId,
    {
      queryKey: ["fuhrparkeintraege", { addEmptyEntry: false }],
    }
  );

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtExternDaten,
      formikHelpers: FormikHelpers<ExtendedTaetigkeitsberichtExternDaten>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        setSubmitting(true);
        await submitMutation.mutateAsync(values);
        setStatus(undefined);
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [submitMutation]
  );
  const backRoute: string = "/fremdleistungen";

  const {
    showConfirmDialog,
    handleConfirmBack,
    handleCancelBack,
    handleBackClick,
  } = useConfirmationNavDialog({ backRoute });
  if (!taetigkeitsbericht) {
    return <div>Loading...</div>;
  }

  const fpArray = fuhrparkeintraege.map((fuhrparkeintrag) => ({
    id: fuhrparkeintrag.id,
    // @ts-ignore
    fahrzeug: fuhrparkeintrag.fahrzeugId,
    fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
    fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
    fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
    bemerkung: fuhrparkeintrag.bemerkung,
    datum: fuhrparkeintrag.datum,
    fuhrparkeintragAnbauteile: fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten) => ({
        id: fuhrparkeintragAnbauteil.id,
        // @ts-ignore
        anbauteil: fuhrparkeintragAnbauteil.anbauteilId,
        anbauteilNutzungsdauer: fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
        anbauteilStundenPreis: fuhrparkeintragAnbauteil.anbauteilStundenPreis,
        anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
      })
    ),
    maschinentransporte: [],
    materialanfuhr: fuhrparkeintrag.materialanfuhr.map(
      (materialanfuhr: MaterialTransportDaten) => ({
        id: materialanfuhr.id,
        baustelleId: materialanfuhr.baustelleId,
        // @ts-ignore
        quelleAbladestelle: materialanfuhr.quelleAbladestelleId,
        // @ts-ignore
        zielBaustelle: materialanfuhr.zielBaustelleId,
        // @ts-ignore
        material: materialanfuhr.materialId,
        preisGesamt: materialanfuhr.preisGesamt,
        mengeGesamt: materialanfuhr.mengeGesamt,
        menge: materialanfuhr.menge,
        fuhrTyp: materialanfuhr.fuhrTyp,
        einheit: materialanfuhr.einheit,
        lieferscheinNummer: materialanfuhr.lieferscheinNummer,
        bemerkung: materialanfuhr.bemerkung,
        meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
        buchungsArt: materialanfuhr.buchungsArt,
        kostenProEinheit: materialanfuhr.kostenProEinheit,
        anzahlFuhren: materialanfuhr.anzahlFuhren,
        // @ts-ignore
        abfallart: materialanfuhr.abfallartId,
        fuhrparkeintragId: materialanfuhr.fuhrparkeintragId,
      })
    ),
    materialabtransport: fuhrparkeintrag.materialabtransport.map(
      (materialabtransport: MaterialTransportDaten) => ({
        id: materialabtransport.id,
        baustelleId: materialabtransport.baustelleId,
        fuhrTyp: materialabtransport.fuhrTyp,
        buchungsTyp: materialabtransport.buchungsTyp,
        // @ts-ignore
        zielAbladestelle: materialabtransport.zielAbladestelleId,
        // @ts-ignore
        zielBaustelle: materialabtransport.zielBaustelleId,
        // @ts-ignore
        quelleBaustelle: materialabtransport.quelleBaustelleId,
        // @ts-ignore
        material: materialabtransport.materialId,
        preisGesamt: materialabtransport.preisGesamt,
        mengeGesamt: materialabtransport.mengeGesamt,
        menge: materialabtransport.menge,
        einheit: materialabtransport.einheit,
        lieferscheinNummer: materialabtransport.lieferscheinNummer,
        bemerkung: materialabtransport.bemerkung,
        meLadeVolumenFahrzeug: materialabtransport.meLadeVolumenFahrzeug,
        buchungsArt: materialabtransport.buchungsArt,
        bsNr: materialabtransport.bsNr,
        // @ts-ignore
        entsorger: materialabtransport.entsorgerId,
        // @ts-ignore
        erzeuger: materialabtransport.erzeugerId,
        kostenProEinheit: materialabtransport.kostenProEinheit,
        anzahlFuhren: materialabtransport.anzahlFuhren,
        // @ts-ignore
        abfallart: materialabtransport.abfallartId,
        fuhrparkeintragId: materialabtransport.fuhrparkeintragId,
      })
    ),
    materialtransport: fuhrparkeintrag.materialtransport.map(
      (materialtransport: MaterialTransportDaten) => ({
        id: materialtransport.id,
        baustelleId: taetigkeitsbericht.baustelleId,
        // @ts-ignore
        zielBaustelle: materialtransport.zielBaustelleId,
        // @ts-ignore
        material: materialtransport.materialId,
        buchungsTyp: materialtransport.buchungsTyp,
        lieferscheinNummer: materialtransport.lieferscheinNummer,
        bemerkung: materialtransport.bemerkung,
        einheit: materialtransport.einheit,
        anzahlFuhren: materialtransport.anzahlFuhren,
        kostenProEinheit: materialtransport.kostenProEinheit,
        menge: materialtransport.menge,
        preisGesamt: materialtransport.preisGesamt,
        gesamtMenge: materialtransport.mengeGesamt,
        buchungsArt: materialtransport.buchungsArt,
        meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
        // @ts-ignore
        abfallart: materialtransport.abfallartId,
        fuhrparkeintragId: materialtransport.fuhrparkeintragId,
      })
    ),
  }));

  return (
    <Layout
      title={capitalize(t("activity-reports-external"))}
      back={backRoute}
      onBackClick={handleBackClick}
    >
      <ConfirmNavigationDialog
        open={showConfirmDialog}
        onConfirm={handleConfirmBack}
        onCancel={handleCancelBack}
        title={t("confirm-cancel-title")}
        message={t("confirm-cancel-message")}
      />
      <Guard
        permission={"taetigkeitsbericht:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          {taetigkeitsbericht.deleted === undefined ? (
            <Guard permission={"taetigkeitsbericht:delete"}>
              <DeleteTaetigkeitsberichtButton
                id={taetigkeitsberichtId}
              ></DeleteTaetigkeitsberichtButton>
            </Guard>
          ) : (
            <></>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={{
              lieferant: taetigkeitsbericht?.lieferantId ?? "",
              baustelle: taetigkeitsbericht?.baustelleId ?? "",
              datum: taetigkeitsbericht?.datum ?? "",
              bearbeitetUm:
                timestampToDatetimeLocal(
                  taetigkeitsbericht?.updated?.timestamp
                ) ?? "",
              //@ts-ignore
              fuhrparkeintraege: fpArray,
            }}
            validationSchema={validationSchemaTaetigkeitsberichtExternDetails}
            // @ts-ignore
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <Typography variant="h5">
                  {capitalize(t("activity-reports-external"))}
                  {taetigkeitsbericht.deleted === undefined ? (
                    ""
                  ) : (
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  )}
                </Typography>
                <DialogContent>
                  <Form id="details-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContentExtern isNew={false} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ push, remove }) => (
                        <>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <FuhrparkProvider
                                removeFP={remove}
                                pushFP={push}
                                taetigkeitsberichtId={taetigkeitsberichtId}
                                fuhrparkeintragIndex={index}
                                fahrzeugHerkunft={"FREMD_GERAET"}
                                fuhrparkeintrag={fuhrparkeintrag}
                              >
                                <Box key={index} sx={{ marginTop: 2 }}>
                                  <Box
                                    display={"grid"}
                                    sx={{
                                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                                      gap: 2,
                                      marginTop: 2,
                                    }}
                                  >
                                    <ExpandContextProvider
                                      id={`fuhrparkeintrag-${index}`}
                                    >
                                      <FuhrparkeintragFremdPersonalItem
                                        isNew={!fuhrparkeintrag.id}
                                        // @ts-ignore
                                        fuhrparkeintrag={fuhrparkeintrag}
                                        index={index}
                                        taetigkeitsberichtDatum={values.datum}
                                        dauerVorhanden={0}
                                        isValidating={isValidating}
                                        isValid={isValid}
                                      />
                                    </ExpandContextProvider>
                                  </Box>
                                </Box>
                              </FuhrparkProvider>
                            )
                          )}
                          <Box
                            display={"grid"}
                            sx={{
                              gridTemplateColumns: [
                                "1fr",
                                "1fr 1fr 1fr 1fr 1fr 8fr",
                              ],
                              gap: 2,
                              marginTop: 4,
                            }}
                          >
                            <NeuerMaterialanfuhrTtbExternButton push={push} />
                            <NeuerMaterialtransportZwischenBaustellenTtbExternButton
                              push={push}
                            />
                            <NeuerMaterialabtransportTtbExternButton
                              push={push}
                            />
                          </Box>
                          <TaetigkeitsberichtExternSummenzeile
                            fuhrparkeintraege={values.fuhrparkeintraege}
                          />
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>

                <DialogActions>
                  <SubmitButton
                    form="details-taetigkeitsbericht"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
