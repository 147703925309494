import {
  Box,
  Button,
  capitalize,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useState } from "react";
import { useDeleteFuhrparkeintragAnbauteil } from "../../../../client/hooks";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ZugewieseneAnbauteileSelect from "../../../fuhrpark/anbauteil/ZugewieseneAnbauteileSelect";
import {
  emptyFuhrparkeintragAnbauteil,
  ExtendedFuhrparkeintragAnbauteilDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { useFuhrparkAnbauteilFieldContent } from "../../../../hooks/useFuhrparkAnbauteilFieldContent";

export default function FuhrparkeintragAnbauteileFieldsContent({
  isNew,
  index,
  anbauteilIndex,
  fahrzeugId,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  push,
  remove,
  newestIndex,
  fuhrparkeintragAnbauteil,
  isFremdleistung,
}: {
  isNew: boolean;
  index: number;
  anbauteilIndex: number;
  fahrzeugId: string;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten;
  isFremdleistung: boolean;
}) {
  const { t } = useT("taetigkeitsbericht");
  const tabIndex = isFremdleistung ? 1 : 0;

  const deleteFuhrparkeintragAnbauteil = useDeleteFuhrparkeintragAnbauteil();

  const [anchorElAnbauteilanlegenInfo, setAnchorElAnbauteilanlegenInfo] =
    useState<HTMLElement | null>(null);
  const handlePopoverOpenAnbauteilanlegenInfo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElAnbauteilanlegenInfo(event.currentTarget);
  };

  const handlePopoverCloseAnbauteilanlegenInfo = () => {
    setAnchorElAnbauteilanlegenInfo(null);
  };

  const openAnbauteilanlegenInfo = Boolean(anchorElAnbauteilanlegenInfo);

  useFuhrparkAnbauteilFieldContent({
    index,
    anbauteilIndex,
    fahrzeugId,
    taetigkeitsberichtDatum,
    dauerVorhanden,
    isNew,
    fuhrparkeintragAnbauteil,
  });

  const handleAnbauteilDelete = (remove: any) => {
    const fuhrparkeintragAnbauteilId = fuhrparkeintragAnbauteil.id;
    if (fuhrparkeintragAnbauteilId) {
      deleteFuhrparkeintragAnbauteil(
        fuhrparkeintragAnbauteil.taetigkeitsberichtId,
        fuhrparkeintragAnbauteil.fuhrparkeintragId,
        fuhrparkeintragAnbauteilId
      ).then(() => {
        remove(anbauteilIndex);
      });
    } else {
      remove(anbauteilIndex);
    }
  };

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 6.2fr .5fr 3fr 2fr 1fr 1fr"],
          gap: 2,
        }}
      >
        <Field
          component={TextField}
          label={capitalize(t("carpool-entry-duration"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`}
          type={"number"}
          inputProps={{
            tabIndex: tabIndex,
            min: 0,
            step: 0.25,
            style: { textAlign: "right" },
          }}
          required
        />
        <Field
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-pieces"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilAnzahl`}
          type={"number"}
          inputProps={{
            tabIndex: tabIndex,
            min: 1,
            style: { textAlign: "right" },
          }}
          required
        />
        <Field
          key={fahrzeugId}
          component={ZugewieseneAnbauteileSelect}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteil`}
          label={capitalize(t("carpool-entry-attachment-part"))}
          fahrzeugId={fahrzeugId}
          inputProps={{
            tabIndex: tabIndex,
          }}
          required
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.open(`/fahrzeuge/${fahrzeugId}`)}
          aria-owns={
            openAnbauteilanlegenInfo ? "mouse-over-popover" : undefined
          }
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenAnbauteilanlegenInfo}
          onMouseLeave={handlePopoverCloseAnbauteilanlegenInfo}
          disabled={!fahrzeugId}
          tabIndex={tabIndex}
        >
          <AddIcon />
        </Button>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={openAnbauteilanlegenInfo}
          anchorEl={anchorElAnbauteilanlegenInfo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseAnbauteilanlegenInfo}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {capitalize(t("create-attachment-part-info"))}
          </Typography>
        </Popover>
        <Field
          component={TextField}
          label={capitalize(t("carpool-entry-price"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`}
          type={"number"}
          inputProps={{
            tabIndex: tabIndex,
            min: 0,
            step: 0.01,
            style: { textAlign: "right" },
          }}
          required
        />
        <Field
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-costs"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
        {anbauteilIndex === newestIndex ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              push(emptyFuhrparkeintragAnbauteil);
            }}
            disabled={!fahrzeugId}
            tabIndex={tabIndex}
          >
            <AddCircleIcon />
          </Button>
        ) : (
          <></>
        )}
        <IconButton
          tabIndex={tabIndex}
          onClick={() => {
            handleAnbauteilDelete(remove);
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  );
}
