import { HerkunftTyp } from "../../api";
import { ExtendedFuhrparkeintragDaten } from "../../models/taetigkeitsberichte/models";
import React from "react";
import { Stack } from "@mui/material";
import { MaterialanfuhrArray } from "./fuhrparkeintraege/transportArrays/MaterialanfuhrArray";
import { MaterialtransportArray } from "./fuhrparkeintraege/transportArrays/MaterialtransportArray";
import { MaterialabtransportArray } from "./fuhrparkeintraege/transportArrays/MaterialabtransportArray";

interface TaetigkeitsberichtExternFuhrenProps {
  index: number;
  isFremdleistung: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
}

export default function TaetigkeitsberichtExternFuhren({
  index,
  isFremdleistung,
  fuhrparkeintrag,
}: TaetigkeitsberichtExternFuhrenProps) {
  return (
    <Stack direction="column" sx={{ marginTop: -5 }}>
      <MaterialanfuhrArray
        index={index}
        fuhrparkeintrag={fuhrparkeintrag}
        isFremdleistung={isFremdleistung}
      />
      <MaterialtransportArray
        index={index}
        fuhrparkeintrag={fuhrparkeintrag}
        isFremdleistung={isFremdleistung}
      />
      <MaterialabtransportArray
        index={index}
        fuhrparkeintrag={fuhrparkeintrag}
        isFremdleistung={isFremdleistung}
      />
    </Stack>
  );
}
