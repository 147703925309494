import React, { memo } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialKostenGrid from "../../components/material/materialkosten/MaterialkostenGrid";
import { Material } from "./AbladestellenDetails";

interface MaterialDialogProps {
  open: boolean;
  onClose: () => void;
  material: Material;
  materialId?: string;
  abladestelleId: string;
}

const MaterialDialog = memo(
  ({
    open,
    onClose,
    material,
    materialId,
    abladestelleId,
  }: MaterialDialogProps) => {
    const { t } = useTranslation("abladestelle");

    return (
      <Dialog
        title={t("new-attachment-part")}
        open={open}
        onClose={onClose}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogContent>
          {materialId ? (
            <MaterialKostenGrid
              materialId={materialId}
              abladestellenId={abladestelleId}
              material={material}
            />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.open === nextProps.open &&
      prevProps.materialId === nextProps.materialId
    );
  }
);

export default MaterialDialog;
