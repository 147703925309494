import { useTranslation } from "react-i18next";
import { Box, capitalize, Stack } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import BRZBenutzerSelect from "./BRZBenutzerSelect";

export default function BrzExportForm() {
  const { t } = useTranslation("taetigkeitsbericht");

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={TextField}
          name="start"
          label={capitalize(t("start-date"))}
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
        />
        <Field
          component={TextField}
          name="ende"
          label={capitalize(t("end-date"))}
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Field
            component={BRZBenutzerSelect}
            name="mitarbeiter"
            label={t("activity-report-user")}
            fullWidth
          ></Field>
        </Box>
      </Box>
    </Stack>
  );
}
