import { useState } from "react";
import { useField } from "formik";
import { useGesamtBerechnung } from "./useGesamtBerechnung";
import { useMaterialPreisUndMenge } from "./useMaterialPreisUndMenge";
import { useFahrzeugLadeMenge } from "./useFahrzeugLadeMenge";

interface UseMaterialtransportProps {
  index: number;
  materialTransportIndex: number;
  fahrzeugId: string;
  materialtransport: any;
}

export const useMaterialtransport = ({
  index,
  materialTransportIndex,
  fahrzeugId,
  materialtransport,
}: UseMaterialtransportProps) => {
  const [isKosten, setIsKosten] = useState(
    materialtransport.buchungsArt === "Kosten"
  );

  const [baustelleField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.zielBaustelle`
  );
  const [abfallartField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.abfallart`
  );
  const [lfsnrField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.lieferscheinNummer`
  );
  const [bemerkungField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.bemerkung`
  );
  const [buchungsArtField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.buchungsArt`
  );

  const { currentFahrzeugLadeMenge } = useFahrzeugLadeMenge({ fahrzeugId });

  const { einheitField } = useMaterialPreisUndMenge({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransportId: materialtransport.id,
    type: "materialtransport",
  });

  const { mengeField, preisField, fuhrenField } = useGesamtBerechnung({
    index,
    materialTransportIndex,
    type: "materialtransport",
  });

  return {
    isKosten,
    setIsKosten,
    baustelleField,
    abfallartField,
    lfsnrField,
    bemerkungField,
    buchungsArtField,
    einheitField,
    mengeField,
    preisField,
    fuhrenField,
    currentFahrzeugLadeMenge,
  };
};
