import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
  ExtendedTaetigkeitsberichtExternDaten,
  MaterialTransportDaten,
} from "./models";
import {
  FuhrparkeintragAnbauteilDaten,
  FuhrparkeintragDaten,
  type MaterialtransportDaten,
  MaterialtransportZwischenDaten,
  PersonaleintragDaten,
  Taetigkeitsbericht,
  TaetigkeitsberichtDaten,
} from "../../api";
import {
  getBaustelleId,
  getMitarbeiterId,
  getAnbauteilId,
  getFahrzeugId,
  getQuelleBaustelleId,
  getMaterialId,
  getEntsorgerId,
  getErzeugerId,
  getZielAbladestelleId,
  getTransportiertesFahrzeugId,
  getAbholortBaustelleId,
  getAbstelltOrtBaustelleId,
  getLieferantId,
} from "./taetigkeitsberichtMutationHelper";
import { Buchungstyp } from "./buchungstyp";

//TODO: Die Datentypen any in der gesamten Datei müssen auf die Korrekten Datentypen der generierten
// Hooks angepasst werden. Damit dies möglich ist, müssen wir weg von den selbsterstellten Datenstrukturen
// und mit den generierten Models arbeiten

export const saveUpdatedTaetigkeitsbericht = async (
  taetigkeitsberichtId: string,
  values: ExtendedTaetigkeitsberichtDaten,
  alterTaetigkeitsberichtstatus: string | undefined,
  updateTaetigkeitsbericht: any
) => {
  const baustelleId = getBaustelleId(values);

  const taetigkeitsberichtUpdatedData: TaetigkeitsberichtDaten =
    alterTaetigkeitsberichtstatus === "OFFEN"
      ? {
          baustelleId: baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "INTERN",
          status: "ANGENOMMEN",
        }
      : {
          baustelleId: baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "INTERN",
          status: "ANGENOMMEN",
        };

  return await updateTaetigkeitsbericht(
    taetigkeitsberichtId,
    taetigkeitsberichtUpdatedData
  );
};

export const saveUpdatedTatigketsberichtExtern = async (
  taetigkeitsberichtId: string,
  values: ExtendedTaetigkeitsberichtExternDaten,
  updateTaetigkeitsbericht: any
) => {
  const baustelleId: string = getBaustelleId(values);
  const lieferantId: string = getLieferantId(values);

  const taetigkeitsberichtUpdatedData: TaetigkeitsberichtDaten = {
    baustelleId: baustelleId,
    datum: values.datum,
    taetigkeitsberichtTyp: "EXTERN",
    lieferantId: lieferantId,
  };

  return await updateTaetigkeitsbericht(
    taetigkeitsberichtId,
    taetigkeitsberichtUpdatedData
  );
};

export const saveUpdatedPersonaleintrag = async (
  taetigkeitsberichtId: string,
  values: ExtendedTaetigkeitsberichtDaten,
  updatePersonaleintrag: any
) => {
  const mitarbeiterId = getMitarbeiterId(values);
  const baustelleId = getBaustelleId(values);

  const personaleintragData: PersonaleintragDaten = {
    mitarbeiterId: mitarbeiterId,
    baustelleId: baustelleId,
    datum: values.datum,
    arbeitszeitNetto: values.arbeitszeitNetto ?? undefined,
    stundensatz: values.stundensatz,
    bemerkung: values.bemerkung,
  };

  return await updatePersonaleintrag(taetigkeitsberichtId, personaleintragData);
};

export const saveUpdatedFuhrparkeintrag = async (
  taetigkeitsberichtId: string,
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten,
  updateFuhrparkeintrag: any
) => {
  if (!fuhrparkeintrag.id) throw new Error("Fuhrparkeintrag ID not found");

  const fuhrparkeintragData: FuhrparkeintragDaten = {
    fahrzeugId: getFahrzeugId(fuhrparkeintrag),
    fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
    fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
    fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
    bemerkung: fuhrparkeintrag.bemerkung,
    datum: fuhrparkeintrag.datum,
  };

  return await updateFuhrparkeintrag(
    taetigkeitsberichtId,
    fuhrparkeintrag.id,
    fuhrparkeintragData
  );
};

export const saveUpdatedAnbauteile = async (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  anbauteil: ExtendedFuhrparkeintragAnbauteilDaten,
  updateFuhrparkeintragAnbauteil: any
) => {
  if (!anbauteil.id) throw new Error("Anbauteil ID not found");

  const anbauteilData: FuhrparkeintragAnbauteilDaten = {
    anbauteilId: getAnbauteilId(anbauteil),
    anbauteilNutzungsdauer: anbauteil.anbauteilNutzungsdauer,
    anbauteilAnzahl: anbauteil.anbauteilAnzahl,
    anbauteilStundenPreis: anbauteil.anbauteilStundenPreis,
  };

  return await updateFuhrparkeintragAnbauteil(
    taetigkeitsberichtId,
    fuhrparkeintragId,
    anbauteil.id,
    anbauteilData
  );
};

export const saveUpdatedMaschinentransporte = async (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  maschinentransport: any,
  baustelleId: string,
  updateMaschinentransport: any
) => {
  if (!maschinentransport.id) return null;

  const maschinentransportData = {
    baustelleId: baustelleId,
    transportiertesFahrzeugId: getTransportiertesFahrzeugId(maschinentransport),
    abholortBaustellenId: getAbholortBaustelleId(maschinentransport),
    abstellortBaustellenId: getAbstelltOrtBaustelleId(maschinentransport),
    fuhrTyp: maschinentransport.fuhrTyp,
    lieferscheinNummer: maschinentransport.lieferscheinNummer,
    bemerkung: maschinentransport.bemerkung,
  };

  return await updateMaschinentransport(
    taetigkeitsberichtId,
    fuhrparkeintragId,
    maschinentransport.id,
    maschinentransportData
  );
};

export const saveUpdatedMaterialanfuhr = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  materialtransport: MaterialTransportDaten,
  updateMaterialtransport: any
) => {
  const quelleAbladestelleId = getQuelleBaustelleId(materialtransport);
  const materialId: string = getMaterialId(materialtransport);

  const materialtransportData: MaterialtransportDaten = {
    baustelleId: taetigkeitsbericht.baustelleId,
    quelleAbladestelleId,
    materialId: materialId,
    buchungsTyp: Buchungstyp.MA,
    fuhrTyp: Buchungstyp.MA,
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    kostenProEinheit: materialtransport.kostenProEinheit,
    menge: materialtransport.menge,
    buchungsArt: materialtransport.buchungsArt,
  };

  return await updateMaterialtransport(
    taetigkeitsbericht.id,
    materialtransport.fuhrparkeintragId,
    materialtransport.id,
    materialtransportData
  );
};

export const saveUpdatedMaterialabtransport = async (
  taetigkeitsbericht: Taetigkeitsbericht,
  materialtransport: MaterialTransportDaten,
  updateMaterialtransport: any
) => {
  const zielAbladestelleId = getZielAbladestelleId(materialtransport);
  const materialId: string = getMaterialId(materialtransport);

  const materialtransportData: MaterialtransportDaten = {
    baustelleId: taetigkeitsbericht.baustelleId,
    zielAbladestelleId,
    materialId: materialId,
    buchungsTyp: materialtransport.buchungsTyp,
    fuhrTyp: materialtransport.fuhrTyp,
    bsNr: materialtransport.bsNr,
    entsorgerId: getEntsorgerId(materialtransport),
    erzeugerId: getErzeugerId(materialtransport),
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    kostenProEinheit: materialtransport.kostenProEinheit,
    menge: materialtransport.menge,
    buchungsArt: materialtransport.buchungsArt,
  };

  return await updateMaterialtransport(
    taetigkeitsbericht.id,
    materialtransport.fuhrparkeintragId,
    materialtransport.id,
    materialtransportData
  );
};

export const saveUpdatedMaterialtransportZwischenBaustellen = async (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  materialtransport: MaterialTransportDaten,
  baustelleId: string,
  updateMaterialtransportZwischenBaustellen: any
) => {
  if (!materialtransport.id) throw new Error("Materialtransport ID not found");

  const materialtransportData: MaterialtransportZwischenDaten = {
    baustelleId: baustelleId,
    zielBaustelleId:
      typeof materialtransport.zielBaustelle === "string"
        ? materialtransport.zielBaustelle
        : materialtransport.zielBaustelle?.id ?? null,
    quelleBaustelleId: baustelleId,
    materialId: materialtransport.material,
    //TODO: Richtigen Datentyp verwenden, da der Mix von Datentypen das Attribut nicht kennt
    //@ts-ignore
    buchungsTyp: materialtransport.buchungsTyp,
    lieferscheinNummer: materialtransport.lieferscheinNummer,
    bemerkung: materialtransport.bemerkung,
    einheit: materialtransport.einheit,
    anzahlFuhren: materialtransport.anzahlFuhren,
    kostenProEinheit: materialtransport.kostenProEinheit,
    menge: materialtransport.menge,
    buchungsArt: materialtransport.buchungsArt,
    abfallartId:
      typeof materialtransport.abfallart === "string"
        ? materialtransport.abfallart
        : materialtransport.abfallart?.id ?? null,
  };

  return await updateMaterialtransportZwischenBaustellen(
    taetigkeitsberichtId,
    fuhrparkeintragId,
    materialtransport.id,
    materialtransportData
  );
};
