import { Box, capitalize, Divider, Stack, Typography } from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import BaustellenSelect from "../baustellen/BaustellenSelect";
import { useEffect, useState } from "react";
import { useGetBenutzer } from "../../client/hooks";
import BenutzerUpdateSelect from "../benutzer/BenutzerUpdateSelect";

export default function TaetigkeitsberichtFieldsContent({
  isNew,
}: {
  isNew: boolean;
}) {
  const { t } = useT("taetigkeitsbericht");
  const { setFieldValue } = useFormikContext();
  const [stundenField] = useField("arbeitszeitNetto");
  const [stundensatzField] = useField("stundensatz");
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const searchBenutzer = useGetBenutzer();

  const calculateLohnkosten = () => {
    setFieldValue(
      "lohnkosten",
      (stundensatzField.value * stundenField.value).toFixed(2)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentUserId) {
        const benutzer = await searchBenutzer(currentUserId);
        await setFieldValue("stundensatz", benutzer.berechnungsSatz);
      }
    };
    fetchData();
  }, [setCurrentUserId, currentUserId]);

  useEffect(() => {
    calculateLohnkosten();
  }, [stundenField.value, stundensatzField.value]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={BenutzerUpdateSelect}
          name="mitarbeiter"
          label={capitalize(t("activity-report-user"))}
          required
          setBenutzerChanged={setCurrentUserId}
        />
        <FastField
          component={BaustellenSelect}
          name="baustelle"
          label={capitalize(t("activity-report-construction"))}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-date"))}
          name="datum"
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-time-net"))}
          name="arbeitszeitNetto"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-hourly-rate"))}
          name="stundensatz"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-personal-cost"))}
          name="lohnkosten"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-remark"))}
          name="bemerkung"
          type={"box"}
        />
        {!isNew && (
          <FastField
            component={TextField}
            label={capitalize(t("processing-time"))}
            name="bearbeitetUm"
            type={"datetime-local"}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        )}
      </Box>
    </Stack>
  );
}
