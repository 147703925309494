/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UmsatzAuswertungDaten } from '../models/UmsatzAuswertungDaten';
import type { UmsatzAuswertungListe } from '../models/UmsatzAuswertungListe';
import type { UmsatzListe } from '../models/UmsatzListe';
import type { UmsatzListeArray } from '../models/UmsatzListeArray';
import type { UmsatzmengenAuswertung } from '../models/UmsatzmengenAuswertung';
import type { UmsatzmengenAuswertungRequest } from '../models/UmsatzmengenAuswertungRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UmsatzService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns UmsatzmengenAuswertung Get umsatz Mengenauswertung
   * @throws ApiError
   */
  public getUmsatzMengenAuswertungByBaustellenId({
    baustellenId,
    richtung,
  }: {
    baustellenId: string,
    richtung: string,
  }): CancelablePromise<UmsatzmengenAuswertung> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/umsatz/auswertung/{baustellenId}/{richtung}',
      path: {
        'baustellenId': baustellenId,
        'richtung': richtung,
      },
      errors: {
        404: `Baustelle not found`,
      },
    });
  }

  /**
   * @returns UmsatzmengenAuswertung Get umsatz Mengen Auswertung By Date
   * @throws ApiError
   */
  public getUmsatzMengenAuswertungByDate({
    baustellenId,
    richtung,
    requestBody,
  }: {
    baustellenId: string,
    richtung: string,
    requestBody: UmsatzmengenAuswertungRequest,
  }): CancelablePromise<UmsatzmengenAuswertung> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/umsatz/auswertung/{baustellenId}/{richtung}',
      path: {
        'baustellenId': baustellenId,
        'richtung': richtung,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Umsätze.
   * @returns UmsatzListe Die Seite der Umsätze.
   * @throws ApiError
   */
  public sucheUmsatz({
    search,
    isTaetigkeitsbericht,
    baustellenId,
    filtered,
    page,
    limit,
    sort,
    isFremdleistung,
    startDate,
    endDate,
  }: {
    search?: string,
    isTaetigkeitsbericht?: boolean,
    baustellenId?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
    isFremdleistung?: boolean,
    startDate?: string,
    endDate?: string,
  }): CancelablePromise<UmsatzListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/umsatz',
      query: {
        'search': search,
        'isTaetigkeitsbericht': isTaetigkeitsbericht,
        'baustellenId': baustellenId,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
        'isFremdleistung': isFremdleistung,
        'startDate': startDate,
        'endDate': endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns UmsatzListeArray Die Seite der Umsaetze.
   * @throws ApiError
   */
  public sucheUmsatzByBaustelleImZeitraum({
    baustellenId,
    requestBody,
  }: {
    baustellenId: string,
    requestBody: {
      startDate: string;
      endDate: string;
    },
  }): CancelablePromise<UmsatzListeArray> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/umsatz/baustelle/{baustellenId}',
      path: {
        'baustellenId': baustellenId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Umsätze einer Baustelle.
   * @returns UmsatzAuswertungListe Die Seite der Auswertungen für das Konto.
   * @throws ApiError
   */
  public sucheUmsatzAuswertungByBaustellenId({
    baustellenId,
  }: {
    baustellenId: string,
  }): CancelablePromise<UmsatzAuswertungListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/umsatz/konto/{baustellenId}',
      path: {
        'baustellenId': baustellenId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns UmsatzAuswertungListe Die Seite der Umsatzdetails.
   * @throws ApiError
   */
  public sucheUmsatzAuswertungByBaustellenIdAndByDate({
    baustellenId,
    requestBody,
  }: {
    baustellenId: string,
    requestBody: UmsatzAuswertungDaten,
  }): CancelablePromise<UmsatzAuswertungListe> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/umsatz/details/{baustellenId}',
      path: {
        'baustellenId': baustellenId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
