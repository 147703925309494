import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import {
  ExtendedFuhrparkeintragDaten,
  MaterialTransportDaten,
} from "../../models/taetigkeitsberichte/models";
import React, { FC, useMemo } from "react";

interface TaetigkeitsberichtExternSummenzeileProps {
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

const TaetigkeitsberichtExternSummenzeile: FC<
  TaetigkeitsberichtExternSummenzeileProps
> = ({ fuhrparkeintraege }: TaetigkeitsberichtExternSummenzeileProps) => {
  const calculateTotals = (
    fuhrparkeintraege: ExtendedFuhrparkeintragDaten[]
  ) => {
    let totalFuhren: number = 0;
    const anfuhrTypen: Record<string, number> = {};
    const abfuhrTypen: Record<string, number> = {};
    let gesamtKosten: number = 0;
    let gesamtErtrag: number = 0;

    const processEntries = (
      entries: MaterialTransportDaten[],
      typMap: Record<string, number>
    ) => {
      entries.forEach(
        ({ buchungsArt, preisGesamt, mengeGesamt, anzahlFuhren, einheit }) => {
          const preis = Number(preisGesamt);
          if (buchungsArt === "Kosten") gesamtKosten += preis;
          else if (buchungsArt === "Ertrag") gesamtErtrag += preis;

          totalFuhren += anzahlFuhren;
          typMap[einheit] = (typMap[einheit] || 0) + mengeGesamt;
        }
      );
    };

    fuhrparkeintraege.forEach(
      ({ materialanfuhr, materialabtransport, materialtransport }) => {
        processEntries(materialanfuhr, anfuhrTypen);
        processEntries(materialabtransport, abfuhrTypen);
        processEntries(materialtransport, abfuhrTypen);
      }
    );

    return {
      totalFuhren,
      anfuhrTypen,
      abfuhrTypen,
      gesamtKosten,
      gesamtErtrag,
    };
  };

  const { totalFuhren, anfuhrTypen, abfuhrTypen, gesamtKosten, gesamtErtrag } =
    useMemo(() => calculateTotals(fuhrparkeintraege), [fuhrparkeintraege]);

  return (
    <Box sx={{ marginTop: 2, paddingRight: "15em" }}>
      <Table>
        <TableBody>
          <TableRow sx={{ borderTop: "1px solid #ccc", marginBottom: -2 }}>
            <TableCell colSpan={4} sx={{ padding: 0.5 }}></TableCell>
          </TableRow>
          <TableRow sx={{ borderTop: "1px solid #ccc" }}>
            <TableCell sx={{ borderRight: "1px solid #ccc" }}>
              {totalFuhren} Fuhre(n)
            </TableCell>
            <TableCell sx={{ width: 200 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "stretch" }}>
                  <Box>Anfuhr</Box>
                  <Box
                    sx={{
                      width: "5em",
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Object.entries(anfuhrTypen).map(([typ, anzahl]) => (
                      <Box key={typ}>
                        {anzahl.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {typ}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                borderRight: "1px solid #ccc",
                width: 200,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "stretch" }}>
                  <Box>Abfuhr</Box>
                  <Box
                    sx={{
                      width: "5em",
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Object.entries(abfuhrTypen).map(([typ, anzahl]) => (
                      <Box key={typ}>
                        {anzahl.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {typ}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }}>Kosten</Box>
                  <Box
                    sx={{
                      textAlign: "right",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {gesamtKosten.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }}>Ertrag</Box>
                  <Box
                    sx={{
                      textAlign: "right",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {gesamtErtrag.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default TaetigkeitsberichtExternSummenzeile;
