import { useField } from "formik";
import { Baustelle, Fahrzeug } from "../api";
import { useDeleteMaschinentransport } from "../client/hooks";

interface UseMaschinentransportProps {
  index: number;
  maschinenTransportIndex: number;
  maschinentransport: any;
}

export const useMaschinentransport = ({
  index,
  maschinenTransportIndex,
  maschinentransport,
}: UseMaschinentransportProps) => {
  const deleteMaschinentransport = useDeleteMaschinentransport();

  const [transportiertesFahrzeugField] = useField(
    `fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.transportiertesFahrzeug`
  );
  const [abholortBaustelleField] = useField(
    `fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abholortBaustelle`
  );
  const [abstellortBaustelleField] = useField(
    `fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abstellortBaustelle`
  );
  const [lfsnrField] = useField(
    `fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.lieferscheinNummer`
  );
  const [bemerkungField] = useField(
    `fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.bemerkung`
  );

  //TODO: Mach mich schön
  const getCloneOfMaschinentransport = () => ({
    transportiertesFahrzeug:
      typeof transportiertesFahrzeugField.value === "string"
        ? transportiertesFahrzeugField.value
        : (transportiertesFahrzeugField.value as Fahrzeug)?.id ?? "",
    abholortBaustelle:
      typeof abholortBaustelleField.value === "string"
        ? abholortBaustelleField.value
        : (abholortBaustelleField.value as Baustelle)?.id ?? "",
    abstellortBaustelle:
      typeof abstellortBaustelleField.value === "string"
        ? abstellortBaustelleField.value
        : (abstellortBaustelleField.value as Baustelle)?.id ?? "",
    lieferscheinNummer: lfsnrField.value ?? "",
    bemerkung: bemerkungField.value ?? "",
  });

  const handleDelete = async (remove: (index: number) => void) => {
    const maschinentransportId = maschinentransport.id;
    if (maschinentransportId) {
      await deleteMaschinentransport(
        maschinentransport.taetigkeitsberichtId,
        maschinentransport.fuhrparkeintragId,
        maschinentransportId
      );
    }
    remove(maschinenTransportIndex);
  };

  return {
    transportiertesFahrzeugField,
    abholortBaustelleField,
    abstellortBaustelleField,
    lfsnrField,
    bemerkungField,
    getCloneOfMaschinentransport,
    handleDelete,
  };
};
