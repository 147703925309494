import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import {
  ExtendedTaetigkeitsberichtDaten,
  MaterialTransportDaten,
} from "../models/taetigkeitsberichte/models";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useGetTaetigkeitsbericht,
  useUpdateFuhrparkeintrag,
  useUpdateFuhrparkeintragAnbauteil,
  useUpdateMaschinentransport,
  useUpdateMaterialtransport,
  useUpdateMaterialtransportZwischen,
  useUpdatePersonaleintrag,
  useUpdateTaetigkeitsbericht,
} from "../client/hooks";
import {
  saveFuhrparkeintrag,
  saveFuhrparkeintragAnbauteil,
  saveMaschinentransporte,
  saveMaterialabtransport,
  saveMaterialanfuhr,
  saveMaterialtransportZwischenBaustellen,
} from "../models/taetigkeitsberichte/taetigkeitsBerichtMutations";
import {
  saveUpdatedAnbauteile,
  saveUpdatedFuhrparkeintrag,
  saveUpdatedMaschinentransporte,
  saveUpdatedMaterialabtransport,
  saveUpdatedMaterialanfuhr,
  saveUpdatedMaterialtransportZwischenBaustellen,
  saveUpdatedPersonaleintrag,
  saveUpdatedTaetigkeitsbericht,
} from "../models/taetigkeitsberichte/editTaetigekeitsberichtMutations";
import { Taetigkeitsbericht } from "../api";
import { enqueueSnackbar } from "notistack";

export const useTaetigkeitsberichtDetails = (
  taetigkeitsberichtId: string,
  expandedItems: boolean[],
  expandedIndex: number | null,
  berichtOffen: boolean,
  shouldNavigate: boolean = true,
  invalidateParams?: {
    queryKey?: [string, { addEmptyEntry: boolean }];
  }
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Hooks für API-Aufrufe
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();
  const updatePersonaleintrag = useUpdatePersonaleintrag();

  const updateFuhrparkeintrag = useUpdateFuhrparkeintrag();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();

  const updateFuhrparkeintragAnbauteil = useUpdateFuhrparkeintragAnbauteil();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();

  const updateMaschinentransport = useUpdateMaschinentransport();
  const createMaschinentransport = useCreateMaschinentransport();

  const updateMaterialtransport = useUpdateMaterialtransport();
  const createMaterialtransport = useCreateMaterialtransport();

  const updateMaterialtransportZwischenBaustellen =
    useUpdateMaterialtransportZwischen();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();

  return useMutation(
    async (values: ExtendedTaetigkeitsberichtDaten) => {
      const tb = await getTaetigkeitsbericht(taetigkeitsberichtId);
      const alterTaetigkeitsberichtstatus = tb?.status;

      const updatedTaetigkeitsbericht: Taetigkeitsbericht =
        await saveUpdatedTaetigkeitsbericht(
          taetigkeitsberichtId,
          values,
          alterTaetigkeitsberichtstatus,
          updateTaetigkeitsbericht
        );

      await saveUpdatedPersonaleintrag(
        taetigkeitsberichtId,
        values,
        updatePersonaleintrag
      );
      //TODO: gleiche Logik wie in useTaetigkeitsberichtDetailsExtern und kann zusammengefasst werden
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          if (fuhrparkeintrag.id) {
            return saveUpdatedFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintrag,
              updateFuhrparkeintrag
            );
          } else {
            return saveFuhrparkeintrag(
              updatedTaetigkeitsbericht,
              fuhrparkeintrag,
              createFuhrparkeintrag
            );
          }
        }
      );

      const fuhrparkeintragResults = await Promise.all(
        fuhrparkeintragPromises.filter((p): p is Promise<any> => p !== null)
      );
      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      await Promise.all(
        processFuhrparkAnbauteile(
          values,
          fuhrparkeintragIds,
          taetigkeitsberichtId,
          createFuhrparkeintragAnbauteil,
          updateFuhrparkeintragAnbauteil
        )
      );

      await Promise.all(
        processMaschinentransporte(
          values,
          fuhrparkeintragIds,
          updatedTaetigkeitsbericht,
          taetigkeitsberichtId,
          updateMaschinentransport,
          createMaschinentransport
        )
      );

      await Promise.all(
        processMaterialanfuhren(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransport,
          createMaterialtransport
        )
      );

      await Promise.all(
        processMaterialabtransporte(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransport,
          createMaterialtransport
        )
      );

      await Promise.all(
        processMaterialtransporteZwischenBaustellen(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransportZwischenBaustellen,
          createMaterialtransportZwischenBaustellen
        )
      );

      return {
        taetigkeitsbericht: updatedTaetigkeitsbericht,
        alterStatus: alterTaetigkeitsberichtstatus,
      };
    },
    {
      onSuccess: async (result) => {
        await queryClient.refetchQueries({
          queryKey: invalidateParams?.queryKey,
        });

        localStorage.setItem("expandedItems", JSON.stringify(expandedItems));
        enqueueSnackbar("Änderungen gespeichert", { variant: "success" });
        if (shouldNavigate) {
          if (result.alterStatus === "OFFEN") {
            navigate(`/berichtfreigabe/`, { replace: true });
          } else {
            navigate(`/taetigkeitsberichte/`, { replace: true });
          }
        }
      },
      onError: (error) => {
        enqueueSnackbar("Fehler beim Speichern " + error, { variant: "error" });
      },
    }
  );
};
//TODO: Funktion von useTaetigkeitsberichtDetailsExtern können auch hier verwendet und zusammengefasst werden
const processFuhrparkAnbauteile = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsberichtId: string,
  createFuhrparkeintragAnbauteil: any,
  updateFuhrparkeintragAnbauteil: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.fuhrparkeintragAnbauteile.map((anbauteil) => {
      if (anbauteil.id) {
        return saveUpdatedAnbauteile(
          taetigkeitsberichtId,
          currentFuhrparkEintragId,
          anbauteil,
          updateFuhrparkeintragAnbauteil
        );
      } else {
        return saveFuhrparkeintragAnbauteil(
          taetigkeitsberichtId,
          anbauteil,
          currentFuhrparkEintragId,
          createFuhrparkeintragAnbauteil
        );
      }
    });
  });
};

const processMaschinentransporte = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  taetigkeitsberichtId: string,
  updateMaschinentransport: any,
  createMaschinentransport: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.maschinentransporte.map((maschinentransport) => {
      if (maschinentransport.id) {
        return saveUpdatedMaschinentransporte(
          taetigkeitsberichtId,
          currentFuhrparkEintragId,
          maschinentransport,
          taetigkeitsbericht.baustelleId,
          updateMaschinentransport
        );
      } else {
        return saveMaschinentransporte(
          taetigkeitsbericht,
          currentFuhrparkEintragId,
          maschinentransport,
          createMaschinentransport
        );
      }
    });
  });
};

const processMaterialanfuhren = (
  values: ExtendedTaetigkeitsberichtDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransport: any,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
      if (materialanfuhr.id) {
        return saveUpdatedMaterialanfuhr(
          taetigkeitsbericht,
          materialanfuhr,
          updateMaterialtransport
        );
      } else {
        return saveMaterialanfuhr(
          taetigkeitsbericht,
          currentFuhrparkEintragId,
          materialanfuhr,
          createMaterialtransport
        );
      }
    });
  });
};

const processMaterialabtransporte = (
  values: ExtendedTaetigkeitsberichtDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransport: any,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.materialabtransport.map((materialabtransport) => {
      if (materialabtransport.id) {
        return saveUpdatedMaterialabtransport(
          taetigkeitsbericht,
          materialabtransport,
          updateMaterialtransport
        );
      } else {
        return saveMaterialabtransport(
          taetigkeitsbericht,
          currentFuhrparkEintragId,
          materialabtransport,
          createMaterialtransport
        );
      }
    });
  });
};

const processMaterialtransporteZwischenBaustellen = (
  values: ExtendedTaetigkeitsberichtDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransportZwischenBaustellen: any,
  createMaterialtransportZwischenBaustellen: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];

    return fuhrparkeintrag.materialtransport.map(
      (materialtransport: MaterialTransportDaten) => {
        if (materialtransport.id) {
          return saveUpdatedMaterialtransportZwischenBaustellen(
            taetigkeitsbericht.id,
            currentFuhrparkEintragId,
            materialtransport,
            taetigkeitsbericht.baustelleId,
            updateMaterialtransportZwischenBaustellen
          );
        } else {
          return saveMaterialtransportZwischenBaustellen(
            taetigkeitsbericht,
            currentFuhrparkEintragId,
            materialtransport,
            createMaterialtransportZwischenBaustellen
          );
        }
      }
    );
  });
};
