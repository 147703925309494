import { useCallback } from "react";
import { useFormikContext, useField } from "formik";
import { useGetFahrzeugKostenFuerDatum } from "../client/hooks";
import { ExtendedFuhrparkeintragDaten } from "../models/taetigkeitsberichte/models";
import { FahrzeugKosten } from "../api";

interface UseFuhrparkKostenProps {
  index: number;
  isNew: boolean;
  taetigkeitsberichtDatum?: string;
}

export const useFuhrparkKosten = ({
  index,
  isNew,
  taetigkeitsberichtDatum,
}: UseFuhrparkKostenProps) => {
  const { setFieldValue } = useFormikContext();
  const bekommeFahrzeugKosten = useGetFahrzeugKostenFuerDatum();

  const [fahrzeugField] = useField(`fuhrparkeintraege.${index}.fahrzeug`);
  const [fahrzeugStundenpreisField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugStundenPreis`
  );
  const [fahrzeugNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`
  );

  const updateFahrzeugStundenpreis = useCallback(
    async (fahrzeugId: string) => {
      if (fahrzeugId && isNew) {
        try {
          const datum: string = taetigkeitsberichtDatum
            ? new Date(taetigkeitsberichtDatum).toISOString()
            : new Date().toISOString();
          const fahrzeugStundenPreis: FahrzeugKosten =
            await bekommeFahrzeugKosten(fahrzeugId, { date: datum });

          await setFieldValue(
            `fuhrparkeintraege.${index}.fahrzeugStundenPreis`,
            fahrzeugStundenPreis.kostenStunde.toFixed(2)
          );
        } catch (error) {
          await setFieldValue(
            `fuhrparkeintraege.${index}.fahrzeugStundenPreis`,
            0
          );
        }
      }
    },
    [isNew, taetigkeitsberichtDatum, index]
  );

  const calculateFahrzeugkosten = useCallback(async () => {
    await setFieldValue(
      `fuhrparkeintraege.${index}.fahrzeugKosten`,
      (
        fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value
      ).toFixed(2)
    );
  }, [
    fahrzeugNutzungsdauerField.value,
    fahrzeugStundenpreisField.value,
    index,
  ]);

  const calculateGesamtKosten = useCallback(
    async (fuhrparkeintrag: ExtendedFuhrparkeintragDaten) => {
      const anbauteilGesamt = fuhrparkeintrag.fuhrparkeintragAnbauteile.reduce(
        (sum, anbauteil) =>
          sum +
          anbauteil.anbauteilAnzahl *
            anbauteil.anbauteilNutzungsdauer *
            anbauteil.anbauteilStundenPreis,
        0
      );

      const fahrzeugGesamt =
        fahrzeugNutzungsdauerField.value * fahrzeugStundenpreisField.value;

      await Promise.all([
        setFieldValue(
          `fuhrparkeintraege.${index}.anbauteilGesamtKosten`,
          anbauteilGesamt.toFixed(2)
        ),
        setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkGesamtKosten`,
          (fahrzeugGesamt + anbauteilGesamt).toFixed(2)
        ),
      ]);
    },
    [fahrzeugNutzungsdauerField.value, fahrzeugStundenpreisField.value, index]
  );

  return {
    updateFahrzeugStundenpreis,
    calculateFahrzeugkosten,
    calculateGesamtKosten,
    fahrzeugField,
    fahrzeugStundenpreisField,
  };
};
