import { useFormikContext } from "formik";
import { useT } from "../../../../i18n";
import { Button, Tooltip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import React from "react";
import { NeuerMaterialButtonProps } from "./MaterialButtonProps";
import { emptyMaschinenTransport } from "../../../../models/taetigkeitsberichte/models";

export default function NeuerMaschinentransportButton({
  values,
  index,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const { t } = useT("fuhrpark");
  const handleAddMaschinentransport = () => {
    const newMaschinentransport = emptyMaschinenTransport;
    const currentMaschinentransport = values.maschinentransporte || [];

    setFieldValue(`fuhrparkeintraege.${index}.maschinentransporte`, [
      ...currentMaschinentransport,
      newMaschinentransport,
    ]).then(() => {});
  };
  return (
    <Tooltip title={t("machine-transport")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaschinentransport}
      >
        <LocalShippingIcon />
      </Button>
    </Tooltip>
  );
}
