import { useT } from "../../../../i18n";
import { Button, Tooltip } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FoundationIcon from "@mui/icons-material/Foundation";
import {
  emptyFuhrparkeintragWithOutAnbauteile,
  emptyMaterialTransport,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { NeuerMaterialTtbExternButtonProps } from "./MaterialButtonProps";

export default function NeuerMaterialtransportZwischenBaustellenTtbExternButton({
  push,
}: NeuerMaterialTtbExternButtonProps) {
  const { t } = useT("fuhrpark");

  const handleAddMaterialZwischenBaustellen = async () => {
    let emptyFuhrparkeintrag = structuredClone(
      emptyFuhrparkeintragWithOutAnbauteile
    );
    emptyFuhrparkeintrag.materialtransport.push(
      emptyMaterialTransport as MaterialTransportDaten
    );

    push(emptyFuhrparkeintrag);
  };

  return (
    <Tooltip title={t("material-transport-between-sites")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialZwischenBaustellen}
      >
        <CategoryIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}
