import { Alert, Paper, Box, Pagination } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  type GridColDef,
  type GridLocaleText,
  type GridPaginationModel,
  type GridSortModel,
} from "@mui/x-data-grid";
import { deDE, enUS } from "@mui/x-data-grid/locales";
import { type DataGridPropsWithoutDefaultValue } from "@mui/x-data-grid/internals";
import { useTranslation } from "react-i18next";
import { useQuery, type QueryKey } from "react-query";
import { useState, useEffect } from "react";
import type { Identifiable } from "../../api";
import type {
  DataLoader,
  DataRequestParams,
  DataRequestState,
  Filter,
  Sort,
} from "./types";

const defaultPageSize = 25;

export type Props<TElement extends Identifiable, TFilter extends Filter> = {
  queryKey: QueryKey;
  loadData: DataLoader<TFilter, TElement>;
  columns: Array<GridColDef<TElement>>;
  request: DataRequestState<TFilter>;
  size?: "small" | "large";
  name?: string;
  setFiltered: (filtered: string) => void;
} & Pick<DataGridPropsWithoutDefaultValue<TElement>, "onRowClick">;

export default function DataTableWithFilter<
  TElement extends Identifiable,
  TFilter extends Filter
>({
  columns,
  queryKey,
  loadData,
  request: { params, sort, setPagination, setSort },
  ...props
}: Props<TElement, TFilter>) {
  const results = useSearch({ queryKey, loadData, params });
  function getPersistedPage(name: string): number {
    return parseInt(localStorage.getItem(name + "Page") ?? "0", 10);
  }

  function getPersistedLimit(name: string): number {
    return parseInt(
      localStorage.getItem(name + "Limit") ?? defaultPageSize.toString(),
      10
    );
  }
  const [currentPage, setCurrentPage] = useState<number>(
    getPersistedPage(props.name ?? "databatable")
  );
  const [currentLimit, setCurrentLimit] = useState<number>(
    getPersistedLimit(props.name ?? "databatable")
  );

  // Function to update pagination state and localStorage
  const handleSetPagination = (newPage: number, newLimit: number) => {
    setCurrentPage(newPage);
    setCurrentLimit(newLimit);
    localStorage.setItem(props.name + "Page", newPage.toString());
    localStorage.setItem(props.name + "Limit", newLimit.toString());
    setPagination(newPage, newLimit);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        display: "table",
        tableLayout: "fixed",
      }}
    >
      {results.error ? (
        <Alert severity="error">{(results.error as any).message}</Alert>
      ) : null}
      <DataGrid
        autoHeight
        columns={columns}
        rows={results.data?.items ?? []}
        rowCount={results.data?.totalElements ?? 0}
        paginationMode="server"
        filterMode={"server"}
        onFilterModelChange={(e) => {
          const serializedFilters = JSON.stringify(
            e.items.map((item) => ({
              field: item.field,
              operator: item.operator,
              value: item.value,
            }))
          );
          if (serializedFilters) {
            props.setFiltered(serializedFilters);
          }
        }}
        paginationModel={toPaginationModel(currentPage, currentLimit)}
        onPaginationModelChange={(e) => {
          if (results.isLoading) {
            handleSetPagination(currentPage, currentLimit);
          } else {
            handleSetPagination(e.page, e.pageSize);
          }
        }}
        sortingMode="server"
        sortModel={toSortModel(sort)}
        onSortModelChange={(e) => setSort(fromSortModel(e))}
        loading={results.isLoading}
        localeText={useLocaleText()}
        slots={{ toolbar: GridToolbar }}
        rowHeight={props.size?.toLowerCase() === "large" ? 55 : 38}
        sx={{ userSelect: "none" }}
        {...props}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
        }}
      >
        <Pagination
          count={Math.ceil((results.data?.totalElements ?? 0) / currentLimit)}
          page={currentPage + 1}
          onChange={(event, page) => {
            if (results.isLoading) {
              handleSetPagination(currentPage - 1, currentLimit);
            } else {
              handleSetPagination(page - 1, currentLimit);
            }
          }}
        />
      </Box>
    </Paper>
  );
}

function toSortModel(sort?: Sort): GridSortModel {
  return (sort ?? []).map(({ field, direction }) => ({
    field,
    sort: direction,
  }));
}

function fromSortModel(model: GridSortModel): Sort {
  return model.map(({ field, sort }) => ({ field, direction: sort ?? "asc" }));
}

function toPaginationModel(page?: number, limit?: number): GridPaginationModel {
  return { page: page ?? 0, pageSize: limit ?? defaultPageSize };
}

function useLocaleText(): Partial<GridLocaleText> {
  const {
    i18n: { language },
  } = useTranslation();

  switch (language) {
    case "de":
      return deDE.components?.MuiDataGrid?.defaultProps?.localeText ?? {};
    default:
      return enUS.components?.MuiDataGrid?.defaultProps?.localeText ?? {};
  }
}

function useSearch<TElement extends Identifiable, TFilter extends Filter>({
  queryKey,
  loadData,
  params,
}: {
  queryKey: QueryKey;
  loadData: DataLoader<TFilter, TElement>;
  params: DataRequestParams<TFilter>;
}) {
  return useQuery(
    Array.isArray(queryKey) ? [...queryKey, params] : [queryKey, params],
    () => loadData(params),
    { suspense: false }
  );
}
