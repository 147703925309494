import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
  MaschinentransportDaten,
  MaterialTransportDaten,
} from "../models/taetigkeitsberichte/models";
import { Fuhrparkeintrag, Taetigkeitsbericht } from "../api";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import {
  saveFuhrparkeintrag,
  saveFuhrparkeintragAnbauteil,
  saveMaschinentransporte,
  saveMaterialabtransport,
  saveMaterialanfuhr,
  saveMaterialtransportZwischenBaustellen,
  savePersonaleintrag,
  saveTaetigkeitsbericht,
} from "../models/taetigkeitsberichte/taetigkeitsBerichtMutations";
import {
  getBaustelleId,
  getMitarbeiterId,
} from "../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useCreatePersonaleintrag,
  useCreateTaetigkeitsbericht,
} from "../client/hooks";
import { enqueueSnackbar } from "notistack";

interface InitialValues {
  mitarbeiter: string;
  baustelle: string;
  datum: string;
  arbeitszeitNetto: number | null;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

export const initialTaetigkeitsberichtValues: InitialValues = {
  mitarbeiter: "",
  baustelle: "",
  datum: "",
  arbeitszeitNetto: 0,
  stundensatz: 0,
  lohnkosten: 0,
  bemerkung: "",
  fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
};

export const useSubmitTaetigkeitsbericht = (
  expandedItems: boolean[],
  expandedIndex: number | null,
  saveBetween?: boolean
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createPersonaleintrag = useCreatePersonaleintrag();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const createMaschinentransport = useCreateMaschinentransport();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  return useMutation(
    async (values: ExtendedTaetigkeitsberichtDaten) => {
      const mitarbeiterId = getMitarbeiterId(values);
      const baustelleId = getBaustelleId(values);

      const taetigkeitsbericht: Taetigkeitsbericht =
        await saveTaetigkeitsbericht(
          baustelleId,
          values,
          createTaetigkeitsbericht
        );

      await savePersonaleintrag(
        mitarbeiterId,
        baustelleId,
        taetigkeitsbericht,
        values,
        createPersonaleintrag
      );

      const fuhrparkeintragPromises: Promise<Fuhrparkeintrag>[] =
        values.fuhrparkeintraege.map((fuhrparkeintrag) =>
          saveFuhrparkeintrag(
            taetigkeitsbericht,
            fuhrparkeintrag,
            createFuhrparkeintrag
          )
        );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);
      const fuhrparkeintragIds: string[] = fuhrparkeintragResults.map(
        (result) => result.id
      );

      await Promise.all(
        processFuhrparkanbauteil(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht.id,
          createFuhrparkeintragAnbauteil
        )
      );
      await Promise.all(
        processFuhrparkMaschinentransporte(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaschinentransport
        )
      );
      await Promise.all(
        processFuhrparkMaterialanfuhren(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransport
        )
      );
      await Promise.all(
        processFuhrparkMaterialabtransporte(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransport
        )
      );
      await Promise.all(
        processFuhrparkMaterialTransportZwischenBaustellen(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransportZwischenBaustellen
        )
      );

      return taetigkeitsbericht;
    },

    {
      onSuccess: (taetigkeitsbericht: Taetigkeitsbericht) => {
        queryClient.invalidateQueries(["taetigkeitsberichte"]);
        localStorage.setItem("expandedItems", JSON.stringify(expandedItems));
        if (saveBetween) {
          navigate(
            `/taetigkeitsberichte/${
              taetigkeitsbericht.id
            }?expandedindexInherited=${expandedIndex}&addedFP=${true}`
          );
        } else {
          navigate(`/taetigkeitsberichte`);
        }
        enqueueSnackbar("Tätigkeitsbericht angelegt", {
          variant: "success",
        });
      },
    }
  );
};

//--------------------------------------------------------
//Functions to process the different parts of the fuhrpark
//--------------------------------------------------------
//TODO: Funktion von useTaetigkeitsberichtExtern können auch hier verwendet und zusammengefasst werden
const processFuhrparkanbauteil = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsberichtId: string,
  createFuhrparkeintragAnbauteil: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten) => {
        return saveFuhrparkeintragAnbauteil(
          taetigkeitsberichtId,
          fuhrparkeintragAnbauteil,
          currentFuhrparkeintragId,
          createFuhrparkeintragAnbauteil
        );
      }
    );
  });
};

const processFuhrparkMaschinentransporte = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaschinentransport: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.maschinentransporte.map(
      (maschinentransport: MaschinentransportDaten) => {
        return saveMaschinentransporte(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          maschinentransport,
          createMaschinentransport
        );
      }
    );
  });
};

const processFuhrparkMaterialanfuhren = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialanfuhr.map(
      (materialanfuhr: MaterialTransportDaten) => {
        return saveMaterialanfuhr(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialanfuhr,
          createMaterialtransport
        );
      }
    );
  });
};

const processFuhrparkMaterialabtransporte = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialabtransport.map(
      (materialabtransport: MaterialTransportDaten) => {
        return saveMaterialabtransport(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialabtransport,
          createMaterialtransport
        );
      }
    );
  });
};

const processFuhrparkMaterialTransportZwischenBaustellen = (
  values: ExtendedTaetigkeitsberichtDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransportZwischenBaustellen: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialtransport.map(
      (materialtransport: MaterialTransportDaten) => {
        return saveMaterialtransportZwischenBaustellen(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialtransport,
          createMaterialtransportZwischenBaustellen
        );
      }
    );
  });
};
