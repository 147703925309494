import { useTranslation } from "react-i18next";
import Guard from "../../components/Guard";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import Layout from "../../components/Layout";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import SubmitButton from "../../components/SubmitButton";
import * as yup from "yup";
import { apiUrl } from "../../config";
import { useGetAccessToken } from "../../client";
import { Benutzer } from "../../api";
import BRZMitarbeiterTable from "./BRZMitarbeiterTable";
import BrzExportForm from "./BRZExportForm";

interface BrzExportProps {
  start: string;
  ende: string;
  mitarbeiter: Benutzer | null;
}

export default function ErstelleBrzExport() {
  const { t } = useTranslation("taetigkeitsbericht");
  const getAccessToken = useGetAccessToken();

  let schema = yup.object().shape({
    start: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    ende: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
  });

  const onSubmit = useCallback(
    async (
      values: BrzExportProps,
      formikHelpers: FormikHelpers<BrzExportProps>
    ) => {
      const { start, ende } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      setSubmitting(true);
      const token = await getAccessToken();

      try {
        const response = await fetch(`${apiUrl}/brz-export`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ start, ende }),
        });
        if (!response.ok) {
          throw new Error("Failed to create BRZ export");
        }

        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            start.replaceAll("-", "") +
              `-` +
              ende.replaceAll("-", "") +
              `-brz-export.csv`
          );
          document.body.appendChild(link);
          link.click();
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [getAccessToken]
  );

  const todayDate = new Date();
  const initialStartDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    1,
    12
  )
    .toISOString()
    .split("T")[0];
  const initialEndDate = todayDate.toISOString().split("T")[0];

  const initialValues: BrzExportProps = {
    start: initialStartDate,
    ende: initialEndDate,
    mitarbeiter: null,
  };

  return (
    <Layout title={"BRZ Export"}>
      <Guard
        permission={"brzexport:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">BRZ Export</Typography>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              return (
                <>
                  <DialogContent>
                    <Form id="brz-export">
                      <BrzExportForm />
                    </Form>
                  </DialogContent>
                  <BRZMitarbeiterTable
                    startDatum={values.start}
                    endDatum={values.ende}
                    mitarbeiterId={values.mitarbeiter?.id ?? ""}
                  />
                  <DialogActions>
                    <SubmitButton
                      form="brz-export"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("create"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
