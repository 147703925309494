import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { ExpandContextProvider } from "../../expand";
import FuhrparkeintragFremdPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragFremdPersonalItem";
import TaetigkeitsberichtFieldsContentExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContentExtern";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import { validationSchemaTaetigkeitsberichtExtern } from "../../models/taetigkeitsberichte/taetigkeitsberichtExternValidationSchema";
import {
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtExternDaten,
} from "../../models/taetigkeitsberichte/models";
import {
  initialTaetigekitsberichtExternValues,
  useSubmitTaetigkeitsberichtExtern,
} from "../../hooks/useTaetigekeitsberichtExtern";
import TaetigkeitsberichtExternSummenzeile from "../../components/taetigkeitsberichte/TaetigkeitsberichtExternSummenzeile";
import NeuerMaterialanfuhrTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialanfuhrTtbExternButton";
import NeuerMaterialtransportZwischenBaustellenTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialtransportZwischenBaustellenTtbExternButton";
import NeuerMaterialabtransportTtbExternButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/fuhrparkeintragButtons/NeuerMaterialabtransportTtbExternButton";
import { FuhrparkProvider } from "../../contexts/FuhrparkContext";
import { useConfirmationNavDialog } from "../../hooks/useConfirmationNavDialog";
import ConfirmNavigationDialog from "../../components/ConfirmNavigationDialog";

export default function ErstelleTaetigkeitsberichtExtern() {
  const { t } = useTranslation("taetigkeitsbericht");

  const submitMutation = useSubmitTaetigkeitsberichtExtern();

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtExternDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtExternDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;

      try {
        setSubmitting(true);
        await submitMutation.mutateAsync(values);
        setStatus(undefined);
      } catch (error) {
        setStatus("Fehler beim Absenden der Fremdleistung");
      } finally {
        setSubmitting(false);
      }
    },
    [submitMutation]
  );
  const backRoute: string = "/fremdleistungen";

  const {
    showConfirmDialog,
    handleConfirmBack,
    handleCancelBack,
    handleBackClick,
  } = useConfirmationNavDialog({ backRoute });

  return (
    <Layout
      title={capitalize(t("create-activity-report-external"))}
      back={backRoute}
      onBackClick={handleBackClick}
    >
      <ConfirmNavigationDialog
        open={showConfirmDialog}
        onConfirm={handleConfirmBack}
        onCancel={handleCancelBack}
        title={t("confirm-cancel-title")}
        message={t("confirm-cancel-message")}
      />
      <Guard
        permission={"taetigkeitsbericht:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={initialTaetigekitsberichtExternValues}
            validationSchema={validationSchemaTaetigkeitsberichtExtern}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              return (
                <>
                  <Typography variant="h5">
                    {capitalize(t("new-activity-report-external"))}
                  </Typography>
                  <DialogContent>
                    <Form id="neuer-taetigkeitsbericht">
                      <TaetigkeitsberichtFieldsContentExtern isNew={true} />
                      <FieldArray name="fuhrparkeintraege">
                        {({ push, remove }) => (
                          <>
                            {values.fuhrparkeintraege.map(
                              (fuhrparkeintrag, index) => {
                                return (
                                  <FuhrparkProvider
                                    pushFP={push}
                                    removeFP={remove}
                                    taetigkeitsberichtId={null}
                                    fuhrparkeintragIndex={index}
                                    fahrzeugHerkunft={"FREMD_GERAET"}
                                    fuhrparkeintrag={fuhrparkeintrag}
                                  >
                                    <Box key={index} sx={{ marginTop: 2 }}>
                                      <Box
                                        display={"grid"}
                                        sx={{
                                          gridTemplateColumns: [
                                            "1fr",
                                            "10fr .1fr",
                                          ],
                                          gap: 2,
                                          marginTop: 2,
                                        }}
                                      >
                                        <ExpandContextProvider
                                          id={`fuhrparkeintrag-${index}`}
                                        >
                                          <FuhrparkeintragFremdPersonalItem
                                            isNew={!fuhrparkeintrag.id}
                                            // TODO: Fix type
                                            // @ts-ignore
                                            fuhrparkeintrag={fuhrparkeintrag}
                                            index={index}
                                            taetigkeitsberichtDatum={
                                              values.datum
                                            }
                                            dauerVorhanden={0}
                                            isValid={isValid}
                                            isValidating={isValidating}
                                          />
                                        </ExpandContextProvider>
                                      </Box>
                                    </Box>
                                  </FuhrparkProvider>
                                );
                              }
                            )}
                            <Box
                              display={"grid"}
                              sx={{
                                gridTemplateColumns: [
                                  "1fr",
                                  "1fr 1fr 1fr 1fr 1fr 8fr",
                                ],
                                gap: 2,
                                marginTop: 4,
                              }}
                            >
                              <NeuerMaterialanfuhrTtbExternButton push={push} />
                              <NeuerMaterialtransportZwischenBaustellenTtbExternButton
                                push={push}
                              />
                              <NeuerMaterialabtransportTtbExternButton
                                push={push}
                              />
                            </Box>
                            <TaetigkeitsberichtExternSummenzeile
                              fuhrparkeintraege={values.fuhrparkeintraege}
                            />
                          </>
                        )}
                      </FieldArray>
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <SubmitButton
                      form="neuer-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("save"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
