import { useCallback, useState } from "react";
import { useNavigate } from "react-router";

interface UseConfirmationNavDialogProps {
  backRoute: string;
}

export const useConfirmationNavDialog = ({
  backRoute,
}: UseConfirmationNavDialogProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleConfirmBack = useCallback(() => {
    setShowConfirmDialog(false);
    navigate(backRoute);
  }, [navigate, backRoute]);

  const handleCancelBack = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  return {
    showConfirmDialog,
    handleBackClick,
    handleConfirmBack,
    handleCancelBack,
  };
};
