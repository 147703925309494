import { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useGetAbladestelle } from "../client/hooks";
import { useEakSchluessel } from "./useEakschluessel";
import { useMaterialPreisUndMenge } from "./useMaterialPreisUndMenge";
import { useGesamtBerechnung } from "./useGesamtBerechnung";
import { useFahrzeugLadeMenge } from "./useFahrzeugLadeMenge";
import { Material } from "../api";

interface UseMaterialabtransportProps {
  index: number;
  materialTransportIndex: number;
  fahrzeugId: string;
  materialtransport: any;
}

export const useMaterialabtransport = ({
  index,
  materialTransportIndex,
  fahrzeugId,
  materialtransport,
}: UseMaterialabtransportProps) => {
  const { setFieldValue } = useFormikContext();
  const getAbladestelle = useGetAbladestelle();

  const [isDeponie, setIsDeponie] = useState<boolean>(
    materialtransport.fuhrTyp === "DP"
  );
  const [abladestelleId, setAbladestelleId] = useState<string | null>(
    materialtransport?.zielAbladestelle ?? null
  );
  const isKosten = materialtransport.buchungsArt === "Kosten";

  const [materialField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`
  );
  const [zielAbladestelleField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.zielAbladestelle`
  );
  const [lfsnrField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.lieferscheinNummer`
  );
  const [bsnrField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bsNr`
  );
  const [erzeugerField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.erzeuger`
  );
  const [entsorgerField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.entsorger`
  );
  const [bemerkungField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bemerkung`
  );
  const [buchungsArtField] = useField(
    `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsArt`
  );

  const { currentFahrzeugLadeMenge } = useFahrzeugLadeMenge({ fahrzeugId });

  const { eakSchluessel } = useEakSchluessel({
    materialValue: materialField.value as Material,
  });

  const { einheitField } = useMaterialPreisUndMenge({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransportId: materialtransport.id,
    type: "materialabtransport",
  });

  const { mengeField, preisField, fuhrenField } = useGesamtBerechnung({
    index,
    materialTransportIndex,
    type: "materialabtransport",
  });

  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`,
      null
    ).then(() => {});
  }, [abladestelleId, index, materialTransportIndex, setFieldValue]);

  useEffect(() => {
    if (abladestelleId && !materialtransport.id) {
      getAbladestelle(abladestelleId).then((abladestelle) => {
        if (abladestelle.isDeponie) {
          setIsDeponie(abladestelle.isDeponie);
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          ).then(() => {});
          setFieldValue(
            `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
            abladestelle.isDeponie ? "DP" : "AB"
          ).then(() => {});
        } else {
          setIsDeponie(false);
        }
      });
    } else {
      setIsDeponie(false);
    }
  }, [
    abladestelleId,
    index,
    materialTransportIndex,
    materialtransport.id,
    setFieldValue,
  ]);

  const handleDeponieChange = (checked: boolean) => {
    setIsDeponie(checked);
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.fuhrTyp`,
      checked ? "DP" : "AB"
    ).then(() => {});
    setFieldValue(
      `fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsTyp`,
      checked ? "DP" : "AB"
    ).then(() => {});
  };

  return {
    isDeponie,
    handleDeponieChange,
    abladestelleId,
    setAbladestelleId,
    isKosten,
    materialField,
    zielAbladestelleField,
    lfsnrField,
    bsnrField,
    erzeugerField,
    entsorgerField,
    bemerkungField,
    buchungsArtField,
    einheitField,
    mengeField,
    preisField,
    fuhrenField,
    currentFahrzeugLadeMenge,
    eakSchluessel,
  };
};
