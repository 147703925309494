import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

interface ConfirmNavigationDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: ReactNode;
  message?: ReactNode;
}

export default function ConfirmNavigationDialog({
  open,
  onConfirm,
  onCancel,
  title,
  message,
}: ConfirmNavigationDialogProps) {
  const { t } = useTranslation("common");

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title ?? ""}</DialogTitle>
      <DialogContent>{message ?? ""}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("abort")}</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
