import { useEffect } from "react";
import { useFormikContext, useField } from "formik";
import { useGetMaterial, useGetFahrzeug } from "../client/hooks";
import { Material } from "../api";

interface UseMaterialPreisUndMengeProps {
  index: number;
  materialTransportIndex: number;
  fahrzeugId: string;
  materialtransportId?: string;
  type: "materialanfuhr" | "materialtransport" | "materialabtransport";
}

export const useMaterialPreisUndMenge = ({
  index,
  materialTransportIndex,
  fahrzeugId,
  materialtransportId,
  type,
}: UseMaterialPreisUndMengeProps) => {
  const { setFieldValue } = useFormikContext();
  const getMaterial = useGetMaterial();
  const getfahrzeug = useGetFahrzeug();

  const [einheitField] = useField(
    `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.einheit`
  );
  const [materialField] = useField(
    `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.material`
  );

  useEffect(() => {
    if (!materialtransportId) {
      if (einheitField.value === "TO") {
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            setFieldValue(
              `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.kostenProEinheit`,
              material.kostenProTonne ?? 0
            );
          });
        }
      }
      if (einheitField.value === "CB") {
        if (fahrzeugId) {
          getfahrzeug(fahrzeugId).then((fahrzeug) => {
            if ((fahrzeug?.ladeMenge ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.menge`,
                fahrzeug.ladeMenge ?? ""
              );
            }
          });
        }
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            setFieldValue(
              `fuhrparkeintraege.${index}.${type}.${materialTransportIndex}.kostenProEinheit`,
              material.kostenProKubikmeter ?? 0
            );
          });
        }
      }
    }
  }, [einheitField.value, materialField.value]);

  return { einheitField, materialField };
};
