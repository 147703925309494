import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
  ExtendedTaetigkeitsberichtExternDaten,
  MaschinentransportDaten,
  MaterialTransportDaten,
} from "./models";
import {
  Abfallart,
  Abladestelle,
  Baustelle,
  Benutzer,
  Entsorger,
  Erzeuger,
  Fahrzeug,
  Lieferant,
  Material,
  ZugewiesenesFahrzeugAnbauteil,
} from "../../api";

export const getMitarbeiterId = (
  values: ExtendedTaetigkeitsberichtDaten
): string => {
  return typeof values.mitarbeiter === "string"
    ? values.mitarbeiter
    : (values.mitarbeiter as Benutzer).id;
};

export const getBaustelleId = (
  values:
    | ExtendedTaetigkeitsberichtDaten
    | ExtendedTaetigkeitsberichtExternDaten
): string => {
  return typeof values.baustelle === "string"
    ? values.baustelle
    : (values.baustelle as Baustelle).id;
};

export const getAnbauteilId = (
  fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten
) => {
  return typeof fuhrparkeintragAnbauteil.anbauteil === "string"
    ? fuhrparkeintragAnbauteil.anbauteil
    : (fuhrparkeintragAnbauteil.anbauteil as ZugewiesenesFahrzeugAnbauteil)
        ?.anbauteilId ?? "";
};

export const getAbholortBaustelleId = (
  maschinentransport: MaschinentransportDaten
): string => {
  return typeof maschinentransport.abholortBaustelle === "string"
    ? maschinentransport.abholortBaustelle
    : (maschinentransport.abholortBaustelle as Baustelle)?.id;
};

export const getAbstelltOrtBaustelleId = (
  maschinentransport: MaschinentransportDaten
): string => {
  return typeof maschinentransport.abstellortBaustelle === "string"
    ? maschinentransport.abstellortBaustelle
    : (maschinentransport.abstellortBaustelle as Baustelle)?.id;
};

export const getQuelleBaustelleId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.quelleAbladestelle === "string"
    ? materialtransport.quelleAbladestelle
    : (materialtransport.quelleAbladestelle as Abladestelle)?.id;
};

export const getMaterialId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.material === "string"
    ? materialtransport.material
    : (materialtransport.material as Material)?.id;
};

export const getTransportiertesFahrzeugId = (
  maschinentransport: MaschinentransportDaten
): string => {
  return typeof maschinentransport.transportiertesFahrzeug === "string"
    ? maschinentransport.transportiertesFahrzeug
    : (maschinentransport.transportiertesFahrzeug as Fahrzeug)?.id;
};

export const getZielAbladestelleId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.zielAbladestelle === "string"
    ? materialtransport.zielAbladestelle
    : (materialtransport.zielAbladestelle as Abladestelle)?.id;
};

export const getEntsorgerId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.entsorger === "string"
    ? materialtransport.entsorger
    : (materialtransport.entsorger as Entsorger)?.id;
};

export const getErzeugerId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.erzeuger === "string"
    ? materialtransport.erzeuger
    : (materialtransport.erzeuger as Erzeuger)?.id;
};

export const getZielBaustelleId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.zielBaustelle === "string"
    ? materialtransport.zielBaustelle
    : (materialtransport.zielBaustelle as Baustelle)?.id;
};

export const getAbfallartId = (
  materialtransport: MaterialTransportDaten
): string => {
  return typeof materialtransport.abfallart === "string"
    ? materialtransport.abfallart
    : (materialtransport.abfallart as Abfallart)?.id;
};

export const getFahrzeugId = (
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten
) => {
  return typeof fuhrparkeintrag.fahrzeug === "string"
    ? fuhrparkeintrag.fahrzeug
    : fuhrparkeintrag.fahrzeug?.id;
};

export const getLieferantId = (
  values: ExtendedTaetigkeitsberichtExternDaten
): string => {
  return typeof values.lieferant === "string"
    ? values.lieferant
    : (values.lieferant as Lieferant)?.id;
};

export function timestampToDatetimeLocal(timestamp: any) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
