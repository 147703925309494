import { useCallback } from "react";
import { useGetApi } from "../../client";
import { capitalize } from "@mui/material";
import { useNavigate } from "react-router";

export function useBRZExport(
  startDatum: string,
  endDatum: string,
  mitarbeiterId: string
) {
  const getApi = useGetApi();
  const navigate = useNavigate();

  enum WochenTage {
    "sonntag" = 0,
    "montag" = 1,
    "dienstag" = 2,
    "mittwoch" = 3,
    "donnerstag" = 4,
    "freitag" = 5,
    "samstag" = 6,
  }

  enum BRZExportArt {
    "abwesenheiten" = "abwesenheiten",
    "anwesenheiten" = "anwesenheiten",
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return (
      `${String(date.getDate()).padStart(2, "0")}` +
      `.${String(date.getMonth() + 1).padStart(2, "0")}` +
      `.${date.getFullYear()}`
    );
  };

  const formatDateRange = (dateRange: string) => {
    const dates: string[] = dateRange.split(" - ");

    if (dates.length === 1) {
      return formatDate(dates[0]);
    } else {
      return `${formatDate(dates[0])} - ${formatDate(dates[1])}`;
    }
  };

  const getWeekday = (dateString: string) => {
    return capitalize(WochenTage[new Date(dateString).getDay()]);
  };

  const formatWeekdayRange = (dateRange: string) => {
    const dates: string[] = dateRange.split(" - ");

    if (dates.length === 1) {
      return getWeekday(dates[0]);
    } else {
      return `${getWeekday(dates[0])} - ${getWeekday(dates[1])}`;
    }
  };

  const mitarbeiterBuchungen = useCallback(
    async () =>
      (await getApi()).brzexport.bekommeAlleMitarbeiterAnUndAbwesenheiten({
        mitarbeiterId: mitarbeiterId,
        startDatum: startDatum,
        endeDatum: endDatum,
      }),
    [getApi, startDatum, endDatum, mitarbeiterId]
  );

  function redirectToBRZPage(id: string, art: string, isNewPage: boolean) {
    let zielRoute;
    switch (art) {
      case BRZExportArt.abwesenheiten:
        zielRoute = "abwesenheit";
        break;
      case BRZExportArt.anwesenheiten:
        zielRoute = "anwesenheiten";
        break;
      default:
        zielRoute = "anwesenheiten";
        break;
    }
    if (isNewPage) {
      window.open(`/${zielRoute}/${id}`, "_blank");
    } else {
      navigate(`/${zielRoute}/${id}`);
    }
  }

  return {
    mitarbeiterBuchungen,
    formatDateRange,
    formatWeekdayRange,
    redirectToBRZPage,
  };
}
