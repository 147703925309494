import {
  Box,
  Button,
  capitalize,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { FastField, FieldArray, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../i18n";
import React, { useEffect, useState } from "react";
import { HerkunftTyp } from "../../../api";
import AddIcon from "@mui/icons-material/Add";
import FuhrparkeintragAnbauteileFieldsContent from "./fuhrparkeintraganbauteile/FuhrparkeintragAnbauteileFieldsContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FahrzeugSelect from "../../fuhrpark/fahrzeug/FahrzeugSelect";
import {
  emptyFuhrparkeintragAnbauteil,
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
} from "../../../models/taetigkeitsberichte/models";
import NeuerMaterialanfuhrButton from "./fuhrparkeintragButtons/NeuerMaterialanfuhrButton";
import NeuerMaterialtransportZwischenBaustellenButton from "./fuhrparkeintragButtons/NeuerMaterialtransportZwischenBaustellenButton";
import NeuerMaterialabtransportButton from "./fuhrparkeintragButtons/NeuerMaterialabtransportButton";
import NeuerMaschinentransportButton from "./fuhrparkeintragButtons/NeuerMaschinentransportButton";
import { getFahrzeugId } from "../../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { MaterialanfuhrArray } from "./transportArrays/MaterialanfuhrArray";
import { MaterialtransportArray } from "./transportArrays/MaterialtransportArray";
import { MaterialabtransportArray } from "./transportArrays/MaterialabtransportArray";
import { MaschinentransportArray } from "./transportArrays/MaschinentransportArray";
import { useFuhrparkeintrag } from "../../../hooks/useFuhrparkeintrag";

export default function FuhrparkeintragFieldsContent({
  isNew,
  index,
  fahrzeugHerkunft,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
  isFremdleistung,
  fuhrparkeintrag,
}: {
  isNew: boolean;
  index: number;
  fahrzeugHerkunft: HerkunftTyp;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
  isFremdleistung: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
}) {
  const { t } = useT("taetigkeitsbericht");

  const { setFieldValue } = useFormikContext();
  const tabIndex = isFremdleistung ? 1 : 0;

  const [anchorElFahrzeuganlegenInfo, setAnchorElFahrzeuganlegenInfo] =
    useState<HTMLElement | null>(null);
  const handlePopoverOpenFahrzeuganlegenInfo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElFahrzeuganlegenInfo(event.currentTarget);
  };

  const handlePopoverCloseFahrzeuganlegenInfo = () => {
    setAnchorElFahrzeuganlegenInfo(null);
  };

  const openFahrzeuganlegenInfo = Boolean(anchorElFahrzeuganlegenInfo);

  const {
    ueberstiegeneNutzungsdauer,
    calculateFahrzeugkosten,
    calculateGesamtKosten,
    checkUeberstiegeneNutzungsdauer,
    deleteAllAnbauteile,
    updateFahrzeugStundenpreis,
    fahrzeugNutzungsdauerField,
    fahrzeugStundenpreisField,
  } = useFuhrparkeintrag({
    index,
    isNew,
    taetigkeitsberichtDatum,
    dauerVorhanden,
  });

  useEffect(() => {
    calculateFahrzeugkosten().then(() => {});
    calculateGesamtKosten(fuhrparkeintrag).then(() => {});
    checkUeberstiegeneNutzungsdauer(fuhrparkeintrag);
  }, [
    fahrzeugNutzungsdauerField.value,
    fahrzeugStundenpreisField.value,
    fuhrparkeintrag.fuhrparkeintragAnbauteile,
  ]);

  useEffect(() => {
    deleteAllAnbauteile().then(() => {});
  }, [deleteAllAnbauteile]);

  const [fahrzeugHasChanged, setFahrzeugHasChanged] = useState(null);

  useEffect(() => {
    const deleteAllAnbauteile = async () => {
      if (fuhrparkeintrag?.fuhrparkeintragAnbauteile?.length) {
        let changedFuhrparkAnbauteile =
          fuhrparkeintrag.fuhrparkeintragAnbauteile.map((anbauteil) => {
            return {
              ...anbauteil,
              anbauteil: "",
              anbauteilKosten: 0,
              anbauteilStundenPreis: 0,
            };
          });

        await setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile`,
          changedFuhrparkAnbauteile
        );
      }
    };
    if (fahrzeugHasChanged) {
      deleteAllAnbauteile().then(() => {});
    }
  }, [fahrzeugHasChanged]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 5.5fr .5fr 1fr 2fr 2fr 2fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-duration"))}
          name={`fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`}
          type={"number"}
          inputProps={{
            tabIndex: tabIndex,
            min: 0,
            step: 0.25,
            style: { textAlign: "right" },
          }}
          required
        />
        <FastField
          component={FahrzeugSelect}
          name={`fuhrparkeintraege.${index}.fahrzeug`}
          label={capitalize(t("carpool-entry-vehicle"))}
          setFahrzeugHasChanged={setFahrzeugHasChanged}
          onParentChange={(value: any) => {
            if (value) {
              updateFahrzeugStundenpreis(value.id);
            }
          }}
          required
          fahrzeugHerkunft={fahrzeugHerkunft}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.open("/neues-fahrzeug")}
          aria-owns={openFahrzeuganlegenInfo ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenFahrzeuganlegenInfo}
          onMouseLeave={handlePopoverCloseFahrzeuganlegenInfo}
          tabIndex={tabIndex}
        >
          <AddIcon />
        </Button>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={openFahrzeuganlegenInfo}
          anchorEl={anchorElFahrzeuganlegenInfo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseFahrzeuganlegenInfo}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {capitalize(t("create-vehicle-info"))}
          </Typography>
        </Popover>
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-price"))}
          name={`fuhrparkeintraege.${index}.fahrzeugStundenPreis`}
          type={"number"}
          inputProps={{
            tabIndex: tabIndex,
            min: 0,
            step: 0.01,
            style: { textAlign: "right" },
          }}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-costs"))}
          name={`fuhrparkeintraege.${index}.fahrzeugKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-total-costs"))}
          name={`fuhrparkeintraege.${index}.anbauteilGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-vehicle-total-costs"))}
          name={`fuhrparkeintraege.${index}.fuhrparkGesamtKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
      </Box>

      {ueberstiegeneNutzungsdauer ? (
        <Box>
          <Typography color={"#cc6600"} sx={{ fontSize: "10pt" }}>
            {capitalize(t("warning-attachment-usage-over-carpool"))}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <FieldArray name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile`}>
        {({ push, remove }) => (
          <>
            {fuhrparkeintrag.fuhrparkeintragAnbauteile.length === 0 ? (
              <Box
                display={"grid"}
                sx={{
                  gridTemplateColumns: ["1fr", "1fr"],
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    push(emptyFuhrparkeintragAnbauteil);
                  }}
                  disabled={!fuhrparkeintrag.fahrzeug}
                >
                  <Typography sx={{ p: 0.5 }}>
                    {capitalize(t("create-attachment-part-info"))}
                  </Typography>
                  <AddCircleIcon />
                </Button>
              </Box>
            ) : (
              <>
                {fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (
                    fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten,
                    indexAnbauteil
                  ) => (
                    <Box key={indexAnbauteil} sx={{ marginTop: 2 }}>
                      <Box
                        display={"grid"}
                        sx={{
                          gridTemplateColumns: ["1fr", "10fr .1fr"],
                          gap: 2,
                        }}
                      >
                        <Box
                          id={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${indexAnbauteil}`}
                        >
                          <FuhrparkeintragAnbauteileFieldsContent
                            dauerVorhanden={dauerVorhanden}
                            fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                            index={index}
                            anbauteilIndex={indexAnbauteil}
                            isNew={!fuhrparkeintragAnbauteil.id}
                            taetigkeitsberichtDatum={taetigkeitsberichtDatum}
                            push={push}
                            remove={remove}
                            newestIndex={
                              fuhrparkeintrag.fuhrparkeintragAnbauteile.length -
                              1
                            }
                            fuhrparkeintragAnbauteil={fuhrparkeintragAnbauteil}
                            isFremdleistung={isFremdleistung}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )
                )}
              </>
            )}
          </>
        )}
      </FieldArray>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "2fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-remark"))}
          name={`fuhrparkeintraege.${index}.bemerkung`}
          type={"box"}
          inputProps={{ tabIndex: tabIndex }}
        />
        {isFremdleistung ? (
          <FastField
            component={TextField}
            label={capitalize(t("activity-report-date"))}
            name={`fuhrparkeintraege.${index}.datum`}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          <></>
        )}
      </Box>

      <MaterialanfuhrArray index={index} fuhrparkeintrag={fuhrparkeintrag} />
      <MaterialtransportArray index={index} fuhrparkeintrag={fuhrparkeintrag} />
      <MaterialabtransportArray
        index={index}
        fuhrparkeintrag={fuhrparkeintrag}
      />
      <MaschinentransportArray
        index={index}
        fuhrparkeintrag={fuhrparkeintrag}
        isFremdleistung={isFremdleistung}
      />

      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr 1fr 8fr"],
          gap: 2,
          marginTop: 4,
        }}
      >
        <NeuerMaterialanfuhrButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
        />
        <NeuerMaterialtransportZwischenBaustellenButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
        />
        <NeuerMaterialabtransportButton
          values={fuhrparkeintrag}
          index={index}
          fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
        />
        {!isFremdleistung ? (
          <NeuerMaschinentransportButton
            values={fuhrparkeintrag}
            index={index}
            fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
          />
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
}
