//TODO: muss entfernt werden, da generierter Typ benutzt werden soll, dafür vorher abgleichen,
// wo es gerade verwendet wird

export enum Buchungstyp {
  AR = "AR",
  ER = "ER",
  PS = "PS",
  FP = "FP",
  MA = "MA",
  AB = "AB",
  DP = "DP",
  TR = "TR",
}
