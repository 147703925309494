import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiError, Benutzer, BenutzerListe } from "../../api";
import { useGetBenutzer, useSucheBenutzer } from "../../client/hooks";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useGetApi } from "../../client";

interface BRZBenutzerSelectProps extends FieldProps {
  label: string;
  required?: boolean;
  fullWidth?: boolean;
}

function BRZBenutzerSelect({
  label,
  required,
  fullWidth,
  field,
  form,
  meta,
}: BRZBenutzerSelectProps) {
  const getApi = useGetApi();
  const searchBenutzer = useSucheBenutzer();
  const ladeBenutzer = useGetBenutzer();
  const [optionen, setOptionen] = useState<ReadonlyArray<Benutzer>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");
  const [geladeneBenutzer, setGeladeneBenutzer] = useState<Benutzer[]>([]);

  const ladeInitialeNutzer = useCallback(async () => {
    const api = await getApi();
    return api.benutzer.getAlleMitarbeiter().then(async (results) => {
      setGeladeneBenutzer(results);
      return results;
    });
  }, [getApi]);

  useEffect(() => {
    ladeInitialeNutzer();
  }, [ladeInitialeNutzer]);

  useEffect(() => {
    if (geladeneBenutzer.length > 0 && !field.value) {
      const ersterBenutzer = geladeneBenutzer[0];
      form.setFieldValue(field.name, ersterBenutzer);
      setInputValue(ersterBenutzer);
    }
  }, [geladeneBenutzer]);

  const navigateMitarbeiter = (direction: "prev" | "next") => {
    if (inputValue) {
      const currentUserIndex: number = geladeneBenutzer.findIndex(
        (benutzer: Benutzer) => {
          return inputValue.id === benutzer.id;
        }
      );
      if (direction === "prev") {
        changeToPrevUser(currentUserIndex);
      } else {
        changeToNextUser(currentUserIndex);
      }
    }
  };

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeBenutzer(field.value)
        .then((benutzer) => {
          form.setFieldValue(field.name, benutzer);
          setInputValue(benutzer);
        })
        .catch((error) => {
          console.error("Error loading user:", error);
        });
    }
  }, [field.value]);

  async function changeToPrevUser(currentUserIndex: number) {
    let prevUser = geladeneBenutzer[currentUserIndex - 1];

    form.setFieldValue(field.name, prevUser);
    setInputValue(prevUser);
    setSearchValue(prevUser.id);
  }

  async function changeToNextUser(currentUserIndex: number) {
    let nextUser: Benutzer = geladeneBenutzer[currentUserIndex + 1];

    form.setFieldValue(field.name, nextUser);
    setInputValue(nextUser);
    setSearchValue(nextUser.id);
  }

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: BenutzerListe) => void
        ) =>
          searchBenutzer({
            search: request.input,
            page: 0,
            limit: 10,
            sort: "name",
          })
            .then(async (results) => {
              callback(undefined, { ...results, items: results.items });
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBenutzer]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <>
      <IconButton
        onClick={() => navigateMitarbeiter("prev")}
        sx={{ color: "primary.main" }}
        disabled={inputValue && inputValue.id === geladeneBenutzer[0].id}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Autocomplete
        field={field}
        form={form}
        meta={meta}
        filterSelectedOptions={true}
        filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
        options={optionen}
        fullWidth={fullWidth}
        getOptionLabel={(option: string | Benutzer) => {
          if (typeof option === "string") {
            return "";
          } else {
            return [option.name, option.vorname].filter(Boolean).join(", ");
          }
        }}
        onChange={(event, value) => {
          setInputValue(value);
        }}
        onInputChange={(event, value) => {
          setSearchValue(value);
        }}
        renderOption={(props, option: Benutzer) => {
          return (
            <ListItem {...props} key={option.id}>
              <ListItemText
                primary={[option.name, option.vorname]
                  .filter(Boolean)
                  .join(", ")}
                secondary={[
                  option.personalNummer,
                  option.email ?? option.geburtsDatum ?? null,
                ]
                  .filter(Boolean)
                  .join(", ")}
              />
            </ListItem>
          );
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} label={label + (required ? " *" : "")} />
        )}
      />

      <IconButton
        onClick={() => navigateMitarbeiter("next")}
        sx={{ color: "primary.main" }}
        disabled={
          inputValue &&
          inputValue.id === geladeneBenutzer[geladeneBenutzer.length - 1].id
        }
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </>
  );
}

export default BRZBenutzerSelect;
