import { useFormikContext } from "formik";
import { useGetFahrzeug } from "../../../../client/hooks";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import FoundationIcon from "@mui/icons-material/Foundation";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { NeuerMaterialButtonProps } from "./MaterialButtonProps";
import { emptyMaterialTransport } from "../../../../models/taetigkeitsberichte/models";
import { useFahrzeugLadeMenge } from "../../../../hooks/useFahrzeugLadeMenge";

export default function NeuerMaterialtransportZwischenBaustellenButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const { t } = useT("fuhrpark");
  const { currentFahrzeugLadeMenge } = useFahrzeugLadeMenge({ fahrzeugId });

  const handleAddMatrialtransportZwischenBaustellen = async () => {
    const newMaterialtransport = emptyMaterialTransport;
    newMaterialtransport.meLadeVolumenFahrzeug = await currentFahrzeugLadeMenge;

    const currentMaterialtransport = values.materialtransport || [];
    await setFieldValue(`fuhrparkeintraege.${index}.materialtransport`, [
      ...currentMaterialtransport,
      newMaterialtransport,
    ]);
  };
  return (
    <Tooltip title={t("material-transport-between-sites")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMatrialtransportZwischenBaustellen}
      >
        <FoundationIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}
