import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FieldArray } from "formik";
import { useBRZExport } from "./useBRZExport";
import { useEffect, useState } from "react";

interface BRZMitarbeiterTableProps {
  startDatum: string;
  endDatum: string;
  mitarbeiterId: string;
}

export default function BRZMitarbeiterTable({
  startDatum,
  endDatum,
  mitarbeiterId,
}: BRZMitarbeiterTableProps) {
  const {
    mitarbeiterBuchungen,
    formatDateRange,
    formatWeekdayRange,
    redirectToBRZPage,
  } = useBRZExport(startDatum, endDatum, mitarbeiterId);
  const [buchungen, setBuchungen] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await mitarbeiterBuchungen(); // API-Funktion aufrufen
        setBuchungen(data || []); // Daten im State speichern
      } catch (error) {
        console.error("Fehler beim Laden der Buchungen", error);
        setBuchungen([]);
      }
    }

    fetchData();
  }, [startDatum, endDatum, mitarbeiterId]);

  return (
    <TableContainer component={Paper}>
      <Table size={"small"} sx={{ marginTop: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Wochentag</TableCell>
            <TableCell>BRZ Übertrag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name="mitarbeiterBuchungen"
            render={({ push, remove, form }) => (
              <>
                {buchungen.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      "Keine Daten gefunden"
                    </TableCell>
                  </TableRow>
                ) : (
                  buchungen.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        onClick={(event) => {
                          if (event.ctrlKey || event.metaKey) {
                            redirectToBRZPage(row.id, row.art, true);
                          } else {
                            redirectToBRZPage(row.id, row.art, false);
                          }
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        <TableCell>{formatDateRange(row.datum)}</TableCell>
                        <TableCell>{formatWeekdayRange(row.datum)}</TableCell>
                        <TableCell>{row.uebertrag}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </>
            )}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
