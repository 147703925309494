import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchField from "../SearchField";
import { isValidElement, useCallback, useEffect, useState } from "react";
import {
  AbfallartDaten,
  DeponieDaten,
  DeponieInfo,
  DeponieListe,
} from "../../api";
import {
  useCreateAbfallart,
  useSucheMaterialtransporteDeponie,
  useUpdateMaterialtransportDeponie,
} from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { Field, Form, Formik, FormikHelpers, useField } from "formik";
import SubmitButton from "../SubmitButton";
import * as yup from "yup";
import { TextField } from "formik-mui";

export default function BaustellenEintragDeponien({
  baustelleId,
  rechnungId,
  index,
}: {
  baustelleId: string | undefined;
  rechnungId: string | undefined;
  index: number;
}) {
  const [deponien, setDeponien] = useState<DeponieListe>();
  const [search, setSearch] = useState<string>("");
  const searchDeponien = useSucheMaterialtransporteDeponie();
  const { mutate } = useSaveDeponie();
  const [startDateField] = useField(`startDate.${index}`);
  const [endDateField] = useField(`endDate.${index}`);
  const fetchDeponien = async () => {
    if (baustelleId) {
      const deponien = await searchDeponien({
        search: search,
        baustelleId: baustelleId,
        rechnungId: rechnungId,
        startDate: startDateField.value,
        endDate: endDateField.value,
      });
      deponien.items.forEach((deponie: DeponieInfo) => {
        if (deponie.rechnungId !== undefined) {
          setCheckedItems((prev) => ({
            ...prev,
            [deponie.id]: true,
          }));
        }
      });
      setDeponien(deponien);
    }
  };
  useEffect(() => {
    fetchDeponien();
  }, [baustelleId, search, startDateField.value, endDateField.value]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  const handleToggle = (id: any) => {
    const currentChecked = checkedItems[id] || false;
    const newRechnungId = currentChecked ? "" : rechnungId ?? "";
    const checked = mutate({
      materialtransportId: id,
      rechnungId: newRechnungId ?? "",
    });
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !currentChecked,
    }));
  };
  let schema = yup.object().shape({
    startDate: yup.string(),
    endDate: yup.string(),
  });
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        Tätigkeitsberichte
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 1 }}>
            <SearchField
              value={search}
              onChange={(value) => {
                setSearch(value);
              }}
            ></SearchField>
            <Field
              sx={{ ml: 2 }}
              component={TextField}
              name={`startDate.${index}`}
              label={"von"}
              type="date"
              size={"small"}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: endDateField.value,
              }}
            />
            <Field
              sx={{ ml: 2 }}
              component={TextField}
              name={`endDate.${index}`}
              label={"bis"}
              type="date"
              size={"small"}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: startDateField.value,
              }}
            />
          </Stack>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Zuordnen</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Abhol- / Abladestelle</TableCell>
                <TableCell>LieferscheinNr</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Preis Ges.</TableCell>
                <TableCell>Menge Ges.</TableCell>
                <TableCell>Einheiten</TableCell>
                <TableCell>Fahrten</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deponien ? (
                deponien.items.map((deponie) => (
                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        checked={checkedItems[deponie.id]}
                        onChange={() => handleToggle(deponie.id)}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      {new Date(deponie.datum ?? "").toLocaleDateString(
                        undefined,
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.kurzname}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px" }}>
                      {deponie.lieferscheinNummer}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px", textAlign: "right" }}>
                      {deponie.preisGesamt
                        ? deponie.preisGesamt.toFixed(2).replace(".", ",") +
                          " €"
                        : ""}
                    </TableCell>
                    <TableCell sx={{ paddingLeft: "15px", textAlign: "right" }}>
                      {deponie.mengeGesamt}
                    </TableCell>
                    <TableCell>{deponie.einheit}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {deponie.anzahlFuhren}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
function useSaveDeponie() {
  const updateDeponie = useUpdateMaterialtransportDeponie();
  const queryClient = useQueryClient();

  return useMutation(
    (input: DeponieDaten) => {
      return updateDeponie(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}
