import { useState } from "react";
import { Button, capitalize, Popover, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useT } from "../../../i18n";

interface FahrzeugAnlegenButtonProps {
  tabIndex?: number;
}

const CreateVehicleButton = ({ tabIndex = 1 }: FahrzeugAnlegenButtonProps) => {
  const { t } = useT("taetigkeitsbericht");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => window.open("/neues-fahrzeug")}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        tabIndex={tabIndex}
        sx={{ height: "100%" }}
      >
        <AddIcon />
      </Button>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          {capitalize(t("create-vehicle-info"))}
        </Typography>
      </Popover>
    </>
  );
};

export default CreateVehicleButton;
