import { Box } from "@mui/material";
import React from "react";
import { ExtendedFuhrparkeintragDaten } from "../../../models/taetigkeitsberichte/models";
import TaetigkeitsberichtExternFuhren from "../TaetigkeitsberichtExternFuhren";

export default function FuhrparkeintragFremdPersonalItem({
  isNew,
  fuhrparkeintrag,
  index,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
}: {
  isNew: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  index: number;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
}) {
  return (
    <Box key={fuhrparkeintrag.id} sx={{ width: "100%", padding: 2 }}>
      <TaetigkeitsberichtExternFuhren
        index={index}
        isFremdleistung={true}
        fuhrparkeintrag={fuhrparkeintrag}
      />
    </Box>
  );
}
