import { Box, Button, capitalize, Stack } from "@mui/material";
import { FastField, Field } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React from "react";
import {
  useDeleteFuhrparkeintrag,
  useDeleteMaterialtransportZwischen,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Abfallart, Baustelle } from "../../../../api";
import Buchungsart from "../../../Buchungsart";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import AbfallartenSelect from "../../../abfallarten/AbfallartenSelect";
import BemerkungNachEakUndBaustelleSelect from "./BemerkungNachEakUndBaustelleSelect";
import Grid from "@mui/material/Grid2";
import EinheitSelect from "../../../einheiten/EinheitSelect";
import {
  emptyFuhrparkeintragWithOutAnbauteile,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { useMaterialtransport } from "../../../../hooks/useMaterialtransport";
import FahrzeugSelect from "../../../fuhrpark/fahrzeug/FahrzeugSelect";
import { useRemoveFP } from "../../../../contexts/FuhrparkContext";
import FahrzeugAnlegenButton from "../FahrzeugAnlegenButton";

export default function MaterialtransportTaetigkeitsberichtExtern({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const { t: tTaetigkeitsbericht } = useT("taetigkeitsbericht");

  const deleteMaterialtransportZwischen = useDeleteMaterialtransportZwischen();
  const deleteFuhrparkeintrag = useDeleteFuhrparkeintrag();
  const {
    removeFP,
    taetigkeitsberichtId,
    fuhrparkeintragIndex,
    pushFP,
    fuhrparkeintrag,
  } = useRemoveFP();

  const {
    isKosten,
    baustelleField,
    abfallartField,
    buchungsArtField,
    einheitField,
    preisField,
    fuhrenField,
    currentFahrzeugLadeMenge,
  } = useMaterialtransport({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransport,
  });

  function handleAddCloneOfMaterialtransport() {
    const emptyFuhrparkeintrag = structuredClone(
      emptyFuhrparkeintragWithOutAnbauteile
    );

    emptyFuhrparkeintrag.datum = fuhrparkeintrag.datum || "";

    emptyFuhrparkeintrag.materialtransport.push({
      zielBaustelle:
        typeof baustelleField.value === "string"
          ? baustelleField.value
          : (baustelleField.value as Baustelle)?.id ?? "",
      abfallart:
        typeof abfallartField.value === "string"
          ? abfallartField.value
          : (abfallartField.value as Abfallart)?.id ?? "",
      einheit: einheitField.value ?? "CB",
      menge: 0,
      kostenProEinheit: preisField.value ?? 0,
      anzahlFuhren: fuhrenField.value ?? "",
      meLadeVolumenFahrzeug: currentFahrzeugLadeMenge ?? 0,
      lieferscheinNummer: "",
      bemerkung: "",
      buchungsArt: buchungsArtField.value ?? "Kosten",
    } as MaterialTransportDaten);

    pushFP(emptyFuhrparkeintrag);
  }

  function handleMaterialTransportRemove() {
    const materialtransportId = materialtransport.id;
    if (materialtransportId && taetigkeitsberichtId) {
      deleteMaterialtransportZwischen(
        materialtransport.taetigkeitsberichtId,
        materialtransport.fuhrparkeintragId,
        materialtransportId
      ).then(() => {
        deleteFuhrparkeintrag(
          taetigkeitsberichtId,
          materialtransport.fuhrparkeintragId
        ).then(() => {
          remove(materialTransportIndex);
          removeFP(fuhrparkeintragIndex);
        });
      });
    } else {
      remove(materialTransportIndex);
      removeFP(fuhrparkeintragIndex);
    }
  }

  return (
    <Stack direction="column" sx={{ marginTop: -4 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-transport-between-sites")}
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={FahrzeugSelect}
              name={`fuhrparkeintraege.${index}.fahrzeug`}
              label={capitalize(tTaetigkeitsbericht("carpool-entry-vehicle"))}
              required
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 1, md: 0.7 }}>
            <FahrzeugAnlegenButton />
          </Grid>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={TextField}
              label={capitalize(tTaetigkeitsbericht("activity-report-date"))}
              name={`fuhrparkeintraege.${index}.datum`}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={BaustellenSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.zielBaustelle`}
              label={t("vehicle-unloading-point")}
              required
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={AbfallartenSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.abfallart`}
              label={t("eak-key")}
              showKeyHeader={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Box></Box>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }} container>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleAddCloneOfMaterialtransport();
              }}
            >
              <AddCircleIcon />
              {t("vehicle-transport-ab-ma")}
            </Button>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => handleMaterialTransportRemove()}
              aria-label="delete"
            >
              <DeleteIcon />
              {t("vehicle-transport-ab-ma")}
            </Button>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Field
              component={BemerkungNachEakUndBaustelleSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.bemerkung`}
              label={t("vehicle-remark")}
              baustelleId={
                typeof baustelleField.value === "string"
                  ? baustelleField.value
                  : (baustelleField.value as Baustelle)?.id ?? ""
              }
              abfallartId={
                typeof abfallartField.value === "string"
                  ? abfallartField.value
                  : (abfallartField.value as Abfallart)?.id ?? ""
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
