/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrzExportFuerMitarbeiter } from '../models/BrzExportFuerMitarbeiter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrzexportService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Anwesenheiten und Abwesenheiten eines bestimmten Mitarbeiters.
   * @returns BrzExportFuerMitarbeiter Die Seite der Anwesenheiten und Abwesenheiten eines bestimmten Mitarbeiters.
   * @throws ApiError
   */
  public bekommeAlleMitarbeiterAnUndAbwesenheiten({
    mitarbeiterId,
    startDatum,
    endeDatum,
  }: {
    mitarbeiterId: string,
    startDatum: string,
    endeDatum: string,
  }): CancelablePromise<Array<BrzExportFuerMitarbeiter>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/brz-mitarbeiter-anwesenheiten-abwesenheiten',
      query: {
        'mitarbeiterId': mitarbeiterId,
        'startDatum': startDatum,
        'endeDatum': endeDatum,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
