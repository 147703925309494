import {
  Box,
  capitalize,
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FastField,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from "formik";
import { useT } from "../../i18n";
import { TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { useSucheUmsatzByBaustelleImZeitraum } from "../../client/hooks";
import { Umsatz } from "../../api";
import BaustellenSelect from "../baustellen/BaustellenSelect";

export default function EntsorgungsdokumentationenFieldsContent({
  handleChangeBaustelle,
  handleChange,
  handleChangePreisAuswahl,
  handleChangeBemerkungAuswahl,
  handleChangeGewaehlteAbladestellenMaterialien,
}: {
  handleChangeBaustelle: (baustellenId: string) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleChangePreisAuswahl: (isPreiseAusgewaehlt: boolean) => void;
  handleChangeBemerkungAuswahl: (isBemerkungAusgewaehlt: boolean) => void;
  handleChangeGewaehlteAbladestellenMaterialien: (auswahlItems: []) => void;
}) {
  const { t } = useT("entsorgungsdokumentation");

  const { values } = useFormikContext<any>();

  const sucheUmsatzByBaustelleImZeitraum =
    useSucheUmsatzByBaustelleImZeitraum();

  const [baustelleField] = useField("baustelle");
  const [preiseAuswahlField] = useField("preiseAuswahl");
  const [bemerkungenAuswahlField] = useField("bemerkungenAuswahl");

  const [startDatum, setStartDatum] = useState("");
  const [endDatum, setEndDatum] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [abladestellenMaterialien, setAbladestellenMaterialien] = useState<
    Array<Umsatz>
  >([]);

  const [selectAll, setSelectAll] = useState(false);

  const handleStartDatumChange = (e: React.ChangeEvent<any>) => {
    setStartDatum(e.target.value);
    handleChange(e);
  };

  const handleEndDatumChange = (e: React.ChangeEvent<any>) => {
    setEndDatum(e.target.value);
    handleChange(e);
  };

  useEffect(() => {
    handleChangeBaustelle(baustelleField?.value?.id);
  }, [baustelleField?.value?.id]);

  useEffect(() => {
    handleChangePreisAuswahl(preiseAuswahlField.value);
  }, [preiseAuswahlField.value]);

  useEffect(() => {
    handleChangeBemerkungAuswahl(bemerkungenAuswahlField.value);
  }, [bemerkungenAuswahlField.value]);

  useEffect(() => {
    handleChangeGewaehlteAbladestellenMaterialien(
      values.gewaehlteAbladestellenMaterialien
    );
  }, [values.gewaehlteAbladestellenMaterialien]);

  useEffect(() => {
    if (baustelleField?.value?.id && startDatum && endDatum) {
      sucheUmsatzByBaustelleImZeitraum(baustelleField?.value?.id, {
        startDate: startDatum,
        endDate: endDatum,
      }).then((data) => {
        setAbladestellenMaterialien(data.items);
      });
    }
  }, [startDatum, endDatum, baustelleField?.value?.id]);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      values.gewaehlteAbladestellenMaterialien = abladestellenMaterialien;
    } else {
      values.gewaehlteAbladestellenMaterialien = [];
    }
  };

  return (
    <Box
      display={"grid"}
      sx={{
        gridTemplateColumns: ["1fr", "2fr 5fr"],
        gap: 2,
        marginTop: 2,
      }}
    >
      <Stack direction="column">
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: ["1fr", "1fr"],
            gap: 2,
          }}
        >
          <FastField
            component={BaustellenSelect}
            name="baustelle"
            label={capitalize(t("construction-site"))}
            required
          />
        </Box>
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: ["1fr", "1fr 1fr"],
            gap: 2,
            marginTop: 2,
          }}
        >
          <Field
            component={TextField}
            name="startDatum"
            label={capitalize(t("start-date"))}
            type={"date"}
            InputLabelProps={{ shrink: true }}
            required
            onChange={handleStartDatumChange}
            inputProps={{ max: endDatum }}
          />
          <Field
            component={TextField}
            name="endDatum"
            label={capitalize(t("end-date"))}
            type={"date"}
            InputLabelProps={{ shrink: true }}
            required
            onChange={handleEndDatumChange}
            inputProps={{ min: startDatum }}
          />
        </Box>
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: ["1fr", "1fr"],
            gap: 2,
            marginTop: 2,
          }}
        >
          <Paper>
            <Typography
              variant={"h6"}
              sx={{
                margin: 2,
              }}
            >
              {capitalize(t("additional-columns"))}
            </Typography>
            <Box
              display={"grid"}
              sx={{
                gridTemplateRows: ["1fr", "1fr"],
                gap: 2,
                margin: 2,
              }}
            >
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Field
                    name={"preiseAuswahl"}
                    as={Switch}
                    color="primary"
                    type="checkbox"
                  />
                }
                label={capitalize(t("prices"))}
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <FastField
                    name={"bemerkungenAuswahl"}
                    as={Switch}
                    color="primary"
                    type="checkbox"
                  />
                }
                label={capitalize(t("remark"))}
              />
            </Box>
          </Paper>
        </Box>
      </Stack>
      <TableContainer component={Paper}>
        <Table size={"small"} sx={{ marginTop: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Bezeichnung</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>EAK Nummer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name="gewaehlteAbladestellenMaterialien"
              render={({ push, remove, form }) => (
                <>
                  {abladestellenMaterialien.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        {capitalize(t("no-data-found"))}
                      </TableCell>
                    </TableRow>
                  ) : (
                    abladestellenMaterialien.map((row, index) => {
                      const isChecked =
                        form.values.gewaehlteAbladestellenMaterialien.some(
                          (item: any) => item.id === row.id
                        );
                      return (
                        <TableRow key={row.id}>
                          <TableCell padding="none">
                            <Checkbox
                              checked={isChecked}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  push(row);
                                } else {
                                  const idx =
                                    form.values.gewaehlteAbladestellenMaterialien.findIndex(
                                      (item: any) => item.id === row.id
                                    );
                                  remove(idx);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ paddingLeft: "15px" }}>
                            {row.buchungsTyp}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: "15px" }}>
                            {row.abladestelleBezeichnung ??
                              row.abstellortBaustelleBezeichnung}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: "15px" }}>
                            {row.materialName}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: "15px" }}>
                            {row.eakSchluessel}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </>
              )}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
