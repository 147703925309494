import * as Yup from "yup";
import * as yup from "yup";
//TODO: kann zusammengefasst werden mit taeigkeitsberichtExternValidationSchema
export const validationSchemaTaetigkeitsbericht = Yup.object().shape({
  mitarbeiter: yup.object().required(),
  baustelle: yup.object().required(),
  datum: yup
    .date()
    .required("Datum ist erforderlich!")
    .max(
      new Date("9999-12-31"),
      "Datum darf nicht über dem 31.12.9999 liegen!"
    ),
  arbeitszeitNetto: yup.number().nullable().min(0),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeug: yup.object().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        datum: yup
          .date()
          .nullable()
          .max(
            new Date("9999-12-31"),
            "Datum darf nicht über dem 31.12.9999 liegen!"
          ),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteil: yup.object().required(),
              anbauteilNutzungsdauer: yup
                .number()
                .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                .required("Nutzungsdauer ist erforderlich!"),
              anbauteilStundenPreis: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anbauteilAnzahl: yup
                .number()
                .min(1, "Stückzahl muss mindestens 0 sein!")
                .required("Stückzahl ist erforderlich!"),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        maschinentransporte: Yup.array()
          .of(
            Yup.object().shape({
              transportiertesFahrzeug: yup.object().required(),
              abholortBaustelle: yup.object().required(),
              abstellortBaustelle: yup.object().required(),
              lieferscheinNummer: yup.string(),
              bemerkung: yup.string().nullable(),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            quelleAbladestelle: yup.object().required(),
            material: yup.object().required(),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            zielAbladestelle: yup.object().required(),
            // TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            material: yup.object().required(),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            bsNr: yup.string().nullable(),
            erzeuger: yup.object().nullable(),
            entsorger: yup.object().nullable(),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialtransport: Yup.array().of(
          Yup.object().shape({
            zielBaustelle: yup.object().required(),
            // TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
            abfallart: yup.object().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});

export const validationSchemaTaetigkeitsberichtDetails = Yup.object().shape({
  mitarbeiter: Yup.lazy((value) =>
    typeof value === "string"
      ? Yup.string().required("Mitarbeiter ist erforderlich!")
      : Yup.object().required("Mitarbeiter ist erforderlich!")
  ),
  baustelle: Yup.lazy((value) =>
    typeof value === "string"
      ? Yup.string().required("Baustelle ist erforderlich!")
      : Yup.object().required("Baustelle ist erforderlich!")
  ),
  datum: yup
    .date()
    .required("Datum ist erforderlich!")
    .max(
      new Date("9999-12-31"),
      "Datum darf nicht über dem 31.12.9999 liegen!"
    ),
  arbeitszeitNetto: yup.number().nullable().min(0),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeug: Yup.lazy((value) =>
          typeof value === "string"
            ? Yup.string().required("Fahrzeug ist erforderlich!")
            : Yup.object().required("Fahrzeug ist erforderlich!")
        ),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        datum: yup
          .date()
          .nullable()
          .max(
            new Date("9999-12-31"),
            "Datum darf nicht über dem 31.12.9999 liegen!"
          ),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteil: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("anbauteil is required")
                  : Yup.object().required("anbauteil is required")
              ),
              anbauteilNutzungsdauer: yup
                .number()
                .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                .required("Nutzungsdauer ist erforderlich!"),
              anbauteilStundenPreis: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anbauteilAnzahl: yup
                .number()
                .min(1, "Stückzahl muss mindestens 0 sein!")
                .required("Stückzahl ist erforderlich!"),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        maschinentransporte: Yup.array()
          .of(
            Yup.object().shape({
              transportiertesFahrzeug: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required(
                      "transportiertesFahrzeug ist erforderlich!"
                    )
                  : Yup.object().required(
                      "transportiertesFahrzeug ist erforderlich!"
                    )
              ),
              abholortBaustelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("abholortBaustelle ist erforderlich!")
                  : Yup.object().required("abholortBaustelle ist erforderlich!")
              ),
              abstellortBaustelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required(
                      "abstellortBaustelle ist erforderlich!"
                    )
                  : Yup.object().required(
                      "abstellortBaustelle ist erforderlich!"
                    )
              ),
              lieferscheinNummer: yup.string(),
              bemerkung: yup.string().nullable(),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            quelleAbladestelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("quelleAbladestelle ist erforderlich!")
                : Yup.object().required("quelleAbladestelle ist erforderlich!")
            ),
            material: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("material ist erforderlich!")
                : Yup.object().required("material ist erforderlich!")
            ),
            // TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            zielAbladestelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("zielAbladestelle ist erforderlich!")
                : Yup.object().required("zielAbladestelle ist erforderlich!")
            ),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            material: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("material ist erforderlich!")
                : Yup.object().required("material ist erforderlich!")
            ),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            bsNr: yup.string().nullable(),
            erzeuger: yup.object().nullable(),
            entsorger: yup.object().nullable(),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialtransport: Yup.array().of(
          Yup.object().shape({
            zielBaustelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("zielAbladestelle is required")
                : Yup.object().required("zielAbladestelle is required")
            ),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup.number(),
            // .min(0, "Ladevolumen muss mindestens 0 sein!")
            // .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
            abfallart: yup.object().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});
