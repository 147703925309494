import * as Yup from "yup";
import * as yup from "yup";
//TODO: kann zusammengefasst werden mit Details und normalen taetigkeitsbericht validation schema
export const validationSchemaTaetigkeitsberichtExtern = Yup.object().shape({
  baustelle: yup.object().required(),
  lieferant: yup.object().required(),
  datum: yup
    .date()
    .required("Datum ist erforderlich!")
    .max(
      new Date("9999-12-31"),
      "Datum darf nicht über dem 31.12.9999 liegen!"
    ),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeug: yup.object().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        datum: yup
          .date()
          .required()
          .max(
            new Date("9999-12-31"),
            "Datum darf nicht über dem 31.12.9999 liegen!"
          ),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteil: yup.object().required(),
              anbauteilNutzungsdauer: yup
                .number()
                .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                .required("Nutzungsdauer ist erforderlich!"),
              anbauteilStundenPreis: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anbauteilAnzahl: yup
                .number()
                .min(1, "Stückzahl muss mindestens 0 sein!")
                .required("Stückzahl ist erforderlich!"),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            quelleAbladestelle: yup.object().required(),
            material: yup.object().required(),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            zielAbladestelle: yup.object().required(),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            material: yup.object().required(),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            bsNr: yup.string().nullable(),
            erzeuger: yup.object().nullable(),
            entsorger: yup.object().nullable(),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialtransport: Yup.array().of(
          Yup.object().shape({
            zielBaustelle: yup.object().required(),
            //TODO: check if necessary
            // meLadeVolumenFahrzeug: yup
            //   .number()
            //   .min(0, "Ladevolumen muss mindestens 0 sein!")
            //   .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
            abfallart: yup.object().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});

export const validationSchemaTaetigkeitsberichtExternDetails =
  Yup.object().shape({
    baustelle: Yup.lazy((value) =>
      typeof value === "string"
        ? Yup.string().required("baustelle ist erforderlich!")
        : Yup.object().required("baustelle ist erforderlich!")
    ),
    lieferant: Yup.lazy((value) =>
      typeof value === "string"
        ? Yup.string().required("lieferant ist erforderlich!")
        : Yup.object().required("lieferant ist erforderlich!")
    ),
    datum: yup
      .date()
      .required("Datum ist erforderlich!")
      .max(
        new Date("9999-12-31"),
        "Datum darf nicht über dem 31.12.9999 liegen!"
      ),
    fuhrparkeintraege: Yup.array()
      .of(
        Yup.object().shape({
          fahrzeug: Yup.lazy((value) =>
            typeof value === "string"
              ? Yup.string().required("fahrzeug ist erforderlich!")
              : Yup.object().required("fahrzeug ist erforderlich!")
          ),
          fahrzeugNutzungsdauer: yup.number().min(0).required(),
          fahrzeugStundenPreis: yup.number().min(0).required(),
          fahrzeugKosten: yup.number().min(0),
          fuhrparkGesamtKosten: yup.number().min(0),
          bemerkung: yup.string().nullable(),
          datum: yup
            .date()
            .nullable()
            .max(
              new Date("9999-12-31"),
              "Datum darf nicht über dem 31.12.9999 liegen!"
            ),
          fuhrparkeintragAnbauteile: Yup.array()
            .of(
              Yup.object().shape({
                anbauteil: Yup.lazy((value) =>
                  typeof value === "string"
                    ? Yup.string().required("anbauteil ist erforderlich!")
                    : Yup.object().required("anbauteil ist erforderlich!")
                ),
                anbauteilNutzungsdauer: yup
                  .number()
                  .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                  .required("Nutzungsdauer ist erforderlich!"),
                anbauteilStundenPreis: yup
                  .number()
                  .min(0, "Preis muss mindestens 0 sein!")
                  .required("Preis ist erforderlich!"),
                anbauteilAnzahl: yup
                  .number()
                  .min(1, "Stückzahl muss mindestens 0 sein!")
                  .required("Stückzahl ist erforderlich!"),
                anbauteilKosten: yup.number().min(0),
              })
            )
            .min(0, ""),
          materialanfuhr: Yup.array().of(
            Yup.object().shape({
              quelleAbladestelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required(
                      "quelleAbladestelle ist erforderlich!"
                    )
                  : Yup.object().required(
                      "quelleAbladestelle ist erforderlich!"
                    )
              ),
              material: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("material ist erforderlich!")
                  : Yup.object().required("material ist erforderlich!")
              ),
              //TODO: check if necessary
              // meLadeVolumenFahrzeug: yup
              //   .number()
              //   .min(0, "Ladevolumen muss mindestens 0 sein!")
              //   .required("Ladevolumen ist erforderlich!"),
              kostenProEinheit: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anzahlFuhren: yup
                .number()
                .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
                .required("Anzahl von Fuhren ist erforderlich!"),
              einheit: yup.string().required("Einheit ist erforderlich!"),
              menge: yup
                .number()
                .min(0, "Menge muss mindestens 0 sein!")
                .required("Menge ist erforderlich!"),
              lieferscheinNummer: yup.string().nullable(),
              bemerkung: yup.string().nullable(),
            })
          ),
          materialabtransport: Yup.array().of(
            Yup.object().shape({
              zielAbladestelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("zielAbladestelle ist erforderlich!")
                  : Yup.object().required("zielAbladestelle ist erforderlich!")
              ),
              //TODO: check if necessary
              // meLadeVolumenFahrzeug: yup
              //   .number()
              //   .min(0, "Ladevolumen muss mindestens 0 sein!")
              //   .required("Ladevolumen ist erforderlich!"),
              material: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("material ist erforderlich!")
                  : Yup.object().required("material ist erforderlich!")
              ),
              kostenProEinheit: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              bsNr: yup.string().nullable(),
              erzeuger: yup.object().nullable(),
              entsorger: yup.object().nullable(),
              anzahlFuhren: yup
                .number()
                .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
                .required("Anzahl von Fuhren ist erforderlich!"),
              einheit: yup.string().required("Einheit ist erforderlich!"),
              menge: yup
                .number()
                .min(0, "Menge muss mindestens 0 sein!")
                .required("Menge ist erforderlich!"),
              lieferscheinNummer: yup.string().nullable(),
              bemerkung: yup.string().nullable(),
            })
          ),
          materialtransport: Yup.array().of(
            Yup.object().shape({
              zielBaustelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("zielBaustelle ist erforderlich!")
                  : Yup.object().required("zielBaustelle ist erforderlich!")
              ),
              //TODO: check if necessary
              // meLadeVolumenFahrzeug: yup
              //   .number()
              //   .min(0, "Ladevolumen muss mindestens 0 sein!")
              //   .required("Ladevolumen ist erforderlich!"),
              kostenProEinheit: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anzahlFuhren: yup
                .number()
                .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
                .required("Anzahl von Fuhren ist erforderlich!"),
              einheit: yup.string().required("Einheit ist erforderlich!"),
              menge: yup
                .number()
                .min(0, "Menge muss mindestens 0 sein!")
                .required("Menge ist erforderlich!"),
              lieferscheinNummer: yup.string().nullable(),
              bemerkung: yup.string().nullable(),
              abfallart: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().nullable()
                  : Yup.object().nullable()
              ),
            })
          ),
        })
      )
      .min(0, ""),
  });
