import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import { ExpandContextProvider } from "../../expand";
import FuhrparkeintragEigenPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import {
  emptyFuhrparkeintrag,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
} from "../../models/taetigkeitsberichte/models";
import {
  initialTaetigkeitsberichtValues,
  useSubmitTaetigkeitsbericht,
} from "../../hooks/useTaetigkeitsbericht";
import { validationSchemaTaetigkeitsbericht } from "../../models/taetigkeitsberichte/taetigkeitsberichtValidationSchema";
import ConfirmNavigationDialog from "../../components/ConfirmNavigationDialog";
import { useConfirmationNavDialog } from "../../hooks/useConfirmationNavDialog";

export default function ErstelleTaetigkeitsbericht() {
  const { t } = useTranslation("taetigkeitsbericht");

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [expandedItems, setExpandedItems] = useState<boolean[]>([]);
  let anzahlFuhrparkeintraege: number = 0;

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    setExpandedItems((prevState) => {
      const newExpandedItems = [...prevState];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const calculateDauerVorhanden = (values: ExtendedTaetigkeitsberichtDaten) => {
    let dauerVorhanden: number = values.arbeitszeitNetto ?? 0;

    values.fuhrparkeintraege.forEach(
      (fuhrparkeintrag: ExtendedFuhrparkeintragDaten) => {
        if (fuhrparkeintrag.fahrzeug) {
          dauerVorhanden -= fuhrparkeintrag.fahrzeugNutzungsdauer;
        }
      }
    );

    return dauerVorhanden;
  };

  const handleAddFuhrparkeintrag = (
    push: FieldArrayRenderProps["push"],
    values: ExtendedTaetigkeitsberichtDaten
  ): void => {
    push(emptyFuhrparkeintrag);

    anzahlFuhrparkeintraege = values.fuhrparkeintraege.length;

    if (anzahlFuhrparkeintraege > 0) {
      saveFuhrparkeintraege(values).then(() => {});
    }
  };

  const submitMutation = useSubmitTaetigkeitsbericht(
    expandedItems,
    expandedIndex
  );

  const saveBetweenMutation = useSubmitTaetigkeitsbericht(
    expandedItems,
    expandedIndex,
    true
  );

  const saveFuhrparkeintraege = async (
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
    }
  ): Promise<void> => {
    await saveBetweenMutation.mutateAsync(values);
  };

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;

      try {
        setSubmitting(true);
        await submitMutation.mutateAsync(values);
        setStatus(undefined);
      } catch (error) {
        setStatus("Fehler beim Absenden des Berichts");
      } finally {
        setSubmitting(false);
      }
    },
    [submitMutation]
  );

  const backRoute: string = "/taetigkeitsberichte";

  const {
    showConfirmDialog,
    handleConfirmBack,
    handleCancelBack,
    handleBackClick,
  } = useConfirmationNavDialog({ backRoute });

  return (
    <Layout
      title={capitalize(t("activity-report-creation-form"))}
      back={backRoute}
      onBackClick={handleBackClick}
    >
      <ConfirmNavigationDialog
        open={showConfirmDialog}
        onConfirm={handleConfirmBack}
        onCancel={handleCancelBack}
        title={t("confirm-cancel-title")}
        message={t("confirm-cancel-message")}
      />
      <Guard
        permission={"taetigkeitsbericht:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={initialTaetigkeitsberichtValues}
            validationSchema={validationSchemaTaetigkeitsbericht}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              const dauerVorhanden = calculateDauerVorhanden(values);

              return (
                <>
                  <DialogContent>
                    <Typography>
                      {capitalize(t("activity-report-staff"))}
                    </Typography>
                    <Form id="neuer-taetigkeitsbericht">
                      <TaetigkeitsberichtFieldsContent isNew={true} />
                      <FieldArray name="fuhrparkeintraege">
                        {({ push, remove }) => (
                          <>
                            {values.fuhrparkeintraege.map(
                              (
                                fuhrparkeintrag: ExtendedFuhrparkeintragDaten,
                                index
                              ) => (
                                <Box key={index} sx={{ marginTop: 2 }}>
                                  <Box
                                    display={"grid"}
                                    sx={{
                                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                                      gap: 2,
                                      marginTop: 2,
                                    }}
                                  >
                                    <ExpandContextProvider
                                      id={`fuhrparkeintrag-${index}`}
                                    >
                                      <FuhrparkeintragEigenPersonalItem
                                        isNew={!fuhrparkeintrag.id}
                                        fuhrparkeintrag={fuhrparkeintrag}
                                        index={index}
                                        expanded={expandedItems[index] ?? true}
                                        handleChange={handleAccordionChange}
                                        taetigkeitsberichtDatum={values.datum}
                                        dauerVorhanden={
                                          dauerVorhanden > 0
                                            ? dauerVorhanden
                                            : 0
                                        }
                                        isValid={isValid}
                                        isValidating={isValidating}
                                        remove={() => remove(index)}
                                        taetigkeitsberichtId={""}
                                      />
                                    </ExpandContextProvider>
                                  </Box>
                                </Box>
                              )
                            )}
                            <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  handleAddFuhrparkeintrag(push, values);
                                }}
                                disabled={isValidating || !isValid}
                              >
                                {capitalize(t("add-carpool"))}
                              </Button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <SubmitButton
                      form="neuer-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("save"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
