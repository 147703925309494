import { FieldArray } from "formik";
import { TransportFieldArrayWrapper } from "../TransportFieldArrayWrapper";
import MaterialtransportFieldsContent from "../materialtransport/MaterialtransportFieldsContent";
import {
  ExtendedFuhrparkeintragDaten,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { getFahrzeugId } from "../../../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { Box } from "@mui/material";
import MaterialtransportTaetigkeitsberichtExtern from "../materialtransport/MaterialtransportTaetigkeitsberichtExtern";

interface MaterialtransportArrayProps {
  index: number;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  isFremdleistung?: boolean;
}

export const MaterialtransportArray = ({
  index,
  fuhrparkeintrag,
  isFremdleistung,
}: MaterialtransportArrayProps) => (
  <FieldArray name={`fuhrparkeintraege.${index}.materialtransport`}>
    {({ push, remove }) => (
      <>
        {fuhrparkeintrag.materialtransport?.map(
          (
            materialtransport: MaterialTransportDaten,
            indexMaterialtransport: number
          ) => (
            <TransportFieldArrayWrapper key={indexMaterialtransport}>
              <Box
                id={`fuhrparkeintraege.${index}.materialtransport.${indexMaterialtransport}`}
              >
                {isFremdleistung ? (
                  <MaterialtransportTaetigkeitsberichtExtern
                    index={index}
                    materialTransportIndex={indexMaterialtransport}
                    push={push}
                    remove={remove}
                    newestIndex={fuhrparkeintrag.materialabtransport.length - 1}
                    materialtransport={materialtransport}
                    fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                  />
                ) : (
                  <MaterialtransportFieldsContent
                    index={index}
                    materialTransportIndex={indexMaterialtransport}
                    push={push}
                    remove={remove}
                    newestIndex={fuhrparkeintrag.materialabtransport.length - 1}
                    materialtransport={materialtransport}
                    fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                  />
                )}
              </Box>
            </TransportFieldArrayWrapper>
          )
        )}
      </>
    )}
  </FieldArray>
);
