import { FieldArray } from "formik";
import { TransportFieldArrayWrapper } from "../TransportFieldArrayWrapper";
import {
  ExtendedFuhrparkeintragDaten,
  MaschinentransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { Box } from "@mui/material";
import MaschinentransportFieldsContent from "../maschinentransporte/MaschinentransporteFieldsContent";

interface MaschinentransportArrayProps {
  index: number;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  isFremdleistung: boolean;
}

export const MaschinentransportArray = ({
  index,
  fuhrparkeintrag,
  isFremdleistung,
}: MaschinentransportArrayProps) => {
  if (isFremdleistung || !fuhrparkeintrag.maschinentransporte) {
    return null;
  }

  return (
    <FieldArray name={`fuhrparkeintraege.${index}.maschinentransporte`}>
      {({ push, remove }) => (
        <>
          {fuhrparkeintrag.maschinentransporte.map(
            (
              maschinentransport: MaschinentransportDaten,
              indexMaschinentransport: number
            ) => (
              <TransportFieldArrayWrapper key={indexMaschinentransport}>
                <Box
                  id={`fuhrparkeintraege.${index}.maschinentransporte.${indexMaschinentransport}`}
                >
                  <MaschinentransportFieldsContent
                    index={index}
                    maschinenTransportIndex={indexMaschinentransport}
                    push={push}
                    remove={remove}
                    newestIndex={fuhrparkeintrag.maschinentransporte.length - 1}
                    maschinentransport={maschinentransport}
                  />
                </Box>
              </TransportFieldArrayWrapper>
            )
          )}
        </>
      )}
    </FieldArray>
  );
};
