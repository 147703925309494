import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlLabel,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-mui";
import Select from "@mui/material/Select";
import { useT } from "../../../../i18n";
import React from "react";
import {
  useDeleteFuhrparkeintrag,
  useDeleteMaterialtransport,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Abladestelle, Entsorger, Erzeuger, Material } from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import Buchungsart from "../../../Buchungsart";
import EntsorgerSelect from "../../../entsorger/EntsorgerSelect";
import ErzeugerSelect from "../../../erzeuger/ErzeugerSelect";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import MaterialEakSelect from "../../../material/MaterialEakSelect";
import Grid from "@mui/material/Grid2";
import EinheitSelect from "../../../einheiten/EinheitSelect";
import { useMaterialabtransport } from "../../../../hooks/useMaterialabtransport";
import {
  emptyFuhrparkeintragWithOutAnbauteile,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import FahrzeugSelect from "../../../fuhrpark/fahrzeug/FahrzeugSelect";
import { useRemoveFP } from "../../../../contexts/FuhrparkContext";
import FahrzeugAnlegenButton from "../FahrzeugAnlegenButton";

export default function MaterialabtransportTaetigkeitsberichtExtern({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const { t: tTaetigkeitsbericht } = useT("taetigkeitsbericht");

  const deleteMaterialtransport = useDeleteMaterialtransport();
  const deleteFuhrparkeintrag = useDeleteFuhrparkeintrag();

  const {
    removeFP,
    taetigkeitsberichtId,
    fuhrparkeintragIndex,
    pushFP,
    fuhrparkeintrag,
  } = useRemoveFP();

  const {
    isDeponie,
    handleDeponieChange,
    abladestelleId,
    setAbladestelleId,
    isKosten,
    materialField,
    zielAbladestelleField,
    erzeugerField,
    entsorgerField,
    buchungsArtField,
    einheitField,
    preisField,
    fuhrenField,
    currentFahrzeugLadeMenge,
    eakSchluessel,
  } = useMaterialabtransport({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransport,
  });

  function handleAddCloneOfMaterialabtransport() {
    const emptyFuhrparkeintrag = structuredClone(
      emptyFuhrparkeintragWithOutAnbauteile
    );

    emptyFuhrparkeintrag.datum = fuhrparkeintrag.datum || "";

    emptyFuhrparkeintrag.materialabtransport.push({
      zielAbladestelle:
        typeof zielAbladestelleField.value === "string"
          ? zielAbladestelleField.value
          : (zielAbladestelleField.value as Abladestelle)?.id ?? "",
      material:
        typeof materialField.value === "string"
          ? materialField.value
          : (materialField.value as Material)?.id ?? "",
      anzahlFuhren: fuhrenField.value ?? 1,
      einheit: einheitField.value ?? "CB",
      menge: 0,
      kostenProEinheit: preisField.value ?? 0,
      lieferscheinNummer: "",
      bsNr: "",
      erzeuger:
        typeof erzeugerField.value === "string"
          ? erzeugerField.value
          : (erzeugerField.value as Erzeuger)?.id ?? "",
      entsorger:
        typeof entsorgerField.value === "string"
          ? entsorgerField.value
          : (entsorgerField.value as Entsorger)?.id ?? "",
      fuhrTyp: "AB",
      buchungsTyp: "AB",
      meLadeVolumenFahrzeug: currentFahrzeugLadeMenge ?? 0,
      bemerkung: "",
      buchungsArt: buchungsArtField.value ?? "Kosten",
    } as MaterialTransportDaten);

    pushFP(emptyFuhrparkeintrag);
  }

  function handleMaterialabtransportRemove() {
    const materialransportId = materialtransport.id;
    if (materialransportId && taetigkeitsberichtId) {
      deleteMaterialtransport(
        materialtransport.taetigkeitsberichtId,
        materialtransport.fuhrparkeintragId,
        materialransportId
      ).then(() => {
        deleteFuhrparkeintrag(
          taetigkeitsberichtId,
          materialtransport.fuhrparkeintragId
        ).then(() => {
          remove(materialTransportIndex);
          removeFP(fuhrparkeintragIndex);
        });
      });
    } else {
      remove(materialTransportIndex);
      removeFP(fuhrparkeintragIndex);
    }
  }

  return (
    <Stack direction="column" sx={{ marginTop: -4 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-removal")}
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={FahrzeugSelect}
              name={`fuhrparkeintraege.${index}.fahrzeug`}
              label={capitalize(tTaetigkeitsbericht("carpool-entry-vehicle"))}
              required
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 1, md: 0.7 }}>
            <FahrzeugAnlegenButton />
          </Grid>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={TextField}
              label={capitalize(tTaetigkeitsbericht("activity-report-date"))}
              name={`fuhrparkeintraege.${index}.datum`}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid size={{ xs: 3, md: 3 }}>
            {materialtransport.quelleBaustelle ? (
              <FastField
                component={BaustellenSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.quelleBaustelle`}
                label={t("vehicle-unloading-point")}
                required
              />
            ) : (
              <FastField
                component={AbladestellenSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.zielAbladestelle`}
                label={t("vehicle-unloading-point")}
                required
                setAbladestelleId={setAbladestelleId}
              />
            )}
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            {abladestelleId ? (
              <FastField
                component={MaterialEakSelect}
                name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.material`}
                label={t("material")}
                abladestelleId={abladestelleId}
                setAbfallartKey={() => {}}
                required
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Material *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Material"
                  disabled={true}
                ></Select>
              </FormControl>
            )}
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Stack>
              <Typography>EAK-Schlüssel</Typography>
              <Box>{eakSchluessel}</Box>
            </Stack>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch
                  checked={isDeponie}
                  onChange={(e) => handleDeponieChange(e.target.checked)}
                />
              }
              label={"Ist Deponie"}
            />
          </Grid>
          <Grid container size={{ xs: 3, md: 3 }} alignItems="left">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAddCloneOfMaterialabtransport()}
            >
              <AddCircleIcon />
              {t("vehicle-transport-ab-dp")}
            </Button>
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
                defaultValue: 0.0,
              }}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={3}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => handleMaterialabtransportRemove()}
              aria-label="delete"
            >
              <DeleteIcon />
              {t("vehicle-transport-ab-dp")}
            </Button>
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={TextField}
              label={t("bs-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bsNr`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={ErzeugerSelect}
              label={t("erz-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.erzeuger`}
            />
          </Grid>
          <Grid size={1.5}>
            <FastField
              component={EntsorgerSelect}
              label={t("en-nr")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.entsorger`}
            />
          </Grid>
          <Grid size={3}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-remark")}
              name={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.bemerkung`}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialabtransport.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
