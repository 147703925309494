import { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useGetFahrzeugAnbauteilKostenFuerBestimmtesDatum } from "../client/hooks";
import { ExtendedFuhrparkeintragAnbauteilDaten } from "../models/taetigkeitsberichte/models";

interface UseFuhrparkAnbauteilFieldContentProps {
  index: number;
  anbauteilIndex: number;
  fahrzeugId: string;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isNew: boolean;
  fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten;
}

export const useFuhrparkAnbauteilFieldContent = ({
  index,
  anbauteilIndex,
  fahrzeugId,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isNew,
  fuhrparkeintragAnbauteil,
}: UseFuhrparkAnbauteilFieldContentProps) => {
  const { setFieldValue } = useFormikContext();
  const bekommeAnbauteilKosten =
    useGetFahrzeugAnbauteilKostenFuerBestimmtesDatum();

  const [anbauteilField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteil`
  );
  const [anbauteilNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`
  );
  const [anbauteilStundenpreisField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`
  );
  const [anbauteilAnzahlField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilAnzahl`
  );

  // Wird ausgeführt, sobald sich ein Feld ändert, was für die Berechnung benötigt wird
  useEffect(() => {
    calculateAnbauteilkosten().then(() => {});
  }, [
    anbauteilAnzahlField.value,
    anbauteilNutzungsdauerField.value,
    anbauteilStundenpreisField.value,
  ]);

  // Wird ausgeführt, sobald ich das im Select das Anbauteil ändert
  useEffect(() => {
    updateAnbauteilPreis().then(() => {});
  }, [anbauteilField.value]);

  //Wird ausgeführt, wenn sich die Dauer vom Anbauteil oder Fuhrpark ändert
  useEffect(() => {
    if (!anbauteilField.value && isNew && dauerVorhanden) {
      setFieldValue(
        `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`,
        dauerVorhanden
      ).then(() => {});
    }
  }, [anbauteilField.value, isNew, dauerVorhanden]);

  const updateAnbauteilPreis = async () => {
    if (anbauteilField.value && isNew) {
      try {
        const datum: string = taetigkeitsberichtDatum
          ? new Date(taetigkeitsberichtDatum).toISOString()
          : new Date().toISOString();

        const anbauteilId: string = anbauteilField.value.anbauteilId;
        const response = await bekommeAnbauteilKosten(fahrzeugId, anbauteilId, {
          date: datum,
        });

        await setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
          response.kostenStunde.toFixed(2)
        );
      } catch (error) {
        await setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
          0
        );
      }
    } else {
      if (isNew) {
        await setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
          0
        );
      } else {
        await setFieldValue(
          `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
          fuhrparkeintragAnbauteil.anbauteilStundenPreis
        );
      }
    }
  };

  const calculateAnbauteilkosten = async () => {
    const kosten = (
      anbauteilAnzahlField.value *
      anbauteilNutzungsdauerField.value *
      anbauteilStundenpreisField.value
    ).toFixed(2);

    await setFieldValue(
      `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilKosten`,
      kosten
    );
  };

  return {
    anbauteilField,
    anbauteilNutzungsdauerField,
    anbauteilAnzahlField,
    anbauteilStundenpreisField,
  };
};
