import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { AnwesenheitInfo } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import Grid from "@mui/material/Grid2";
import { useGetBenutzer } from "../../client/hooks";
import { useMe } from "../../client/queries";

export type AnwesenheitenFilter = {
  search?: string;
  filtered?: string;
};

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function MyAnwesenheitenDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <MyAnwesenheitenResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<AnwesenheitenFilter>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const ladeBenutzer = useGetBenutzer();
  const ladeMe = useMe();
  const [az_saldo, setAzSaldo] = useState("");

  async function getAzSaldo() {
    await ladeBenutzer(ladeMe.benutzerId!!).then((benutzer) => {
      setAzSaldo(benutzer.azSaldo?.toString() ?? "0");
    });
  }

  getAzSaldo();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-meine-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Stack sx={{ ml: 2, textAlign: "center" }}>
              <Typography>Mein AZ-Saldo</Typography>
              <Box>{az_saldo}</Box>
            </Stack>
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-meine-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function MyAnwesenheitenResults({
  ...request
}: DataRequestState<AnwesenheitenFilter>) {
  return <Results request={request} />;
}

function Results({
  request,
}: {
  request: DataRequestState<AnwesenheitenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAnwesenheiten: DataLoader<AnwesenheitenFilter, AnwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).anwesenheiten.sucheMeineAnwesenheiten({ ...params }),
      [getApi]
    );
  const [{ search, filtered }, setField] = useDebouncedFilter(request);
  const [filteredState, setFilteredState] = useState("");
  useEffect(() => {
    if (filteredState) {
      setField("filtered", filteredState);
    }
  }, [filteredState]);
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["anwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAnwesenheiten}
      onRowClick={(row, event) => {
        if (event.ctrlKey || event.metaKey) {
          window.open("/meine-anwesenheiten/" + row.id, "_blank");
        } else {
          navigate("/meine-anwesenheiten/" + row.id);
        }
      }}
      name={"meine-anwesenheiten"}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<AnwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "datum",
        headerName: t("date"),
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "start",
        headerName: "Start",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "arbeitszeitNetto",
        headerName: "Netto Arbeitszeit",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "fahrzeiten",
        headerName: "Fahrzeiten",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "bezahltePause",
        headerName: "bezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "unbezahltePause",
        headerName: "Unbezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "arbeitszeitBrutto",
        headerName: "Brutto Arbeitszeit",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
    ],
    [t]
  );
}
