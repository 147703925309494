import { ActionTimestamp, EinheitenListe } from "../../api";
import { Buchungstyp } from "./buchungstyp";

export interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

export interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
  abfallart?: any;
}

export interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

export interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiter: any;
  baustelle: any;
  datum: string;
  arbeitszeitNetto: number | null;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

export interface ExtendedTaetigkeitsberichtExternDaten {
  lieferant: any;
  baustelle: any;
  datum: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

export interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeug: any;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  datum?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: ExtendedFuhrparkeintragAnbauteilDaten[];
  maschinentransporte: MaschinentransportDaten[];
  materialanfuhr: MaterialTransportDaten[];
  materialabtransport: MaterialTransportDaten[];
  materialtransport: MaterialTransportDaten[];
}

export const emptyFuhrparkeintrag = {
  fahrzeug: "",
  fahrzeugHerkunft: "",
  fahrzeugStundenPreis: 0,
  fahrzeugNutzungsdauer: 0,
  bemerkung: "",
  datum: "",
  fuhrparkeintragAnbauteile: [
    {
      anbauteil: "",
      anbauteilNutzungsdauer: 0,
      anbauteilAnzahl: 1,
      anbauteilStundenPreis: 0,
      anbauteilKosten: 0,
    },
  ],
  maschinentransporte: [],
  materialanfuhr: [],
  materialabtransport: [],
  materialtransport: [],
};

export var emptyFuhrparkeintragWithOutAnbauteile = {
  fahrzeug: "",
  fahrzeugHerkunft: "",
  fahrzeugStundenPreis: 0,
  fahrzeugNutzungsdauer: 0,
  bemerkung: "",
  datum: "",
  fuhrparkeintragAnbauteile: [],
  maschinentransporte: [],
  materialanfuhr: [] as MaterialTransportDaten[],
  materialabtransport: [] as MaterialTransportDaten[],
  materialtransport: [] as MaterialTransportDaten[],
};

export const emptyFuhrparkeintragAnbauteil = {
  anbauteil: "",
  anbauteilNutzungsdauer: 0,
  anbauteilAnzahl: 1,
  anbauteilStundenPreis: 0,
};

export const emptyMaterialanfuhr = {
  quelleAbladestelle: "",
  material: "",
  meLadeVolumenFahrzeug: 0,
  kostenProEinheit: 0,
  anzahlFuhren: 1,
  einheit: "TO",
  menge: 0,
  lieferscheinNummer: "",
  bemerkung: "",
  buchungsArt: "Kosten",
};

// Handelt sich um Materialtransport zwischen Baustellen
export const emptyMaterialTransport = {
  zielBaustelle: "",
  meLadeVolumenFahrzeug: 0,
  kostenProEinheit: 0,
  anzahlFuhren: 1,
  einheit: "CB",
  menge: 0,
  lieferscheinNummer: "",
  bemerkung: "",
  abfallart: "",
  buchungsArt: "Kosten",
};

export const emptyMaterialAbtransport = {
  zielAbladestelle: "",
  meLadeVolumenFahrzeug: 0,
  material: "",
  kostenProEinheit: 0,
  bsNr: "",
  erzeuger: "",
  entsorger: "",
  anzahlFuhren: 1,
  einheit: "TO",
  fuhrTyp: "AB",
  buchungsTyp: "AB",
  menge: 0,
  lieferscheinNummer: "",
  bemerkung: "",
  buchungsArt: "Kosten",
};

export const emptyMaschinenTransport = {
  transportiertesFahrzeug: "",
  abholortBaustelle: "",
  abstellortBaustelle: "",
  lieferscheinNummer: "",
  bemerkung: "",
};
