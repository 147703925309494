import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  createFilterOptions,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Abfallart, ApiError, Material, MaterialListe } from "../../api";
import {
  useFetchMaterialsByAbladestelleId,
  useGetAbfallart,
  useGetMaterial,
} from "../../client/hooks";

interface MaterialSelectProps extends FieldProps {
  label: string;
  required?: boolean;
  abladestelleId: string;
  setAbfallartKey: (value: string) => void;
}

function MaterialEakSelect({
  label,
  required,
  abladestelleId,
  field,
  form,
  meta,
  setAbfallartKey,
}: MaterialSelectProps) {
  const searchMaterial = useFetchMaterialsByAbladestelleId();
  const ladeMaterial = useGetMaterial();
  const ladeAbfallart = useGetAbfallart();
  const [optionen, setOptionen] = useState<ReadonlyArray<Material>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [field.name, field.value, form, inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeMaterial(field.value)
        .then((material) => {
          form.setFieldValue(field.name, material);
          setInputValue(material);
        })
        .catch((error) => {
          console.error("Error loading material:", error);
        });
    }
  }, [field.name, field.value, form, ladeMaterial]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: MaterialListe) => void
        ) =>
          searchMaterial(abladestelleId, {
            search: request.input,
            limit: 1000,
            page: 0,
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchMaterial, abladestelleId]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  const filterOptions = createFilterOptions({
    ignoreAccents: true,
    ignoreCase: true,
    limit: 20,
    matchFrom: "any",
    stringify: (option: Material) => option.name,
    trim: false,
  });

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={filterOptions} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Material) => {
        if (typeof option === "string") {
          return "";
        } else {
          return option.name;
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);

        const material = value as Material;
        if (material) {
          ladeAbfallart(material.abfallartId).then((abfallart: Abfallart) => {
            setAbfallartKey(abfallart.key ?? "");
          });
        } else {
          setAbfallartKey("");
        }
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Material) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemText
              primary={option.name}
              secondary={option.abfallartKey}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default MaterialEakSelect;
