// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type FetchMaterialsByAbladestelleIdQuery = {
  search?: string;
  page?: number;
  limit?: number;
};

export function useFetchMaterialsByAbladestelleId(): (
  abladestelleId: string,
  query: FetchMaterialsByAbladestelleIdQuery
) => Promise<MaterialListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (abladestelleId, query = {}) =>
      (await getAPI()).material.fetchMaterialsByAbladestelleId({
        abladestelleId,
        ...query,
      }),
    [getAPI]
  );
}
