import { useFormikContext } from "formik";
import { useT } from "../../../../i18n";
import React from "react";
import { Button, Tooltip } from "@mui/material";
import FoundationIcon from "@mui/icons-material/Foundation";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CategoryIcon from "@mui/icons-material/Category";
import { NeuerMaterialButtonProps } from "./MaterialButtonProps";
import { useFahrzeugLadeMenge } from "../../../../hooks/useFahrzeugLadeMenge";
import {
  emptyMaterialAbtransport,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";

export default function NeuerMaterialabtransportButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const { t } = useT("fuhrpark");
  const { currentFahrzeugLadeMenge } = useFahrzeugLadeMenge({ fahrzeugId });

  const handleAddMaterialabtransport = async () => {
    const newMaterialAbtransport = emptyMaterialAbtransport;
    newMaterialAbtransport.meLadeVolumenFahrzeug = currentFahrzeugLadeMenge;

    const currentMaterialabtransport: MaterialTransportDaten[] =
      values.materialabtransport || [];

    await setFieldValue(`fuhrparkeintraege.${index}.materialabtransport`, [
      ...currentMaterialabtransport,
      newMaterialAbtransport,
    ]);
  };
  return (
    <Tooltip title={t("material-removal")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialabtransport}
      >
        <FoundationIcon />
        <KeyboardDoubleArrowRightIcon />
        <CategoryIcon />
      </Button>
    </Tooltip>
  );
}
