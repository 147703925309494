import {
  Box,
  Button,
  capitalize,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { Umsatz } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import StartEndFilter from "../StartEndFilter";

export type VorgaengeFilter = {
  search?: string;
  baustellenId?: string;
  isTaetigkeitsbericht?: boolean;
  filtered?: string;
  isFremdleistung?: boolean;
  startDate?: string;
  endDate?: string;
};

export type Props = Omit<DataRequest<VorgaengeFilter>, "filter"> &
  Partial<Pick<DataRequest<VorgaengeFilter>, "filter">>;

export default function VorgaengeDataTableExtern({ ...input }: Props) {
  const request = useDataRequest<VorgaengeFilter>({
    filter: { isFremdleistung: true },
    ...input,
  });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <VorgaengeResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useT("taetigkeitsbericht");
  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <StartEndFilter setField={setField} />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neue-fremdleistung");
            }}
          >
            {capitalize(t("create-activity-report-external"))}
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <StartEndFilter setField={setField} />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neue-fremdleistung");
            }}
          >
            {capitalize(t("create-activity-report-external"))}
          </Button>
        </>
      )}
    </Box>
  );
}

function VorgaengeResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}

function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadUmsatz: DataLoader<VorgaengeFilter, Umsatz> = useCallback(
    async (params) => (await getApi()).umsatz.sucheUmsatz({ ...params }),
    [getApi]
  );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["umsaetze"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadUmsatz}
      onRowClick={(row: GridRowParams<Umsatz>, event) => {
        if (event.ctrlKey || event.metaKey) {
          window.open(`${row.row.referenzUrl}`, "_blank");
        } else {
          navigate(`${row.row.referenzUrl}`);
        }
      }}
      name={"taetigkeitsberichte-extern"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<Umsatz>> {
  const { t, capitalize } = useT("vorgaenge");

  return useMemo(
    () => [
      {
        field: "buchungsDatum",
        headerName: t("date"),
        flex: 1,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) =>
          new Date(value as string).toLocaleDateString(undefined, {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
      },
      {
        field: "buchungsArt",
        headerName: capitalize(t("booking-type")),
        flex: 1,
      },
      {
        field: "baustellenBezeichnung",
        headerName: capitalize(t("construction-site")),
        width: 250,
      },
      {
        field: "lieferantenBezeichnung",
        headerName: capitalize(t("supplier")),
        flex: 1,
      },
      {
        field: "buchungsTyp",
        headerName: capitalize(t("booking-short-type")),
        flex: 1,
      },
      {
        field: "vorgangBezeichnung",
        headerName: capitalize(t("description")),
        flex: 2,
      },
      {
        field: "materialName",
        headerName: capitalize(t("material")),
        flex: 2,
      },
      {
        field: "eakSchluessel",
        headerName: capitalize(t("eak-nr")),
        flex: 1,
      },
      {
        field: "gesamtmenge",
        headerName: capitalize(t("total-units")),
        align: "right",
        headerAlign: "right",
        flex: 1,
      },
      {
        field: "mengeneinheit",
        headerName: capitalize(t("unit")),
        flex: 1,
      },
      {
        field: "lieferscheinNr",
        headerName: capitalize(t("delivery-note-number")),
        flex: 1,
      },
      {
        field: "betrag",
        headerName: capitalize(t("total-amount")),
        flex: 1.5,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "bemerkungTb",
        headerName: capitalize(t("remark")),
        flex: 2,
      },
      {
        field: "bearbeitetUm",
        headerName: capitalize(t("processing-time")),
        flex: 1.5,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            const formattedTime = date.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            return `${formattedDate} ${formattedTime}`;
          }
          return "";
        },
      },
    ],
    [t]
  );
}
