import {
  Box,
  Button,
  capitalize,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FastField, FieldArrayRenderProps } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React from "react";
import {
  useDeleteFuhrparkeintrag,
  useDeleteMaterialtransport,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid2";
import { Abladestelle, Material } from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import Buchungsart from "../../../Buchungsart";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import MaterialEakSelect from "../../../material/MaterialEakSelect";
import EinheitSelect from "../../../einheiten/EinheitSelect";
import {
  emptyFuhrparkeintragWithOutAnbauteile,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { useMaterialanfuhr } from "../../../../hooks/useMaterialanfuhr";
import FahrzeugSelect from "../../../fuhrpark/fahrzeug/FahrzeugSelect";
import { useRemoveFP } from "../../../../contexts/FuhrparkContext";
import FahrzeugAnlegenButton from "../FahrzeugAnlegenButton";

export default function MaterialanfuhrTaetigkeitsberichtExtern({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: FieldArrayRenderProps["push"];
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const { t: tTaetigkeitsbericht } = useT("taetigkeitsbericht");
  const deleteMaterialtransport = useDeleteMaterialtransport();
  const deleteFuhrparkEintrag = useDeleteFuhrparkeintrag();
  const isKosten: boolean = materialtransport.buchungsArt === "Kosten";

  const {
    removeFP,
    taetigkeitsberichtId,
    fuhrparkeintragIndex,
    fahrzeugHerkunft,
    pushFP,
    fuhrparkeintrag,
  } = useRemoveFP();

  const {
    abladestelleId,
    setAbladestelleId,
    eakSchluessel,
    materialField,
    quelleAbladestelleField,
    buchungsArtField,
    einheitField,
    preisField,
    fuhrenField,
  } = useMaterialanfuhr({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransport,
  });

  function handleAddCloneOfMaterialanfuhr() {
    const emptyFuhrparkeintrag = structuredClone(
      emptyFuhrparkeintragWithOutAnbauteile
    );

    emptyFuhrparkeintrag.datum = fuhrparkeintrag.datum || "";

    emptyFuhrparkeintrag.materialanfuhr.push({
      quelleAbladestelle:
        typeof quelleAbladestelleField.value === "string"
          ? quelleAbladestelleField.value
          : (quelleAbladestelleField.value as Abladestelle)?.id ?? "",
      meLadeVolumenFahrzeug: 0,
      material:
        typeof materialField.value === "string"
          ? materialField.value
          : (materialField.value as Material)?.id ?? "",
      einheit: einheitField.value ?? "CB",
      menge: 0,
      kostenProEinheit: preisField.value ?? 0,
      anzahlFuhren: fuhrenField.value ?? "",
      lieferscheinNummer: "",
      bemerkung: "",
      buchungsArt: buchungsArtField.value ?? "Kosten",
    } as MaterialTransportDaten);

    pushFP(emptyFuhrparkeintrag);
  }

  function handleMaterialAnfuhrRemove() {
    const materialtransportId = materialtransport.id;
    if (materialtransportId && taetigkeitsberichtId) {
      deleteMaterialtransport(
        materialtransport.taetigkeitsberichtId,
        materialtransport.fuhrparkeintragId,
        materialtransportId
      ).then(() => {
        deleteFuhrparkEintrag(
          taetigkeitsberichtId,
          materialtransport.fuhrparkeintragId
        ).then(() => {
          remove(materialTransportIndex);
          removeFP(fuhrparkeintragIndex);
        });
      });
    } else {
      remove(materialTransportIndex);
      removeFP(fuhrparkeintragIndex);
    }
  }

  return (
    <Stack direction="column" sx={{ marginTop: -4 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-delivery")}
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={FahrzeugSelect}
              name={`fuhrparkeintraege.${index}.fahrzeug`}
              label={capitalize(tTaetigkeitsbericht("carpool-entry-vehicle"))}
              fahrzeugHerkunft={fahrzeugHerkunft}
              required
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 1, md: 0.7 }}>
            <FahrzeugAnlegenButton />
          </Grid>
          <Grid size={{ xs: 2, md: 3 }}>
            <FastField
              component={TextField}
              label={capitalize(tTaetigkeitsbericht("activity-report-date"))}
              name={`fuhrparkeintraege.${index}.datum`}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid size={{ xs: 3, md: 3 }}>
            {materialtransport.zielBaustelle ? (
              <FastField
                component={BaustellenSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.zielBaustelle`}
                label={t("vehicle-unloading-point")}
                required
              />
            ) : (
              <FastField
                component={AbladestellenSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.quelleAbladestelle`}
                label={t("vehicle-unloading-point")}
                required
                setAbladestelleId={setAbladestelleId}
              />
            )}
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            {abladestelleId ? (
              <FastField
                component={MaterialEakSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`}
                label={t("material")}
                abladestelleId={abladestelleId}
                setAbfallartKey={() => {}}
                required
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("material") + " *"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Material"
                  disabled={true}
                ></Select>
              </FormControl>
            )}
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Stack>
              <Typography sx={{ color: "gray" }}>EAK-Schlüssel</Typography>
              {eakSchluessel}
            </Stack>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Box></Box>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }} container>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAddCloneOfMaterialanfuhr()}
            >
              <AddCircleIcon />
              {"(MA)"}
            </Button>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
                defaultValue: 0.0,
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
                defaultValue: 0.0,
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => handleMaterialAnfuhrRemove()}
              aria-label="delete"
            >
              <DeleteIcon />
              {"(MA)"}
            </Button>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-remark")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.bemerkung`}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
