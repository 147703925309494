import { useT } from "../../i18n";
import { Field, useField, useFormikContext } from "formik";
import { Box, Stack, Typography } from "@mui/material";
import { TextField } from "formik-mui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import BenutzerSelect from "../benutzer/BenutzerSelect";

export default function AnwesenheitFieldsContent({
  isVerwaltungAnwesenheit,
  startzeit,
  endzeit,
}: {
  isVerwaltungAnwesenheit: boolean;
  startzeit: string | null;
  endzeit: string | null;
}) {
  const { t } = useT("entsorger");
  const { setFieldValue } = useFormikContext();
  const [starttime, setStarttime] = useState<Dayjs | null>(
    startzeit ? dayjs(startzeit) : null
  );
  const [endtime, setEndtime] = useState<Dayjs | null>(
    endzeit ? dayjs(endzeit) : null
  );
  const setStart = (value: string) => {
    setFieldValue("start", value);
  };
  const setEnde = (value: string) => {
    setFieldValue("ende", value);
  };
  const [unbezahltePause] = useField("unbezahltePause");
  const [bezahltePause] = useField("bezahltePause");
  const [fahrzeitenField] = useField("fahrzeiten");
  const [arbeitszeitKonto] = useField("arbeitszeitKonto");

  useEffect(() => {
    if (starttime !== null && endtime !== null) {
      const bruttoArbeitszeitInStunden =
        endtime.diff(starttime, "minutes") / 60;
      const unbezahltePauseValue = parseFloat(unbezahltePause.value);
      const isUnbezahltePauseValid = !isNaN(unbezahltePauseValue);

      const brutto = isUnbezahltePauseValid
        ? bruttoArbeitszeitInStunden - unbezahltePauseValue
        : bruttoArbeitszeitInStunden;

      const bezahltePauseValue = parseFloat(bezahltePause.value);
      const isBezahltePauseValid = !isNaN(bezahltePauseValue);

      const fahrzeitenValue = parseFloat(fahrzeitenField.value);
      const isFahrzeitenValid = !isNaN(fahrzeitenValue);

      const nettoArbeitszeitInStunden =
        brutto -
        (isBezahltePauseValid ? bezahltePauseValue : 0) -
        (isFahrzeitenValid ? fahrzeitenValue : 0);
      const roundedBrutto = Math.round(brutto * 100) / 100;
      const roundedNetto = Math.round(nettoArbeitszeitInStunden * 100) / 100;
      const displayBrutto = roundedBrutto.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      const displayNetto = roundedNetto.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      setFieldValue("arbeitszeitBrutto", displayBrutto);
      setFieldValue("arbeitszeitNetto", displayNetto);

      const brzUebertragDisplay =
        roundedBrutto + parseFloat(arbeitszeitKonto.value) * -1;
      setFieldValue(
        "brzUebertrag",
        brzUebertragDisplay.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [
    starttime,
    endtime,
    unbezahltePause.value,
    fahrzeitenField.value,
    bezahltePause.value,
    arbeitszeitKonto.value,
  ]);
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("date") + " *"}
          name="datum"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        {isVerwaltungAnwesenheit && (
          <Field
            component={BenutzerSelect}
            label={"Mitarbeiter *"}
            name="mitarbeiter"
          />
        )}
      </Box>
      <Typography sx={{ mt: 2 }}>Einsatz von - bis</Typography>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          {starttime ? (
            <DesktopTimePicker
              label={"Startzeit"}
              ampm={false}
              value={starttime}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setStart(date.toISOString());
                    setStarttime(date);
                  }
                }
              }}
            ></DesktopTimePicker>
          ) : (
            <DesktopTimePicker
              label={"Startzeit"}
              ampm={false}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setStart(date.toISOString());
                    setStarttime(date);
                  }
                }
              }}
            ></DesktopTimePicker>
          )}
          {endtime ? (
            <DesktopTimePicker
              label={"Endzeit"}
              ampm={false}
              value={endtime}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setEnde(date.toISOString());
                    setEndtime(date);
                  }
                }
              }}
            ></DesktopTimePicker>
          ) : (
            <DesktopTimePicker
              label={"Endzeit"}
              ampm={false}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setEnde(date.toISOString());
                    setEndtime(date);
                  }
                }
              }}
            ></DesktopTimePicker>
          )}
        </LocalizationProvider>
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"unbezahlte Pause in Std"}
          name="unbezahltePause"
          type={"number"}
          inputProps={{ style: { textAlign: "right" }, step: 0.25 }}
        />
        <Field
          component={TextField}
          label={"Brutto Arbeitszeit"}
          name="arbeitszeitBrutto"
          inputProps={{
            readOnly: true,
            style: { textAlign: "right", paddingRight: "30px" },
          }}
          disabled
        />
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Fahrzeiten in Std"}
          name="fahrzeiten"
          type={"number"}
          inputProps={{ style: { textAlign: "right" }, step: 0.25 }}
        />
        <Field
          component={TextField}
          label={"bezahlte Pause in Std"}
          name="bezahltePause"
          type={"number"}
          inputProps={{ style: { textAlign: "right" }, step: 0.25 }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Netto Arbeitszeit"}
          name="arbeitszeitNetto"
          inputProps={{
            readOnly: true,
            style: { textAlign: "right", paddingRight: "30px" },
          }}
          disabled
        />
        {isVerwaltungAnwesenheit && (
          <Field
            component={TextField}
            label={"Arbeitszeit Konto"}
            type={"number"}
            name="arbeitszeitKonto"
            inputProps={{
              style: { textAlign: "right" },
              step: 0.25,
            }}
          />
        )}
      </Box>
      {isVerwaltungAnwesenheit && (
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
            gap: 2,
            mt: 2,
          }}
        >
          <Field
            component={TextField}
            label={"BRZ Übertrag"}
            name="brzUebertrag"
            inputProps={{
              readOnly: true,
              style: { textAlign: "right", paddingRight: "30px" },
            }}
            disabled
          />
        </Box>
      )}
    </Stack>
  );
}
