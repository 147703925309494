import { useFormikContext } from "formik";
import { useT } from "../../../../i18n";
import { Button, Tooltip } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FoundationIcon from "@mui/icons-material/Foundation";
import { NeuerMaterialButtonProps } from "./MaterialButtonProps";
import { useFahrzeugLadeMenge } from "../../../../hooks/useFahrzeugLadeMenge";
import {
  emptyMaterialanfuhr,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";

export default function NeuerMaterialanfuhrButton({
  values,
  index,
  fahrzeugId,
}: NeuerMaterialButtonProps) {
  const { setFieldValue } = useFormikContext();
  const { t } = useT("fuhrpark");
  const { currentFahrzeugLadeMenge } = useFahrzeugLadeMenge({ fahrzeugId });

  const handleAddMaterialanfuhr = async () => {
    const newMaterialanfuhr = emptyMaterialanfuhr;
    emptyMaterialanfuhr.meLadeVolumenFahrzeug = currentFahrzeugLadeMenge;

    const currentMaterialanfuhr: MaterialTransportDaten[] =
      values.materialanfuhr || [];

    await setFieldValue(`fuhrparkeintraege.${index}.materialanfuhr`, [
      ...currentMaterialanfuhr,
      newMaterialanfuhr,
    ]);
  };

  return (
    <Tooltip title={t("material-delivery")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialanfuhr}
      >
        <CategoryIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}
