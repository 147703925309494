import { createContext, ReactNode, useContext } from "react";
import { FieldArrayRenderProps } from "formik";
import { ExtendedFuhrparkeintragDaten } from "../models/taetigkeitsberichte/models";

interface FuhrparkContextProps {
  removeFP: (index: number) => void;
  taetigkeitsberichtId?: string | null;
  fuhrparkeintragIndex: number;
  fahrzeugHerkunft: string;
  pushFP: FieldArrayRenderProps["push"];
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
}

const FuhrparkContext = createContext<FuhrparkContextProps | undefined>(
  undefined
);

export const useRemoveFP = () => {
  const context = useContext(FuhrparkContext);
  if (!context) {
    throw new Error("useRemove must be used within a RemoveProvider");
  }
  return context;
};

export const FuhrparkProvider = ({
  removeFP,
  children,
  taetigkeitsberichtId,
  fuhrparkeintragIndex,
  fahrzeugHerkunft,
  pushFP,
  fuhrparkeintrag,
}: FuhrparkContextProps & {
  children: ReactNode;
}) => {
  return (
    <FuhrparkContext.Provider
      value={{
        removeFP,
        taetigkeitsberichtId,
        fuhrparkeintragIndex,
        fahrzeugHerkunft,
        pushFP,
        fuhrparkeintrag,
      }}
    >
      {children}
    </FuhrparkContext.Provider>
  );
};
