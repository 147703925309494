import { useState, useEffect, useCallback } from "react";
import { useGetAbfallart } from "../client/hooks";
import { Abfallart, Material } from "../api";

interface UseEakSchluesselProps {
  materialValue: Material | null;
}

export const useEakSchluessel = ({ materialValue }: UseEakSchluesselProps) => {
  const [eakSchluessel, setEAKSchluessel] = useState<string>("-");
  const ladeAbfallart = useGetAbfallart();

  const fetchData = useCallback(async () => {
    if (materialValue && materialValue.abfallartId) {
      const data: Abfallart = await ladeAbfallart(materialValue.abfallartId);
      setEAKSchluessel(data.key ?? "");
    } else {
      setEAKSchluessel("");
    }
  }, [ladeAbfallart, materialValue]);

  useEffect(() => {
    fetchData().then(() => {});
  }, [fetchData, materialValue]);

  return { eakSchluessel };
};
