import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import { useEakSchluessel } from "./useEakschluessel";
import { useMaterialPreisUndMenge } from "./useMaterialPreisUndMenge";
import { useGesamtBerechnung } from "./useGesamtBerechnung";
import { Material } from "../api";

interface UseMaterialanfuhrProps {
  index: number;
  materialTransportIndex: number;
  fahrzeugId: string;
  materialtransport: any;
}

export const useMaterialanfuhr = ({
  index,
  materialTransportIndex,
  fahrzeugId,
  materialtransport,
}: UseMaterialanfuhrProps) => {
  const [abladestelleId, setAbladestelleId] = useState<String | null>(
    materialtransport?.quelleAbladestelle ?? null
  );

  const [materialField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`
  );
  const [quelleAbladestelleField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.quelleAbladestelle`
  );
  const [buchungsArtField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.buchungsArt`
  );
  const [bemerkungField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.bemerkung`
  );
  const [lfsnrField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.lieferscheinNummer`
  );
  const { setFieldValue } = useFormikContext();
  const { eakSchluessel } = useEakSchluessel({
    materialValue: materialField.value as Material,
  });

  const { einheitField } = useMaterialPreisUndMenge({
    index,
    materialTransportIndex,
    fahrzeugId,
    materialtransportId: materialtransport.id,
    type: "materialanfuhr",
  });

  useEffect(() => {
    setAbladestelleId(abladestelleId);
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`,
      null
    );
  }, [setAbladestelleId, abladestelleId]);

  const { mengeField, preisField, fuhrenField } = useGesamtBerechnung({
    index,
    materialTransportIndex,
    type: "materialanfuhr",
  });

  return {
    abladestelleId,
    setAbladestelleId,
    eakSchluessel,
    materialField,
    quelleAbladestelleField,
    buchungsArtField,
    bemerkungField,
    lfsnrField,
    einheitField,
    mengeField,
    preisField,
    fuhrenField,
  };
};
