import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import {
  ExtendedTaetigkeitsberichtExternDaten,
  MaterialTransportDaten,
} from "../models/taetigkeitsberichte/models";
import { Taetigkeitsbericht } from "../api";
import {
  saveUpdatedAnbauteile,
  saveUpdatedFuhrparkeintrag,
  saveUpdatedMaterialabtransport,
  saveUpdatedMaterialanfuhr,
  saveUpdatedMaterialtransportZwischenBaustellen,
  saveUpdatedTatigketsberichtExtern,
} from "../models/taetigkeitsberichte/editTaetigekeitsberichtMutations";
import {
  saveFuhrparkeintrag,
  saveFuhrparkeintragAnbauteil,
  saveMaterialabtransport,
  saveMaterialanfuhr,
  saveMaterialtransportZwischenBaustellen,
} from "../models/taetigkeitsberichte/taetigkeitsBerichtMutations";
import { enqueueSnackbar } from "notistack";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useGetTaetigkeitsbericht,
  useSucheFuhrparkeintraege,
  useUpdateFuhrparkeintrag,
  useUpdateFuhrparkeintragAnbauteil,
  useUpdateMaterialtransport,
  useUpdateMaterialtransportZwischen,
  useUpdateTaetigkeitsbericht,
} from "../client/hooks";

export const useTaetigkeitsberichtExternDetails = (
  taetigkeitsberichtId: string,
  invalidateParams?: {
    queryKey?: [string, { addEmptyEntry: boolean }];
  }
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();

  const updateFuhrparkeintrag = useUpdateFuhrparkeintrag();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();

  const updateFuhrparkeintragAnbauteil = useUpdateFuhrparkeintragAnbauteil();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();

  const updateMaterialtransport = useUpdateMaterialtransport();
  const createMaterialtransport = useCreateMaterialtransport();

  const updateMaterialtransportZwischenBaustellen =
    useUpdateMaterialtransportZwischen();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();

  return useMutation(
    async (values: ExtendedTaetigkeitsberichtExternDaten) => {
      const tb: Taetigkeitsbericht = await getTaetigkeitsbericht(
        taetigkeitsberichtId
      );
      const alterTaetigkeitsberichtstatus = tb?.status;

      // Update Tätigkeitsbericht
      const updatedTaetigkeitsbericht: Taetigkeitsbericht =
        await saveUpdatedTatigketsberichtExtern(
          taetigkeitsberichtId,
          values,
          updateTaetigkeitsbericht
        );

      // Update oder Create Fuhrparkeinträge
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          if (fuhrparkeintrag.id) {
            return saveUpdatedFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintrag,
              updateFuhrparkeintrag
            );
          } else {
            return saveFuhrparkeintrag(
              updatedTaetigkeitsbericht,
              fuhrparkeintrag,
              createFuhrparkeintrag
            );
          }
        }
      );

      const fuhrparkeintragResults = await Promise.all(
        fuhrparkeintragPromises.filter((p): p is Promise<any> => p !== null)
      );
      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      await Promise.all(
        processFuhrparkAnbauteile(
          values,
          fuhrparkeintragIds,
          taetigkeitsberichtId,
          createFuhrparkeintragAnbauteil,
          updateFuhrparkeintragAnbauteil
        )
      );

      await Promise.all(
        processMaterialanfuhren(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransport,
          createMaterialtransport
        )
      );

      await Promise.all(
        processMaterialabtransporte(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransport,
          createMaterialtransport
        )
      );

      await Promise.all(
        processMaterialtransporteZwischenBaustellen(
          values,
          updatedTaetigkeitsbericht,
          fuhrparkeintragIds,
          updateMaterialtransportZwischenBaustellen,
          createMaterialtransportZwischenBaustellen
        )
      );

      const updatedFuhrparkeintraege = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );
      return {
        taetigkeitsbericht: updatedTaetigkeitsbericht,
        alterStatus: alterTaetigkeitsberichtstatus,
        fuhrparkeintraege: updatedFuhrparkeintraege.items,
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: invalidateParams?.queryKey,
        });

        navigate(`/fremdleistungen/`, { replace: true });

        enqueueSnackbar("Änderungen gespeichert", { variant: "success" });
      },
      onError: (error) => {
        enqueueSnackbar("Fehler beim Speichern " + error, { variant: "error" });
      },
    }
  );
};
//TODO: Funktion von useTaetigkeitsberichtDetails können auch hier verwendet und zusammengefasst werden
const processFuhrparkAnbauteile = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsberichtId: string,
  createFuhrparkeintragAnbauteil: any,
  updateFuhrparkeintragAnbauteil: any
) =>
  values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.fuhrparkeintragAnbauteile.map((anbauteil) => {
      if (anbauteil.id) {
        return saveUpdatedAnbauteile(
          taetigkeitsberichtId,
          currentFuhrparkEintragId,
          anbauteil,
          updateFuhrparkeintragAnbauteil
        );
      } else {
        return saveFuhrparkeintragAnbauteil(
          taetigkeitsberichtId,
          anbauteil,
          currentFuhrparkEintragId,
          createFuhrparkeintragAnbauteil
        );
      }
    });
  });

const processMaterialanfuhren = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransport: any,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
      if (materialanfuhr.id) {
        return saveUpdatedMaterialanfuhr(
          taetigkeitsbericht,
          materialanfuhr,
          updateMaterialtransport
        );
      } else {
        return saveMaterialanfuhr(
          taetigkeitsbericht,
          currentFuhrparkEintragId,
          materialanfuhr,
          createMaterialtransport
        );
      }
    });
  });
};

const processMaterialabtransporte = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransport: any,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];
    if (!currentFuhrparkEintragId) throw new Error("Fuhrparkeintrag ID fehlt");

    return fuhrparkeintrag.materialabtransport.map((materialabtransport) => {
      if (materialabtransport.id) {
        return saveUpdatedMaterialabtransport(
          taetigkeitsbericht,
          materialabtransport,
          updateMaterialtransport
        );
      } else {
        return saveMaterialabtransport(
          taetigkeitsbericht,
          currentFuhrparkEintragId,
          materialabtransport,
          createMaterialtransport
        );
      }
    });
  });
};

const processMaterialtransporteZwischenBaustellen = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  taetigkeitsbericht: Taetigkeitsbericht,
  fuhrparkeintragIds: string[],
  updateMaterialtransportZwischenBaustellen: any,
  createMaterialtransportZwischenBaustellen: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkEintragId = fuhrparkeintragIds[index];

    return fuhrparkeintrag.materialtransport.map(
      (materialtransport: MaterialTransportDaten) => {
        if (materialtransport.id) {
          return saveUpdatedMaterialtransportZwischenBaustellen(
            taetigkeitsbericht.id,
            currentFuhrparkEintragId,
            materialtransport,
            taetigkeitsbericht.baustelleId,
            updateMaterialtransportZwischenBaustellen
          );
        } else {
          return saveMaterialtransportZwischenBaustellen(
            taetigkeitsbericht,
            currentFuhrparkEintragId,
            materialtransport,
            createMaterialtransportZwischenBaustellen
          );
        }
      }
    );
  });
};
