import {
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtExternDaten,
  MaterialTransportDaten,
} from "../models/taetigkeitsberichte/models";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useCreateTaetigkeitsbericht,
} from "../client/hooks";
import { enqueueSnackbar } from "notistack";
import {
  saveFuhrparkeintrag,
  saveFuhrparkeintragAnbauteil,
  saveMaterialabtransport,
  saveMaterialanfuhr,
  saveMaterialtransportZwischenBaustellen,
  saveTaetigkeitsberichtExtern,
} from "../models/taetigkeitsberichte/taetigkeitsBerichtMutations";
import {
  getBaustelleId,
  getLieferantId,
} from "../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { Fuhrparkeintrag, Taetigkeitsbericht } from "../api";

const today = new Date();
const day = today.getDate().toString().padStart(2, "0");
const month = (today.getMonth() + 1).toString().padStart(2, "0");
const year = today.getFullYear().toString();

interface InitialValues {
  lieferant: string;
  baustelle: string;
  datum: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

export const initialTaetigekitsberichtExternValues: InitialValues = {
  lieferant: "",
  baustelle: "",
  datum: `${year}-${month}-${day}`,
  fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
};

export const useSubmitTaetigkeitsberichtExtern = (saveBetween?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();

  return useMutation(
    async (values: ExtendedTaetigkeitsberichtExternDaten) => {
      const lieferantId: string = getLieferantId(values);

      const baustelleId = getBaustelleId(values);
      const taetigkeitsbericht: Taetigkeitsbericht =
        await saveTaetigkeitsberichtExtern(
          baustelleId,
          values,
          createTaetigkeitsbericht,
          lieferantId
        );

      const fuhrparkeintragPromises: Promise<Fuhrparkeintrag>[] =
        values.fuhrparkeintraege.map(
          (fuhrparkeintrag: ExtendedFuhrparkeintragDaten) =>
            saveFuhrparkeintrag(
              taetigkeitsbericht,
              fuhrparkeintrag,
              createFuhrparkeintrag
            )
        );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);
      const fuhrparkeintragIds: string[] = fuhrparkeintragResults.map(
        (result) => result.id
      );

      await Promise.all(
        processFuhrparkanbauteil(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht.id,
          createFuhrparkeintragAnbauteil
        )
      );
      await Promise.all(
        processFuhrparkMaterialanfuhren(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransport
        )
      );
      await Promise.all(
        processFuhrparkMaterialabtransporte(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransport
        )
      );
      await Promise.all(
        processFuhrparkMaterialTransportZwischenBaustellen(
          values,
          fuhrparkeintragIds,
          taetigkeitsbericht,
          createMaterialtransportZwischenBaustellen
        )
      );

      return taetigkeitsbericht;
    },
    {
      onSuccess: (taetigkeitsbericht) => {
        queryClient.invalidateQueries(["taetigkeitsberichte"]);
        if (saveBetween) {
          navigate(`/fremdleistungen/${taetigkeitsbericht.id}`);
        } else {
          navigate(`/fremdleistungen`);
        }

        enqueueSnackbar("Fremdleistung angelegt", {
          variant: "success",
        });
      },
    }
  );
};

//--------------------------------------------------------
//Functions to process the different parts of the fuhrpark
//--------------------------------------------------------
//TODO: Funktion von useTaetigkeitsbericht können auch hier verwendet und zusammengefasst werden
const processFuhrparkanbauteil = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsberichtId: string,
  createFuhrparkeintragAnbauteil: any
) => {
  return values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten) => {
        return saveFuhrparkeintragAnbauteil(
          taetigkeitsberichtId,
          fuhrparkeintragAnbauteil,
          currentFuhrparkeintragId,
          createFuhrparkeintragAnbauteil
        );
      }
    );
  });
};

const processFuhrparkMaterialanfuhren = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialanfuhr.map(
      (materialanfuhr: MaterialTransportDaten) => {
        return saveMaterialanfuhr(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialanfuhr,
          createMaterialtransport
        );
      }
    );
  });
};

const processFuhrparkMaterialabtransporte = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransport: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialabtransport.map(
      (materialabtransport: MaterialTransportDaten) => {
        return saveMaterialabtransport(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialabtransport,
          createMaterialtransport
        );
      }
    );
  });
};

const processFuhrparkMaterialTransportZwischenBaustellen = (
  values: ExtendedTaetigkeitsberichtExternDaten,
  fuhrparkeintragIds: string[],
  taetigkeitsbericht: Taetigkeitsbericht,
  createMaterialtransportZwischenBaustellen: any
) => {
  return values.fuhrparkeintraege.map((fuhparkeintrag, index) => {
    const currentFuhrparkeintragId = fuhrparkeintragIds[index];
    return fuhparkeintrag.materialtransport.map(
      (materialtransport: MaterialTransportDaten) => {
        return saveMaterialtransportZwischenBaustellen(
          taetigkeitsbericht,
          currentFuhrparkeintragId,
          materialtransport,
          createMaterialtransportZwischenBaustellen
        );
      }
    );
  });
};
