import { Box } from "@mui/material";
import React from "react";

interface TransportFieldArrayWrapperProps {
  children: React.ReactNode;
}

export const TransportFieldArrayWrapper = ({ children }: TransportFieldArrayWrapperProps) => (
  <Box sx={{ marginTop: 2 }}>
    <Box
      display={"grid"}
      sx={{
        gridTemplateColumns: ["1fr", "10fr .1fr"],
        gap: 2,
      }}
    >
      {children}
    </Box>
  </Box>
); 