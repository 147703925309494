import { useT } from "../../../../i18n";
import { Button, Tooltip } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FoundationIcon from "@mui/icons-material/Foundation";
import { NeuerMaterialTtbExternButtonProps } from "./MaterialButtonProps";
import {
  emptyFuhrparkeintragWithOutAnbauteile,
  emptyMaterialAbtransport,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";

export default function NeuerMaterialabtransportTtbExternButton({
  push,
}: NeuerMaterialTtbExternButtonProps) {
  const { t } = useT("fuhrpark");

  const handleAddMaterialabtransport = async () => {
    let emptyFuhrparkeintrag = structuredClone(
      emptyFuhrparkeintragWithOutAnbauteile
    );

    emptyFuhrparkeintrag.materialabtransport.push(
      emptyMaterialAbtransport as MaterialTransportDaten
    );

    push(emptyFuhrparkeintrag);
  };

  return (
    <Tooltip title={t("material-removal")} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMaterialabtransport}
      >
        <CategoryIcon />
        <KeyboardDoubleArrowRightIcon />
        <FoundationIcon />
      </Button>
    </Tooltip>
  );
}
