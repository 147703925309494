import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useGetMaterialtransporteZwischenListe,
  useGetPersonaleintrag,
  useGetTaetigkeitsbericht,
  useSucheFuhrparkeintraege,
  useSucheFuhrparkeintragAnbauteil,
  useSucheMaschinentransporte,
  useSucheMaterialtransporteAbtransport,
  useSucheMaterialtransporteAnfuhr,
} from "../../client/hooks";
import {
  Fuhrparkeintrag,
  Personaleintrag,
  Taetigkeitsbericht,
} from "../../api";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTaetigkeitsberichtButton from "../../components/taetigkeitsberichte/DeleteTaetigkeitsberichtButton";
import { ExpandContextProvider } from "../../expand";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import { validationSchemaTaetigkeitsberichtDetails } from "../../models/taetigkeitsberichte/taetigkeitsberichtValidationSchema";
import {
  emptyFuhrparkeintrag,
  ExtendedFuhrparkeintragAnbauteilDaten,
  ExtendedFuhrparkeintragDaten,
  ExtendedTaetigkeitsberichtDaten,
  MaschinentransportDaten,
  MaterialTransportDaten,
} from "../../models/taetigkeitsberichte/models";
import { useTaetigkeitsberichtDetails } from "../../hooks/useTaetigkeitsberichtDetails";
import { timestampToDatetimeLocal } from "../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import ConfirmNavigationDialog from "../../components/ConfirmNavigationDialog";
import { useConfirmationNavDialog } from "../../hooks/useConfirmationNavDialog";
import { useQuery } from "react-query";

const FuhrparkeintragEigenPersonalItem = React.lazy(
  () =>
    import(
      "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem"
    )
);

export default function TaetigkeitsberichtDetails() {
  const { id: taetigkeitsberichtId = "" } = useParams<{
    id: string;
  }>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stringExpandedindexInherited: string | null = queryParams.get(
    "expandedindexInherited"
  );
  const expandedindexInherited: number | null = stringExpandedindexInherited
    ? parseInt(stringExpandedindexInherited)
    : null;

  const stringAddedFPInherited: string | null = queryParams.get("addedFP");
  const addedFPInherited: boolean = stringAddedFPInherited
    ? stringAddedFPInherited === "true"
    : false;
  const { t } = useTranslation("taetigkeitsbericht");
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const getPersonaleintrag = useGetPersonaleintrag();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();
  const searchFuhrparkeintragAnbauteil = useSucheFuhrparkeintragAnbauteil();
  const searchMaschinentransport = useSucheMaschinentransporte();
  const searchMaterialtransportAnfuhr = useSucheMaterialtransporteAnfuhr();
  const searchMaterialtransporteAbtransport =
    useSucheMaterialtransporteAbtransport();
  const searchMaterialZwischenBaustellen =
    useGetMaterialtransporteZwischenListe();
  const [fuhrparkeintraege, setFuhrparkeintraege] = useState<
    ExtendedFuhrparkeintragDaten[]
  >([]);
  const [taetigkeitsbericht, setTaetigkeitsbericht] =
    useState<Taetigkeitsbericht | null>(null);

  const [personaleintrag, setPersonaleintrag] =
    useState<Personaleintrag | null>(null);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(
    expandedindexInherited
  );
  const localStorageKey = "expandedItems";
  const [expandedItems, setExpandedItems] = useState<boolean[]>(() =>
    JSON.parse(localStorage.getItem(localStorageKey) || "[]")
  );

  const [berichtOffen, setBerichtOffen] = useState<boolean>(
    taetigkeitsbericht?.status === "OFFEN"
  );

  const [addEmptyEntry, setAddEmptyEntry] = useState(false);
  const [initialScroll, setInitialScroll] = useState(true);
  useEffect(() => {
    setBerichtOffen(taetigkeitsbericht?.status === "OFFEN");
  }, [taetigkeitsbericht]);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    setExpandedItems((prevState) => {
      const newExpandedItems = [...prevState];
      newExpandedItems[index] = !newExpandedItems[index];
      localStorage.setItem(localStorageKey, JSON.stringify(newExpandedItems));
      return newExpandedItems;
    });
  };

  const [dauerVorhanden, setDauerVorhanden] = useState<number>(
    personaleintrag?.arbeitszeitNetto ?? 0
  );

  const { data: fuhrparkeintraegeData } = useQuery(
    ["taetigkeitsberichte", { addEmptyEntry }],
    async ({ queryKey }) => {
      const [_, { addEmptyEntry }] = queryKey as [
        string,
        { addEmptyEntry: boolean }
      ];

      if (!taetigkeitsberichtId) return [];
      const fuhrparkeintraegeData = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );

      const completeFuhrparkeintraege = await Promise.all(
        fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
          const fuhrparkeintragAnbauteileData =
            await searchFuhrparkeintragAnbauteil(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );

          const maschinentransporteData = await searchMaschinentransport(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialanfuhrData = await searchMaterialtransportAnfuhr(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialabtransportData =
            await searchMaterialtransporteAbtransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
          const materialzwischenData = await searchMaterialZwischenBaustellen(
            taetigkeitsberichtId,
            fuhrparkeintrag.id
          );

          return {
            ...fuhrparkeintrag,
            fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            maschinentransporte: maschinentransporteData.items,
            materialanfuhr: materialanfuhrData.items,
            materialabtransport: materialabtransportData.items,
            materialtransport: materialzwischenData.items,
          };
        })
      );

      if (initialScroll) {
        setInitialScroll(false);
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }

      return addEmptyEntry && !addedFPInherited
        ? [...completeFuhrparkeintraege, emptyFuhrparkeintrag]
        : completeFuhrparkeintraege;
    },
    {
      enabled: !!taetigkeitsberichtId,
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (fuhrparkeintraegeData) {
      // @ts-ignore
      setFuhrparkeintraege(fuhrparkeintraegeData);
    }
  }, [fuhrparkeintraegeData]);

  const { data: taetigkeitsberichtData } = useQuery(
    ["taetigkeitsberichte", taetigkeitsberichtId],
    () => getTaetigkeitsbericht(taetigkeitsberichtId),
    {
      enabled: !!taetigkeitsberichtId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: personaleintragData } = useQuery(
    ["taetigkeitsberichte", taetigkeitsberichtId, "personaleintrag"],
    () => getPersonaleintrag(taetigkeitsberichtId),
    {
      enabled: !!taetigkeitsberichtId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (taetigkeitsberichtData) {
      setTaetigkeitsbericht(taetigkeitsberichtData);
    }
  }, [taetigkeitsberichtData]);

  useEffect(() => {
    if (personaleintragData) {
      setPersonaleintrag(personaleintragData);
    }
  }, [personaleintragData]);

  useEffect(() => {
    var aktuelleVorhandeneDauer = personaleintrag?.arbeitszeitNetto ?? 0;
    fuhrparkeintraege.forEach((fuhrparkeintrag) => {
      aktuelleVorhandeneDauer -= fuhrparkeintrag.fahrzeugNutzungsdauer;
    });
    setDauerVorhanden(
      aktuelleVorhandeneDauer > 0 ? aktuelleVorhandeneDauer : 0
    );
  }, [taetigkeitsbericht, fuhrparkeintraege, personaleintrag]);

  const saveMutation = useTaetigkeitsberichtDetails(
    taetigkeitsberichtId,
    expandedItems,
    expandedIndex,
    berichtOffen,
    false,
    {
      queryKey: ["taetigkeitsberichte", { addEmptyEntry: true }],
    }
  );

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: Fuhrparkeintrag[];
    }
  ) {
    try {
      await saveMutation.mutateAsync(values);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const submitMutation = useTaetigkeitsberichtDetails(
    taetigkeitsberichtId,
    expandedItems,
    expandedIndex,
    berichtOffen,
    true,
    {
      queryKey: ["taetigkeitsberichte", { addEmptyEntry: false }],
    }
  );

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten,
      formikHelpers: FormikHelpers<ExtendedTaetigkeitsberichtDaten>
    ) => {
      const { setSubmitting, setStatus, resetForm } = formikHelpers;

      try {
        setSubmitting(true);
        await submitMutation.mutateAsync(values);
        setStatus(undefined);
        //TODO: check if necessary
        // resetForm({ values });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [submitMutation]
  );
  const backRoute: string = berichtOffen
    ? "/berichtfreigabe"
    : "/taetigkeitsberichte";
  const {
    showConfirmDialog,
    handleConfirmBack,
    handleCancelBack,
    handleBackClick,
  } = useConfirmationNavDialog({ backRoute });

  if (!taetigkeitsbericht || !personaleintrag) {
    return <div>Loading...</div>;
  }

  const fpArray = fuhrparkeintraege.map((fuhrparkeintrag) => ({
    id: fuhrparkeintrag.id,
    // @ts-ignore
    fahrzeug: fuhrparkeintrag.fahrzeugId,
    fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
    fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
    fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
    bemerkung: fuhrparkeintrag.bemerkung,
    datum: fuhrparkeintrag.datum,
    fuhrparkeintragAnbauteile: fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten) => ({
        id: fuhrparkeintragAnbauteil.id,
        // @ts-ignore
        anbauteil: fuhrparkeintragAnbauteil.anbauteilId,
        anbauteilNutzungsdauer: fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
        anbauteilStundenPreis: fuhrparkeintragAnbauteil.anbauteilStundenPreis,
        anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
      })
    ),
    maschinentransporte: fuhrparkeintrag.maschinentransporte.map(
      (maschinentransport: MaschinentransportDaten) => ({
        id: maschinentransport.id,
        baustelleId: maschinentransport.baustelleId,
        transportiertesFahrzeug:
          // @ts-ignore
          maschinentransport.transportiertesFahrzeugId,
        // @ts-ignore
        abholortBaustelle: maschinentransport.abholortBaustellenId,
        abstellortBaustelle:
          // @ts-ignore
          maschinentransport.abstellortBaustellenId,
        fuhrTyp: maschinentransport.fuhrTyp,
        lieferscheinNummer: maschinentransport.lieferscheinNummer,
        bemerkung: maschinentransport.bemerkung,
      })
    ),
    materialanfuhr: fuhrparkeintrag.materialanfuhr.map(
      (materialanfuhr: MaterialTransportDaten) => ({
        id: materialanfuhr.id,
        baustelleId: materialanfuhr.baustelleId,
        // @ts-ignore
        quelleAbladestelle: materialanfuhr.quelleAbladestelleId,
        // @ts-ignore
        zielBaustelle: materialanfuhr.zielBaustelleId,
        // @ts-ignore
        material: materialanfuhr.materialId,
        fuhrTyp: materialanfuhr.fuhrTyp,
        einheit: materialanfuhr.einheit,
        menge: materialanfuhr.menge,
        mengeGesamt: materialanfuhr.mengeGesamt,
        kostenProEinheit: materialanfuhr.kostenProEinheit,
        anzahlFuhren: materialanfuhr.anzahlFuhren,
        preisGesamt: materialanfuhr.preisGesamt,
        lieferscheinNummer: materialanfuhr.lieferscheinNummer,
        bemerkung: materialanfuhr.bemerkung,
        meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
        buchungsArt: materialanfuhr.buchungsArt,
        // @ts-ignore
        abfallart: materialanfuhr.abfallartId,
      })
    ),
    materialabtransport: fuhrparkeintrag.materialabtransport.map(
      (materialabtransport: MaterialTransportDaten) => ({
        id: materialabtransport.id,
        baustelleId: materialabtransport.baustelleId,
        // @ts-ignore
        zielAbladestelle: materialabtransport.zielAbladestelleId,
        // @ts-ignore
        zielBaustelle: materialabtransport.zielBaustelleId,
        // @ts-ignore
        quelleBaustelle: materialabtransport.quelleBaustelleId,
        // @ts-ignore
        material: materialabtransport.materialId,
        fuhrTyp: materialabtransport.fuhrTyp,
        einheit: materialabtransport.einheit,
        menge: materialabtransport.menge,
        mengeGesamt: materialabtransport.mengeGesamt,
        kostenProEinheit: materialabtransport.kostenProEinheit,
        anzahlFuhren: materialabtransport.anzahlFuhren,
        preisGesamt: materialabtransport.preisGesamt,
        lieferscheinNummer: materialabtransport.lieferscheinNummer,
        bemerkung: materialabtransport.bemerkung,
        meLadeVolumenFahrzeug: materialabtransport.meLadeVolumenFahrzeug,
        buchungsArt: materialabtransport.buchungsArt,
        bsNr: materialabtransport.bsNr,
        // @ts-ignore
        entsorger: materialabtransport.entsorgerId,
        // @ts-ignore
        erzeuger: materialabtransport.erzeugerId,
        buchungsTyp: materialabtransport.buchungsTyp,
        // @ts-ignore
        abfallart: materialabtransport.abfallartId,
      })
    ),
    materialtransport: fuhrparkeintrag.materialtransport.map(
      (materialabtransport: MaterialTransportDaten) => ({
        id: materialabtransport.id,
        // @ts-ignore
        zielBaustelle: materialabtransport.zielBaustelleId,
        // @ts-ignore
        quelleBaustelle: materialabtransport.quelleBaustelleId,
        materialId: materialabtransport.material,
        einheit: materialabtransport.einheit,
        menge: materialabtransport.menge,
        mengeGesamt: materialabtransport.mengeGesamt,
        kostenProEinheit: materialabtransport.kostenProEinheit,
        anzahlFuhren: materialabtransport.anzahlFuhren,
        preisGesamt: materialabtransport.preisGesamt,
        lieferscheinNummer: materialabtransport.lieferscheinNummer,
        bemerkung: materialabtransport.bemerkung,
        meLadeVolumenFahrzeug: materialabtransport.meLadeVolumenFahrzeug,
        buchungsArt: materialabtransport.buchungsArt,
        buchungsTyp: materialabtransport.buchungsTyp,
        // @ts-ignore
        abfallart: materialabtransport.abfallartId,
      })
    ),
  }));

  // @ts-ignore
  return (
    <Layout
      title={capitalize(t("activity-report"))}
      back={backRoute}
      onBackClick={handleBackClick}
    >
      <ConfirmNavigationDialog
        open={showConfirmDialog}
        onConfirm={handleConfirmBack}
        onCancel={handleCancelBack}
        title={t("confirm-cancel-title")}
        message={t("confirm-cancel-message")}
      />

      <Guard
        permission={"taetigkeitsbericht:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          {taetigkeitsbericht.deleted === undefined ? (
            <Guard permission={"taetigkeitsbericht:delete"}>
              <DeleteTaetigkeitsberichtButton
                id={taetigkeitsberichtId}
              ></DeleteTaetigkeitsberichtButton>
            </Guard>
          ) : (
            <></>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={{
              mitarbeiter: personaleintrag?.mitarbeiterId ?? "",
              baustelle: taetigkeitsbericht?.baustelleId ?? "",
              datum: taetigkeitsbericht?.datum ?? "",
              arbeitszeitNetto: personaleintrag?.arbeitszeitNetto ?? null,
              stundensatz: personaleintrag?.stundensatz ?? 0,
              lohnkosten: personaleintrag?.lohnkosten ?? 0,
              bemerkung: personaleintrag?.bemerkung ?? "",
              bearbeitetUm:
                timestampToDatetimeLocal(
                  taetigkeitsbericht?.updated?.timestamp
                ) ?? "",
              // @ts-ignore
              fuhrparkeintraege: addedFPInherited
                ? [...fpArray, emptyFuhrparkeintrag]
                : fpArray,
            }}
            validationSchema={validationSchemaTaetigkeitsberichtDetails}
            // @ts-ignore
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <DialogContent>
                  <Typography>
                    {capitalize(t("activity-report-staff"))}
                    {taetigkeitsbericht.deleted === undefined ? (
                      ""
                    ) : (
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    )}
                  </Typography>
                  <Form id="details-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContent isNew={false} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ remove, push }) => (
                        <>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <Box key={index} sx={{ marginTop: 2 }}>
                                <Box
                                  display={"grid"}
                                  sx={{
                                    gridTemplateColumns: ["1fr", "10fr .1fr"],
                                    gap: 2,
                                    marginTop: 2,
                                  }}
                                >
                                  <ExpandContextProvider
                                    id={`fuhrparkeintrag-${index}`}
                                  >
                                    <FuhrparkeintragEigenPersonalItem
                                      isNew={!fuhrparkeintrag.id}
                                      fuhrparkeintrag={fuhrparkeintrag}
                                      index={index}
                                      expanded={expandedItems[index] ?? true}
                                      handleChange={handleAccordionChange}
                                      taetigkeitsberichtDatum={values.datum}
                                      dauerVorhanden={dauerVorhanden}
                                      isValidating={isValidating}
                                      isValid={isValid}
                                      remove={() => remove(index)}
                                      taetigkeitsberichtId={
                                        taetigkeitsberichtId
                                      }
                                    />
                                  </ExpandContextProvider>
                                </Box>
                              </Box>
                            )
                          )}
                          <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                            <Button
                              variant="contained"
                              color={berichtOffen ? "success" : "secondary"}
                              onClick={async () => {
                                // Speichere zuerst die bestehenden Einträge, wenn vorhanden
                                if (values.fuhrparkeintraege.length > 0) {
                                  //@ts-ignore
                                  await saveFuhrparkeintraege(values);
                                  setBerichtOffen(false);
                                  push(emptyFuhrparkeintrag);
                                }
                                setAddEmptyEntry(true);

                                // Expandiere den neuen Eintrag
                                const newIndex =
                                  values.fuhrparkeintraege.length;
                                setExpandedItems((prevState) => {
                                  const newExpandedItems = [...prevState];
                                  newExpandedItems[newIndex] = true;
                                  localStorage.setItem(
                                    "expandedItems",
                                    JSON.stringify(newExpandedItems)
                                  );
                                  return newExpandedItems;
                                });
                              }}
                              disabled={isValidating || !isValid}
                            >
                              {berichtOffen
                                ? capitalize(t("release-and-add-carpool"))
                                : capitalize(t("add-carpool"))}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  {berichtOffen ? (
                    <SubmitButton
                      form="details-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="success"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("to-release"))}
                    </SubmitButton>
                  ) : (
                    <SubmitButton
                      form="details-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("save"))}
                    </SubmitButton>
                  )}
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
