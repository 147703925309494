import { useState, useCallback, useEffect } from "react";
import { useFormikContext, useField } from "formik";
import { ExtendedFuhrparkeintragDaten } from "../models/taetigkeitsberichte/models";

interface UseFuhrparkNutzungsdauerProps {
  index: number;
  isNew: boolean;
  dauerVorhanden: number;
}

export const useFuhrparkNutzungsdauer = ({
  index,
  isNew,
  dauerVorhanden,
}: UseFuhrparkNutzungsdauerProps) => {
  const { setFieldValue } = useFormikContext();
  const [ueberstiegeneNutzungsdauer, setUeberstiegeneNutzungsdauer] =
    useState(false);
  const [fahrzeugNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`
  );

  const setInitialNutzungsdauer = useCallback(async () => {
    if (isNew && dauerVorhanden) {
      await setFieldValue(
        `fuhrparkeintraege.${index}.fahrzeugNutzungsdauer`,
        dauerVorhanden
      );
    }
  }, [isNew, index, setFieldValue]);

  useEffect(() => {
    setInitialNutzungsdauer().then(() => {});
  }, [setInitialNutzungsdauer]);

  const checkUeberstiegeneNutzungsdauer = useCallback(
    (fuhrparkeintrag: ExtendedFuhrparkeintragDaten) => {
      const gesamtAnbauteileNutzungsdauer =
        fuhrparkeintrag.fuhrparkeintragAnbauteile.reduce(
          (sum, anbauteil) => sum + anbauteil.anbauteilNutzungsdauer,
          0
        );

      setUeberstiegeneNutzungsdauer(
        fahrzeugNutzungsdauerField.value < gesamtAnbauteileNutzungsdauer
      );
    },
    [fahrzeugNutzungsdauerField.value]
  );

  return {
    ueberstiegeneNutzungsdauer,
    setInitialNutzungsdauer,
    checkUeberstiegeneNutzungsdauer,
    fahrzeugNutzungsdauerField,
  };
};
