import { FieldArray } from "formik";
import { TransportFieldArrayWrapper } from "../TransportFieldArrayWrapper";
import {
  ExtendedFuhrparkeintragDaten,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { getFahrzeugId } from "../../../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { Box } from "@mui/material";
import React from "react";
import MaterialanfuhrFieldsContent from "../materialanfuhr/MaterialanfuhrFieldsContent";
import MaterialanfuhrTaetigkeitsberichtExtern from "../materialanfuhr/MaterialanfuhrTaetigkeitsberichtExtern";

interface MaterialanfuhrArrayProps {
  index: number;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  isFremdleistung?: boolean;
}

export const MaterialanfuhrArray = ({
  index,
  fuhrparkeintrag,
  isFremdleistung,
}: MaterialanfuhrArrayProps) => {
  return (
    <FieldArray name={`fuhrparkeintraege.${index}.materialanfuhr`}>
      {({ push, remove }) => (
        <>
          {fuhrparkeintrag.materialanfuhr?.map(
            (
              materialanfuhr: MaterialTransportDaten,
              indexMaterialanfuhr: number
            ) => (
              <TransportFieldArrayWrapper key={indexMaterialanfuhr}>
                <Box
                  id={`fuhrparkeintraege.${index}.materialanfuhr.${indexMaterialanfuhr}`}
                >
                  {isFremdleistung ? (
                    <MaterialanfuhrTaetigkeitsberichtExtern
                      index={index}
                      materialTransportIndex={indexMaterialanfuhr}
                      push={push}
                      remove={remove}
                      newestIndex={fuhrparkeintrag.materialanfuhr.length - 1}
                      materialtransport={materialanfuhr}
                      fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                    />
                  ) : (
                    <MaterialanfuhrFieldsContent
                      index={index}
                      materialTransportIndex={indexMaterialanfuhr}
                      push={push}
                      remove={remove}
                      newestIndex={fuhrparkeintrag.materialanfuhr.length - 1}
                      materialtransport={materialanfuhr}
                      fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                    />
                  )}
                </Box>
              </TransportFieldArrayWrapper>
            )
          )}
        </>
      )}
    </FieldArray>
  );
};
