import { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';

interface UseFuhrparkAnbauteileProps {
  index: number;
}

export const useFuhrparkAnbauteile = ({ index }: UseFuhrparkAnbauteileProps) => {
  const { setFieldValue } = useFormikContext();
  const [fahrzeugHasChanged, setFahrzeugHasChanged] = useState(false);

  const handleFahrzeugChange = useCallback(() => {
    setFahrzeugHasChanged(true);
  }, []);

  const deleteAllAnbauteile = useCallback(async () => {
    if (fahrzeugHasChanged) {
      await setFieldValue(
        `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile`,
        []
      );
      setFahrzeugHasChanged(false);
    }
  }, [fahrzeugHasChanged, index]);

  return {
    handleFahrzeugChange,
    deleteAllAnbauteile,
    fahrzeugHasChanged
  };
}; 