import { FieldArray } from "formik";
import { TransportFieldArrayWrapper } from "../TransportFieldArrayWrapper";
import {
  ExtendedFuhrparkeintragDaten,
  MaterialTransportDaten,
} from "../../../../models/taetigkeitsberichte/models";
import { getFahrzeugId } from "../../../../models/taetigkeitsberichte/taetigkeitsberichtMutationHelper";
import { Box } from "@mui/material";
import MaterialabtransportFieldsContent from "../materialabtransport/MaterialabtransportFieldsContent";
import MaterialabtransportTaetigkeitsberichtExtern from "../materialabtransport/MaterialabtransportTaetigkeitsberichtExtern";

interface MaterialabtransportArrayProps {
  index: number;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  isFremdleistung?: boolean;
}

export const MaterialabtransportArray = ({
  index,
  fuhrparkeintrag,
  isFremdleistung,
}: MaterialabtransportArrayProps) => (
  <FieldArray name={`fuhrparkeintraege.${index}.materialabtransport`}>
    {({ push, remove }) => (
      <>
        {fuhrparkeintrag.materialabtransport?.map(
          (
            materialabtransport: MaterialTransportDaten,
            indexMaterialabtransport: number
          ) => (
            <TransportFieldArrayWrapper key={indexMaterialabtransport}>
              <Box
                id={`fuhrparkeintraege.${index}.materialabtransport.${indexMaterialabtransport}`}
              >
                {isFremdleistung ? (
                  <MaterialabtransportTaetigkeitsberichtExtern
                    index={index}
                    materialTransportIndex={indexMaterialabtransport}
                    push={push}
                    remove={remove}
                    newestIndex={fuhrparkeintrag.materialabtransport.length - 1}
                    materialtransport={materialabtransport}
                    fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                  />
                ) : (
                  <MaterialabtransportFieldsContent
                    index={index}
                    materialTransportIndex={indexMaterialabtransport}
                    push={push}
                    remove={remove}
                    newestIndex={fuhrparkeintrag.materialabtransport.length - 1}
                    materialtransport={materialabtransport}
                    fahrzeugId={getFahrzeugId(fuhrparkeintrag)}
                  />
                )}
              </Box>
            </TransportFieldArrayWrapper>
          )
        )}
      </>
    )}
  </FieldArray>
);
